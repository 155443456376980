import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { Button } from "@coxkit/core";
import { Dialog } from "./Dialog";

class RawAlertDialogContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: []
    };
  }

  componentDidMount() {
    if (window.__alertEventBus__ || window.__alertInstance__) {
      console.error("Found another AlertDialog listener!");
    }

    window.__alertInstance__ = this;
    window.__alertEventBus__ = this.handleAlert;

    if (window.__pendingAlerts__) {
      this.setState(state => {
        const alerts = [...window.__pendingAlerts__];
        return { alerts: state.alerts.concat(alerts) };
      });
    }
  }

  componentWillUnmount() {
    if (window.__alertEventBus__ !== this.handleAlert || window.__alertInstance__ !== this) {
      console.error("Found another AlertDialog listener!");
    }

    window.__alertInstance__ = null;
    window.__alertEventBus__ = null;
  }

  render() {
    const alert = this.state.alerts ? this.state.alerts[this.state.alerts.length - 1] : null;
    const { header, message, buttons, showOk, showCancel, ...others } = alert || {};

    const buttonElements = (buttons || []).map(b => (
      <Button key={b.label} default onClick={b.onClick}>
        b.label
      </Button>
    ));
    if (showOk) {
      buttonElements.push(
        <Button key="OK" default onClick={this.handleOk}>
          OK
        </Button>
      );
    }

    if (!alert) {
      return null;
    }

    return (
      <Dialog visible={!!alert} header={header} footer={buttonElements} onHide={this.handleHide} {...others}>
        {message}
      </Dialog>
    );
  }

  handleAlert = msg => {
    this.setState(state => {
      const alerts = [...state.alerts];
      alerts.push(msg);
      return { alerts: alerts };
    });
  };

  handleHide = () => {
    this.dismiss();
  };

  handleOk = () => {
    this.dismiss();
  };

  dismiss() {
    this.setState(state => {
      const alerts = [...state.alerts];
      alerts.pop();
      return { alerts: alerts };
    });
  }
}

export const AlertDialogContainer = themed("alertDialog", RawAlertDialogContainer);

export const AlertDialog = {
  show: props => {
    if (window.__alertEventBus__) {
      window.__alertEventBus__(props);
    } else {
      window.__pendingAlerts__ = window.__pendingAlerts__ || [];
      window.__pendingAlerts__.push(props);
    }
  }
};
