import React from "react";
import PropTypes from "prop-types";

import { DataContext } from "./DataContext";

class BridgeBarInstance extends React.Component {
  componentDidMount() {
    let tag = document.createElement("script");
    tag.setAttribute("src", "https://assets.bridge.coxautoservices.com/bridge-bar.js");

    document.body.appendChild(tag);

    const bridgeBar = document.querySelectorAll("bridge-bar")[0];
    bridgeBar.addEventListener("initialized", () => {
      bridgeBar.bb.render({
          // At a minimum, userCode, sessionId and solutionGroupCode should be provided.
          userCode: this.props.userCode,
          sessionId: this.props.sessionId,
          solutionGroupCode: "VAT",
          useMockData: this.props.isDebug
      });
    });
  }

  render() {
    return <bridge-bar />;
  }
}

export const BridgeBar = props => (
  <DataContext.Consumer>{data =>
    data.data.bridgeInfo ? <BridgeBarInstance {...data.data.bridgeInfo} /> : null
  }</DataContext.Consumer>
);
