import { createGlobalStyle } from "styled-components";

export const MainStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto+Mono:100,100i,300,300i,400,400i,500,500i,700,700i|Roboto+Slab:100,300,400,700|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

  html, body {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    background: #FAFAFA;
  }

  body, button, input, select, textarea {
    font: 15px "Roboto", sans-serif;
    letter-spacing: 0;
  }

  svg {
    fill: currentColor;
  }
`;
