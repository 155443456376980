import { themed } from "@coxkit/core/theme-engine";
import { createGlobalStyle } from "styled-components";
import { Dialog as PrimeDialog } from "primereact/dialog";

export const Dialog = themed("dialog", PrimeDialog);

export const DialogOverlayStyle = createGlobalStyle`
  .p-overflow-hidden {
    overflow: hidden;
  }

  .p-dialog-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
  }
`;
