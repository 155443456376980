export const menu = [
  {
    id: 132,
    title: "Provisioning",
    description: "Provision",
    unavailable: false,
    children: [
      {
        id: 142,
        title: "Workbench",
        url: "/Va/Inventory/Stocking/Playground.aspx",
        description: "Look at the hot cars in your market",
        unavailable: false,
        children: []
      },
      {
        id: 133,
        title: "Advanced Workbench",
        url: "/Va/Inventory/Stocking/ProvisionAnalyzer.aspx",
        description: "Analyze the provision grades of all vehicles in your market.",
        unavailable: false,
        children: []
      },
      {
        id: 134,
        title: "Strategy",
        url: "/Va/Inventory/Stocking/Strategy.aspx",
        description: "View and configure your stocking strategy",
        unavailable: false,
        children: []
      },
      {
        id: 135,
        title: "Recommendations",
        url: "/Va/Inventory/Stocking/Recommendations.aspx",
        description: "View your recommended vehicles",
        unavailable: false,
        children: []
      },
      {
        id: 136,
        title: "Sourcing",
        url: "/Va/Inventory/Stocking/AuctionList.aspx",
        description: "View vehicles available for purchase",
        unavailable: false,
        children: []
      },
      {
        id: 143,
        title: "Shopping List",
        url: "/Va/Inventory/Stocking/BuyListVehicles.aspx",
        description: "View the vehicles you are interested in buying",
        unavailable: false,
        children: []
      },
      {
        id: 221,
        title: "Service Appointments",
        url: "/Va/Inventory/Stocking/ServiceAppointments/ServiceAppointmentsGrid.aspx",
        description: "Displays the vehicles coming in for service.",
        unavailable: false,
        children: []
      },
      {
        id: 246,
        title: "Stockwave",
        url: "http://stockwave.vauto.com/platforms",
        description: "Stockwave Platforms Page",
        unavailable: false,
        target: "_blank",
        children: []
      }
    ]
  },
  {
    id: 13,
    title: "Appraising",
    url: "/Va/Appraisal/List.aspx?uq=1",
    unavailable: false,
    unavailableUrl: "/Va/Unavailable/NoAppraising.aspx",
    unavailableMessage: "Click here to learn how you can know how to get out of a car before you get in.",
    children: [
      {
        id: 22,
        title: "New Appraisal",
        url: "/Va/Appraisal/Default.aspx?new=true",
        description: "Create new appraisal",
        unavailable: false,
        children: []
      },
      {
        id: 3,
        title: "Lookup Appraisals",
        url: "/Va/Appraisal/List.aspx",
        description: "Search for an existing appraisal",
        unavailable: false,
        children: []
      }
    ]
  },
  {
    id: 16,
    title: "Pricing",
    url: "/Va/Inventory/Default.aspx?uq=1",
    unavailable: false,
    children: [
      {
        id: 24,
        title: "View Inventory",
        url: "/Va/Inventory/",
        description: "View a list of the vehicles currently in inventory",
        unavailable: false,
        children: []
      },
      {
        id: 111,
        title: "Pending Publishing Changes",
        url: "/Va/Inventory/PendingPrice/Changes.aspx",
        description: "View pending price and description changes",
        unavailable: false,
        children: []
      },
      {
        id: 46,
        title: "Pricing Alerts",
        url: "/Va/Inventory/PricingAlertVehicles.aspx",
        description: "View a list of vehicles that deserve a second look on pricing",
        unavailable: false,
        children: []
      },
      {
        id: 85,
        title: "RealDeal.com",
        url: "/Va/RealDeal/admin/Default.aspx",
        unavailable: false,
        children: []
      },
      {
        id: 114,
        title: "Auction Forecast",
        url: "/Va/Inventory/Auctions/AuctionForecast.aspx",
        description:
          "View all vehicles ready to be sent to auction, set the estimated auciton value and send them to OVE",
        unavailable: false,
        children: []
      },
      {
        id: 229,
        title: "Deal Finder",
        url: "/Va/Inventory/SF/VehicleLookup.aspx",
        unavailable: false,
        children: []
      }
    ]
  },
  {
    id: 69,
    title: "Merchandising",
    url: "/Va/Marketing/",
    unavailable: false,
    unavailableUrl: "/Va/Unavailable/NoMerchandising.aspx",
    unavailableMessage:
      "Click here to learn how you can know if your virtual lot is merchandised as well as your physical lot.",
    children: [
      {
        id: 211,
        title: "Kelley Blue Book Price Advisor Report",
        url: "/Va/PriceAdvisor/Default.aspx",
        unavailable: false,
        children: []
      },
      {
        id: 168,
        title: "AutoVisor",
        url: "/Va/AutoVisor/Default.aspx",
        unavailable: false,
        children: []
      },
      {
        id: 83,
        title: "View Channels",
        url: "/Va/Marketing/",
        unavailable: false,
        children: []
      },
      {
        id: 86,
        title: "Syndication Summary",
        url: "/Va/Dashboard/SyndicationSummary.aspx",
        unavailable: false,
        children: []
      },
      {
        id: 97,
        title: "Syndication Details",
        url: "/Va/Syndication/Default.aspx",
        description: "Inventory List tailored to syndication",
        unavailable: false,
        children: []
      },
      {
        id: 82,
        title: "Photo Management",
        url: "/Va/Inventory/ImageManagement/",
        unavailable: false,
        children: []
      },
      {
        id: 100,
        title: "Window Stickers",
        url: "/Va/Marketing/WindowStickers.aspx",
        description: "Batch Window Stickers and Buyer Guides",
        unavailable: false,
        children: []
      },
      {
        id: 181,
        title: "Listing Logic",
        url: "/Va/Merchandising/MerchandisingDetail.aspx",
        description: "Tool for increasing VDPs on Autotrader.com",
        unavailable: false,
        children: []
      }
    ]
  },
  {
    id: 70,
    title: "Wholesaling",
    unavailable: false,
    children: [
      {
        id: 71,
        title: "Trade Network",
        url: "/Va/Trade/List.aspx",
        description: "Enterprise trade network.",
        unavailable: false,
        children: []
      },
      {
        id: 98,
        title: "vAuto Network Listings",
        url: "/Va/VNet/List.aspx",
        description: "Listing on the vAuto Network",
        unavailable: false,
        children: []
      },
      {
        id: 99,
        title: "vAuto Network Messages",
        url: "/Va/VNet/MessagesList.aspx",
        description: "View your messages from the vAuto Network",
        unavailable: false,
        children: []
      },
      {
        id: 261,
        title: "Transfer Advantage",
        url: "/Va/ProvisionEnterprise/TransferAdvantageList/Default.aspx",
        description: "Manage your Enterprise",
        unavailable: false,
        children: []
      },
      {
        id: 184,
        title: "Enterprise Transfers",
        url: "/Va/Inventory/EnterpriseTransfers/EnterpriseTransferList.aspx",
        description: "Enterprise Transfers",
        unavailable: false,
        children: []
      }
    ]
  },
  {
    id: 65,
    title: "Reporting",
    url: "/Va/Reporting/Default.aspx",
    description: "Reporting Home Page",
    unavailable: false,
    children: [
      {
        id: 262,
        title: "ProfitTime",
        url: "/Va/Dashboard/ProfitTimeDashboard.aspx",
        description: "Provision ProfitTime Dashboard (beta)",
        unavailable: false,
        children: []
      },
      {
        id: 258,
        title: "Report Central",
        url: "/Va/Reporting/Default.aspx",
        description: "Report Central",
        unavailable: false,
        children: []
      }
    ]
  }
];

export const menuConfig = {
  admin: true,
  version: "2019.1.18.2134",
  host: "localhost",
  serverip: "127.0.0.1",
  impersonate: true,
  imperasonteUrl: "/Va.Admin/Enrollment/Impersonate.ashx",
  announcements: [
    {
      id: "0",
      subject: "vAuto Training Webinars & Workshops - Hover to register",
      type: "Information"
    },
    {
      id: "1",
      subject: "Warning Message",
      type: "Warning"
    },
    {
      id: "2",
      subject: "Error Message",
      type: "Error"
    }
  ]
};

export const announcements = {
  "0": {
    subject: "vAuto Training Webinars & Workshops - Hover to register",
    body:
      'vAuto hosts a series of ongoing webinars for all vAuto clients.<a href="http://www.vauto.com/provision/internal.aspx">Click here </a> to review the current training schedule and to register.  <br><br>Call your Performance Manager with any questions.',
    type: "Information"
  },
  "1": {
    subject: "Warning Message",
    body: 'This is a message with type "Warning"',
    type: "Warning"
  },
  "2": {
    subject: "Error Message",
    body: 'This is a message with type "Error"',
    type: "Error"
  }
};

export const userInfo = {
  userName: "cchuckerston",
  displayUserName: "Chuck Chuckerston",
  selectedEntityLogicalId: "UT-ALLCHUCKS",
  selectedEntityName: "Unit Test All The Chucks",
  userEmail: "chuck.chuckerston@wouldchuck.chuck"
};

export const bridgeInfo = {
  isDebug: true,
  isBridgeEligible: true,
  bridgeId: null,
  sessionId: "chuck"
};

export const userMenu = [
  {
    group: "Helper",
    type: "item",
    action: "admin",
    text: "Admin Helper"
  },
  {
    group: "Home",
    type: "item",
    action: "url",
    href: "/Va.Admin/Default.aspx",
    text: "Admin"
  },
  {
    group: "Home",
    type: "item",
    action: "url",
    href: "/Va/Share/Admin/LocalCacheManager.aspx",
    text: "Local Cache Manager"
  },
  {
    group: "Home",
    type: "item",
    action: "url",
    href: "/Va/Share/Admin/RemoteCacheManager.aspx",
    text: "Remote Cache Manager"
  },
  {
    group: "Home",
    type: "item",
    action: "url",
    href: "/Va.Admin/Billing/QuickQuote.aspx",
    text: "Quick Quote"
  },
  {
    group: "Home",
    type: "item",
    action: "url",
    href: "/Va.Admin/Vehicle/Diagnose.aspx",
    text: "VIN Diagnose"
  },
  {
    group: "Home",
    type: "item",
    action: "url",
    href: "/Va.Admin/Vehicle/VINDecoder.aspx",
    text: "VIN Decode"
  },
  {
    group: "Home",
    type: "item",
    action: "url",
    href: "/Va/Tasks/TaskList.aspx",
    text: "Tasks"
  },
  {
    group: "Home",
    type: "item",
    action: "url",
    href: "/Va/Misc/NCP/RebateVehicleLookup.aspx",
    text: "Rebate VIN Lookup"
  },
  {
    group: "Settings",
    type: "item",
    action: "url",
    href: "/Va/Settings/General/Default.aspx",
    text: "General Settings"
  },
  {
    group: "Settings",
    type: "item",
    action: "url",
    href: "/Va/Settings/User/UserSettings.aspx",
    text: "User Settings"
  },
  {
    group: "Settings",
    type: "item",
    action: "url",
    href: "/Va/Settings/UserManagement/UserFeatures.aspx",
    text: "User Management"
  },
  {
    group: "Logout",
    type: "item",
    action: "url",
    href: "/Va/Share/LoginHandler.ashx?logout=1&redirect=1",
    text: "Add New User"
  }
];

export const recentItems = {
  success: true,
  data: {
    rows: [
      {
        id: "AWzo2dCbL-juceWTFzANhz08ZeFfipcn197FIJNEwYc=",
        p: 0,
        lm: "2019-01-30T22:07:36.239Z",
        cid: "NtaWUHhi9SCC-RkLJJcWoqNtYRjsDg9FynF56qzkSG0=",
        eid: "QHrRwpi2tU3jMWu8wkktdTrcu2x7GWnGyNJKDd4Fb5A=",
        en: "Demo Dealership 5 (Demo)",
        v: "1G1AL58F387228345",
        sn: "STK8832",
        y: 2008,
        m: "Chevrolet",
        md: "Cobalt",
        sr: "LT",
        val: 12850,
        t: "i",
        stat: "Active",
        age: 25
      },
      {
        id: "18akrHGLaR5gv3IHHVQuZIjp427kXp0Pnn-DX78R7N0=",
        p: 0,
        lm: "2019-01-28T23:05:57.863Z",
        cid: "NO-68T-zDdm6jmVcZbourELWstks-Zu6KBYsTEOfEd4=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "JF1GPAV67F8207076",
        sn: "STK207076",
        y: 2015,
        m: "Subaru",
        md: "Impreza",
        val: 1,
        t: "i",
        stat: "Deleted",
        age: 12
      },
      {
        id: "p2cAfKMcYu8OGUkGffdzzY9qynXvCWapyRN6jP310cg=",
        p: 0,
        lm: "2019-01-18T23:28:47.327Z",
        cid: "Z8LH2GrDhirPLNBDd28uPYEKQfQsUUkbY6t2dtJMXO4=",
        eid: "kxFNAPLF2qQ1fTwjXhClAt5QaST4sHBuuelZxjkN1Kg=",
        en: "QA Dealership 01",
        v: "2FMHK6DC9CBD01899",
        sn: "STKD01899",
        y: 2012,
        m: "Ford",
        md: "Flex",
        val: 10245,
        t: "i",
        stat: "UserAdded",
        age: 18
      },
      {
        id: "wL4bngRMTIP2waw5r2X9-C3yocubPtCMp4mEUW2zXOI=",
        p: 0,
        lm: "2019-01-28T23:04:57.886Z",
        cid: "sEsCmCqcp_PyGvdDSH_wVT3mkJvUeYKMPWyqI1SQB4Q=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "JM1CW2DL0E0169776",
        sn: "STK169776A",
        y: 2014,
        m: "Mazda",
        md: "Mazda5",
        sr: "Grand Touring",
        sd: "g unit",
        val: 30608,
        t: "i",
        stat: "UserAdded",
        age: 0
      },
      {
        id: "HkMJ8xlhq8-QW8mblsSeYXObN5WraByTTl7QbCPZfak=",
        p: 0,
        lm: "2019-01-23T18:03:19.767Z",
        cid: "DfnXVAr1YrcLX_VyWZOZTwJvnZ8dAsR-hdaDZiknKfQ=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "1N4AL3AP3DN545553",
        sn: "STK545553",
        y: 2013,
        m: "Nissan",
        md: "Altima",
        val: 1000,
        t: "i",
        stat: "UserAdded",
        age: 31
      },
      {
        id: "8jy51B-nJ3rWlGFYC7UfN1OweKn3RZWhdbxAc3gecEo=",
        p: 0,
        lm: "2019-01-23T21:28:14.606Z",
        cid: "s2Be9vPt70SuKwnjp4vNzpzifUZB7r1DJaGOvIQWPqE=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "1FTEW1EG6HFC22891",
        y: 2017,
        m: "Ford",
        md: "F-150",
        sr: "King Ranch",
        t: "a",
        stat: "InProcess"
      },
      {
        id: "xNCfrGMkhZztpPloDxdFozNOhIukMiXPvkdIK-xQj9c=",
        p: 0,
        lm: "2018-08-22T02:58:07.039Z",
        cid: "ppqmyprTEvZQZqRYWtdxXvFhwfaWZK1vd7H-izyDITc=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "3FAHP0HA9CR417199",
        y: 2012,
        m: "Ford",
        md: "Fusion",
        sr: "SE",
        val: 123456,
        t: "a",
        stat: "Completed"
      },
      {
        id: "6Xvh0qufWfRDrQsDhewwL4VApmoN3wyJOkJGD_xH6rQ=",
        p: 0,
        lm: "2018-08-22T02:14:58.306Z",
        cid: "kZVcO2nDmBVs-zDYu8-L6fHosCFGXArXrZodCneFC_Q=",
        eid: "kxFNAPLF2qQ1fTwjXhClAt5QaST4sHBuuelZxjkN1Kg=",
        en: "QA Dealership 01",
        v: "4T1BE46K48U204133",
        y: 2008,
        m: "Toyota",
        md: "Camry",
        val: 5234,
        t: "a",
        stat: "Completed"
      },
      {
        id: "ult9l4y9HpBiRKX-e8noH89T50mCg2me4Y7DZwZ0XSY=",
        p: 0,
        lm: "2018-08-22T18:58:21.691Z",
        cid: "Ogja1WGNPzvQVUPKM7DsfeeWf2peJl5ihC8cj8WhTWo=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "WBAFR1C5XBC744619",
        y: 2011,
        m: "BMW",
        md: "5 Series",
        sr: "528i",
        val: 8199,
        t: "a",
        stat: "Completed"
      },
      {
        id: "WWJMJYxUWvUKsqxR67oczAuU7_p5gdUW7xJUsj_j39A=",
        p: 0,
        lm: "2018-08-22T02:01:02.238Z",
        cid: "TLbaYmVWzLOWMEFgfxpkfc_rB8X1C5oya8oexRCGIyo=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "5YFBU4EE1CP070027",
        y: 2012,
        m: "Toyota",
        md: "Corolla",
        val: 123456,
        t: "a",
        stat: "Completed"
      },
      {
        id: "NLTtszdYQ8fGl7-JTg9zAjseVzBsOKPAgyQrsS872zQ=",
        p: 0,
        lm: "2018-08-22T19:01:14.179Z",
        cid: "Lz9Z3mXZwuxcL5aWf4-35dzYBwy1BkiVdYEgjb_GGLw=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "1C3LC45KX8N223963",
        y: 2008,
        m: "Chrysler",
        md: "Sebring",
        sr: "LX",
        val: 123456,
        t: "a",
        stat: "Completed"
      },
      {
        id: "ki2dgNhboDyrCnIDvloQF9ZgmeFFsMDJhyTYHlH0d8c=",
        p: 0,
        lm: "2018-08-22T02:11:11.797Z",
        cid: "bjbE8Eq4CXSSkfYKdITmZexYvGjjUXLB6ulhy7lZO9o=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "2D4RN3D18AR335918",
        y: 2010,
        m: "Dodge",
        md: "Grand Caravan",
        sr: "Hero",
        val: 123456,
        t: "a",
        stat: "Completed"
      },
      {
        id: "vIwMDFZRx0MDsNXEgplk00AeeKX_fQIU4AVhiaLbmwI=",
        p: 0,
        lm: "2018-12-12T00:36:50.608Z",
        cid: "DhJYFWY37Nmw2y9lWBlltxg2kzk8ubPqzqJqMC8J8Fw=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "JM1NDAM74H0106552",
        y: 2017,
        m: "Mazda",
        md: "Miata RF",
        sr: "Grand Touring",
        val: 20688,
        t: "a",
        stat: "Completed"
      },
      {
        id: "VYZ_dGoY8ZABC-6El0DipOpQJQbbr2x0pTKcWS1XK_A=",
        p: 0,
        lm: "2018-08-22T18:55:33.671Z",
        cid: "PbkZsEef3JJj_JnUVBxDImgwONWlWoktAUEmB0Rwx-E=",
        eid: "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
        en: "Unit Test All Products",
        v: "1B4HS28N91F614887",
        y: 2001,
        m: "Dodge",
        md: "Durango",
        val: 123456,
        t: "a",
        stat: "Completed"
      }
    ]
  }
};

export const quickSearch = {
  appraisals: {
    totalCount: 6,
    sort: "AppraisalLastModified DESC",
    columns: [
      "Id",
      "DealerName",
      "ModelYear",
      "Make",
      "Model",
      "Vin",
      "AppraisedValue",
      "InitialAppraisedValue",
      "AppraisalLastModified",
      "AppraisalStatus",
      "RetailWholesale",
      "CustomerName",
      "AppraiserName",
      "SalespersonName",
      "VehicleTitle",
      "MakeModel",
      "InventoryId",
      "RedBlack",
      "AppraisalSourceId",
      "Recall",
      "Centralized",
      "HqSuggestedValue",
      "HqTransferEntityName",
      "HqTransferEntityId",
      "HqReviewedByName",
      "HqReviewedById",
      "HqAcknowledgedNotes",
      "HqReviewedNotes"
    ],
    description: "Appraisals containing nissan",
    rows: [
      [
        "4zN__3NpFmCvqKjoPIsVN837czofqJrvGahJFkOxmXc=",
        null,
        2009,
        "Nissan",
        "Altima",
        "1N4AL21E09N478975",
        6500,
        6500,
        "2017-12-28T19:54:00.451Z",
        "Completed",
        "R",
        null,
        "Casey, B.",
        ",",
        null,
        "Nissan Altima",
        null,
        "R",
        1,
        0,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ],
      [
        "Yf6K8Yi_hL7iX39KARTpTbeqz4p2B9cCULX-vqVPY5k=",
        null,
        2009,
        "Nissan",
        "Altima",
        "1N4AL21E09N478975",
        6500,
        6500,
        "2017-12-28T19:50:55.709Z",
        "Completed",
        "R",
        null,
        "Casey, B.",
        ",",
        null,
        "Nissan Altima",
        null,
        "R",
        1,
        0,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ],
      [
        "8md4mjOTTYc2FrrYD5aAg04M1R9N1NXwMF-HNRR87oY=",
        null,
        2009,
        "Nissan",
        "Altima",
        "1N4AL21E09N478975",
        6500,
        6500,
        "2017-12-28T19:45:02.575Z",
        "Completed",
        "R",
        null,
        "Casey, B.",
        ",",
        null,
        "Nissan Altima",
        null,
        "R",
        1,
        0,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ],
      [
        "2iFtvZTRo0LmePutV5yuwbk9v491vhgK_nurIYIB12c=",
        null,
        2009,
        "Nissan",
        "Altima",
        "1N4AL21E09N478975",
        6500,
        6500,
        "2017-12-28T19:39:47.962Z",
        "Completed",
        "R",
        null,
        "Casey, B.",
        ",",
        null,
        "Nissan Altima",
        null,
        "R",
        1,
        0,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ],
      [
        "gJHX50-3_39lDaZGeGx3Edfs9RxafYuR6hjOAAoZyvQ=",
        null,
        2009,
        "Nissan",
        "Altima",
        "1N4AL21E09N478975",
        6500,
        6500,
        "2017-10-26T16:09:12.526Z",
        "Completed",
        "R",
        null,
        "Casey, B.",
        ",",
        null,
        "Nissan Altima",
        "apHCjvdh_2r4sC3BD1P_TbTofX1Y3ngQho-_2hlPrjg=",
        "B",
        1,
        0,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ],
      [
        "EtV7XM-LS_NoMW6jzaqJiaxMjlZNX0fU2hxOolZgowo=",
        null,
        2011,
        "Nissan",
        "Versa",
        "3N1BC1AP2BL431689",
        7200,
        7200,
        "2016-08-10T17:28:48.530Z",
        "Completed",
        "R",
        null,
        "Casey, B.",
        ",",
        null,
        "Nissan Versa",
        "cD_jkHOMsB7qRo_Oedga3XjWJwdMKwyYne2Nhpi1FzQ=",
        "B",
        1,
        0,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ]
    ]
  },
  inventory: {
    totalCount: 2,
    sort: "IsActive DESC, DaysInInventory ASC",
    columns: [
      "Id",
      "DealerName",
      "Vin",
      "StockNumber",
      "ModelYear",
      "Make",
      "Model",
      "DaysInInventory",
      "ListPrice",
      "PendingPrice",
      "RetailWholesale",
      "IsActive",
      "Odometer",
      "OdometerUOM",
      "YearMakeModelDaySupply",
      "ExactDaySupply",
      "DefaultPercentOfMarket",
      "OnTarget",
      "Rank",
      "EffectiveRank"
    ],
    description: "Vehicles <span>Active</span> and <span>containing 'nissan'</span>",
    rows: [
      [
        "apHCjvdh_2r4sC3BD1P_TbTofX1Y3ngQho-_2hlPrjg=",
        null,
        "1N4AL21E09N478975",
        "STK478975",
        2009,
        "Nissan",
        "Altima",
        469,
        8600,
        null,
        "R",
        1,
        26000,
        0,
        57,
        39,
        0.8763697612,
        0,
        22,
        7
      ],
      [
        "cD_jkHOMsB7qRo_Oedga3XjWJwdMKwyYne2Nhpi1FzQ=",
        null,
        "3N1BC1AP2BL431689",
        "STK431689",
        2011,
        "Nissan",
        "Versa",
        911,
        9300,
        null,
        "R",
        1,
        58421,
        0,
        69,
        69,
        1.3720217829,
        0,
        16,
        16
      ]
    ]
  }
};

export const entities = {
  totalCount: 334,
  sort: "Distance ASC",
  columns: [
    "Id",
    "Name",
    "ParentId",
    "ParentName",
    "EntityType",
    "City",
    "Region",
    "ChildCount",
    "TypeId",
    "LogicalId",
    "ParentLogicalId"
  ],
  description: "Entities",
  Root: {
    Id: "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
    Name: "Unit Test QA Group"
  },
  rows: [
    [
      "djgrQM6MCzVp4jsZL93CoDB_5tH7T_rJ20Hb_XjHM28=",
      "AutoTest Stockwave Dealer23",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW23",
      "UT-ATG"
    ],
    [
      "INGRSmN2BQP4p_ZdcONE3PLWAsYg5vo_TEzHv-5AaiE=",
      "AutoTest Stockwave Dealer",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW",
      "UT-ATG"
    ],
    [
      "ePQRVi4rrN12xYPUu8WWVv1tex0o-O9bSJpme_Do8pA=",
      "AutoTest Stockwave Dealer Sandbox",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW-SANDBOX",
      "UT-ATG"
    ],
    [
      "aL74GDbcJrTa_dEhc-Irav74SwV0GJQ7OJJ9Eox-6s0=",
      "Autotest Dealership 02",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "UT-AUTOTEST02",
      "UT-ATG"
    ],
    [
      "6_DZevZBqMKdIucZmxz5dkXAydSxjAI1jTFIWF-bHXo=",
      "Unit Test Appraisal Only",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-APPRAISAL",
      "TESTS"
    ],
    [
      "2fOTMCG56vBypx20b_ywmeq7P-ZmHWcLyw2dVuyrLZg=",
      "Unit Test AG Group",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Enterprise",
      "Austin",
      "TX",
      4,
      3,
      "UT-AGG",
      "TESTS"
    ],
    [
      "_ZweAHQFVxchGmqH5_qacYVt3P5mMpJtEgQFuFZ7pa4=",
      "Miles Test Dealer",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "MBRADDOCK-TEST1",
      "USA"
    ],
    [
      "dIPHH1shBLUwmLHzJLyf2BAFRiCqE7rCYJiMMsbOqfY=",
      "Test Dealer - Test Winter19 Dealer",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG122149",
      "NGIN"
    ],
    [
      "JzYm9_mdPVgAU3JHYFLRpq5hxkPSLRrQNcdOWRvnlzs=",
      "Karen's Test1 Feb9",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG124935",
      "NGIN"
    ],
    [
      "yVW0_3k25Ah07DtccTooo7dLXKkCbURgNhvpxS16ktg=",
      "TestApplication_ProductionBN",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG128159",
      "NGIN"
    ],
    [
      "hriaJvJIceCD5WKf6f88BjtHs4-00vO1cyX0K_Fqr9Q=",
      "NEW DBA NAME - Test Scenario Sai linemod",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG128156",
      "NGIN"
    ],
    [
      "YJzOd8kj1IHOunL6W9ciFzAhTVnJ431IQUMLY2ul1BU=",
      "Karen's Cars - Karen's Test1  Jun23",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG128162",
      "NGIN"
    ],
    [
      "cLBXqJK3vsX3lmV-KbvqR4NilGTkCiD5ujksbBNSE10=",
      "King Motors LLC",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Whitestown",
      "IN",
      0,
      1,
      "NG114306",
      "NGIN"
    ],
    [
      "YVda3tOHHfdeG_u4JfGokumH3AkcNFODdBuNrjL1q1M=",
      "D-Test Auto Inc.",
      "ksTRIfObXkAsCIEzQo5ljRDizIBRZLQo3nF7VyQNAFM=",
      "FL",
      "Dealer",
      "Hollywood",
      "FL",
      0,
      1,
      "NG122008",
      "NGFL"
    ],
    [
      "rk72T-7jKh1DSf_buGIsARySFIOvuPdN-7pa0uBvqIs=",
      "Testa Automotive, Inc.",
      "ksTRIfObXkAsCIEzQo5ljRDizIBRZLQo3nF7VyQNAFM=",
      "FL",
      "Dealer",
      "Fort Myers",
      "FL",
      0,
      1,
      "NG108129",
      "NGFL"
    ],
    [
      "4jtqe8q69VT109szgP9owf8Yndccv2BEnqUQYrRxYhs=",
      "Chris Stutsman test 77061",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Houston",
      "TX",
      0,
      1,
      "CMSTEST_77061",
      "CMS_TEST_CONTAINER"
    ],
    [
      "WrUpEf8ltuVkq5SYuXjjdppZIHWdmcpw46pb-QZTHjQ=",
      "Chris Stutsman test 45242",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Cincinnati",
      "OH",
      0,
      1,
      "CMSTEST_45242",
      "CMS_TEST_CONTAINER"
    ],
    [
      "XANw7wX2c8AZW_c3u-sKCQh4daY9K-enEuCpoxGv8EU=",
      "Chris Stutsman test 60169",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Hoffman Estates",
      "IL",
      0,
      1,
      "CMSTEST_60169",
      "CMS_TEST_CONTAINER"
    ],
    [
      "Mm9PGb5Rtt6B-VReWOz8Z6ZMNJqesQ1toR4FAY9GfKI=",
      "testing missing features",
      "nMcmQkbyNkPMqlcUnH51rSo0fmq0ZnQh_q4PEyGJP3Y=",
      "Homenet CDK conversions",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "TESTIFEAT",
      "HNCDK"
    ],
    [
      "4Ls8hH9jX9mukgjf8VZ-EsajV9q_xv0cbHSEXvEU-B4=",
      "Trader QA vAuto test4",
      "qNZ3lfANcPIZ1Na32cZpPRw2vHtpu0Bc5Hoyu6tGLpc=",
      "TraderCanada Testing",
      "Dealer",
      null,
      null,
      0,
      1,
      "TC-TEST4",
      "TC-TESTS"
    ],
    [
      "9DdVnQJCH7u9XFuHB_oyYf1S9X0p6LwNtIXuMsLWOg0=",
      "Gary Test Auto Group",
      "RyuXsIokdr0Ipe8pGBqkdCrThZimbiOWOt-AL6T9e5k=",
      "Illinois",
      "Enterprise",
      "Woodridge",
      "IL",
      1,
      3,
      "GARYAUTO",
      "REGION$IL"
    ],
    [
      "6qkg6CwOIDiOQ6wtaSmX0zontoTq0QTzx7x501swGuE=",
      "Lilly2",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Enterprise",
      "Bloomington",
      "IL",
      2,
      3,
      "LILLYTEST2",
      "DEMOS"
    ],
    [
      "wF-s3atU7TV0tsZuUYiavrxleBdIMn5s52xdnTyGvhM=",
      "TEST DEMO BMW",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "BMWDEMO",
      "DEMOS"
    ],
    [
      "_rX-kfcbnCne4oKlx11hxxYMGvWdIvHMF_vRvUbFMw4=",
      "Darrow Test",
      "WiacQamcqCNbLgWc9QV2Apln5oVEL4vKVhnd7JNOK5Q=",
      "Wisconsin",
      "Dealer",
      "Waukesha",
      "WI",
      0,
      1,
      "MP105",
      "REGION$WI"
    ],
    [
      "btvaIl85WCiw7EWLJE9cRCt1pP_Uc9FqvjjDszgIq6w=",
      "TESTING AW001",
      "1jyltuKDwR46LHkzffhXh8xrW7VBzNToYr3GxWBG6sI=",
      "Testing nested containers",
      "Dealer",
      "Oak Brook",
      "IL",
      0,
      1,
      "RCI1023",
      "VASUPPORT_DEMO_CONTAINER_CTB"
    ],
    [
      "u2jTRGJkNnqMLESzyAAJd5xltYmAaacFhzEjTVTPJcU=",
      "unit test 24",
      "EO__FwO5hTpHh9ZpqkC-DaQ_lKrgNarqZ9jtl57rI4U=",
      "Unit Test QAG1 CT02",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-QA24",
      "UT-QAG1CT02"
    ],
    [
      "J-qbrTR_pMIxiENM79ueoFDdbEDHuoKml3rynPVUlJo=",
      "Blake's Test 3",
      "I7pGkbL7tX0cv6fWZ7O2gqy74QSLhsD0FP1NZmvsBH0=",
      "Blake Test HQ",
      "Dealer",
      "Livonia",
      "MI",
      0,
      1,
      "BLAKETRAIN",
      "PILSBURY"
    ],
    [
      "q3tFBosCl-IYoTER2oJVk3DsxdgJsfpQgRAOKOiT-Eg=",
      "NCP ISUZU Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_ISUZU",
      "NCP_CONTAINER"
    ],
    [
      "HZfhg0JMojUmkdSDXZleTF05bQxbIeYcYfUgTBJzZrY=",
      "NCP LEXUS Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_LEXUS",
      "NCP_CONTAINER"
    ],
    [
      "PU_JLSbfuA2DaKlCQxjqS0iE-LNAsquiAjHaRFWpfVA=",
      "NCP LINCOLN Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_LINCOLN",
      "NCP_CONTAINER"
    ],
    [
      "Xw43wDkqeLlcbQ0XqCGj-VXSwXx2tVF9-PIluj6ennE=",
      "NCP OLDSMOBILE Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_OLDSMOBILE",
      "NCP_CONTAINER"
    ],
    [
      "29YbQc4TOU4p0O2aIG2YVi2oqGxtPJ3IX1aGGql65Cw=",
      "NCP PORSCHE Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_PORSCHE",
      "NCP_CONTAINER"
    ],
    [
      "_w6E3eWhXVyflBSxKmO_PuuIYy4lNFaP4TjR6GnzMOA=",
      "NCP BMW Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_BMW",
      "NCP_CONTAINER"
    ],
    [
      "H1QpBICkY-JExdkzlBh6JmQLvzZYCV1Wd5lKJLN4NmM=",
      "Cindy HQ Test Entity 1",
      "XI3vXkEgAnwmKVrdiieHG2U36nuL2H1IeaoLAZuTOlM=",
      "Cindy Bodnar HQ Test",
      "Dealer",
      "Newark",
      "DE",
      0,
      1,
      "CABHQ1",
      "CABHQTEST"
    ],
    [
      "s03YGCmvGFyDjXZugv-ypaASXCDGs5GTG1SihtwRQ1c=",
      "Garver Template Test Account",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Blauvelt",
      "NY",
      0,
      1,
      "GARVERTEMP",
      "SUPPORTDC"
    ],
    [
      "ItG1dT1zeIGJMdEnrzuxDCY46JQK0ifF5KuEXQNE9Po=",
      "KJ Test Account",
      "TxyxDq3JU54TW9wDiJIVrWkt-ESHklDYf-qbdD4vcVk=",
      "Conquest Beta",
      "Dealer",
      "Myrtle Beach",
      "SC",
      0,
      1,
      "KJTEST",
      "CONBETA"
    ],
    [
      "FaoAd6bdP7yeAnlsUYw5BxYBrXsyQ7-7wapTOHePkiA=",
      "MONICA'S DEMO ACCOUNT",
      "TxyxDq3JU54TW9wDiJIVrWkt-ESHklDYf-qbdD4vcVk=",
      "Conquest Beta",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "MONICATEST",
      "CONBETA"
    ],
    [
      "qYt1X5hyo2Q0haI2aLIMSc2mLssivBkgl8lhdfscb1w=",
      "Amadou Kalle Test Account",
      "skDUTxVnMn37Zrs3nLrXi952tgDoCZsCpBJ34opv8KE=",
      "Canadian Dealers",
      "Dealer",
      null,
      null,
      0,
      1,
      "AK1",
      "CANADA"
    ],
    [
      "4WsXGaaz62QmaIHJSlYpVtosvr2clm5uIx1jNu0keGw=",
      "RCI - TEST",
      "2tNUg7B3Nw1gHihw_uaGa0Me5_C0EdHQn2t8z8si3NE=",
      "RCI Conversion",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "RCI1",
      "RCITOP"
    ],
    [
      "yeyTGm9rFGbu8DdjH_8uj7C5neiwSM0X9RzXf2PWtko=",
      "OP-TEST-CONQUEST",
      "Zy9HuJA0DW9Da01inKjexP74wCR4fFBbx0wzRj2c2fM=",
      "OP-TEST-DEMOS",
      "Dealer",
      "McHenry",
      "IL",
      0,
      1,
      "OPTCONQUEST",
      "OPTESTDEMOS"
    ],
    [
      "Y5GLJ-wsnQHQiEwSrymNVgkRLvyxy_reOw8bwbxs2yw=",
      "Test Dealership 01",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "TEST01",
      "TESTS"
    ],
    [
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Container",
      "Austin",
      "TX",
      29,
      0,
      "CMS_TEST_CONTAINER",
      "TESTS"
    ],
    [
      "NKeteeGYAe1gIve64Iw0lCvSn5uIycrtOGSfBsBoR2E=",
      "Unit Tests",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UNITTEST",
      "TESTS"
    ],
    [
      "faEizaW9WPM3Dl_7xif_zmgO-G1RRk06zLVv29Uq0Ew=",
      "Unit Test Dev 01",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-DEV01",
      "TESTS"
    ],
    [
      "xcDdLU21aKkjPauAMM6tHjI5NvxhQSH3NlM0cBJPADc=",
      "Unit Test - All Conquest",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-ALLCONQ2",
      "TESTS"
    ],
    [
      "ivyxaCFlnDTvhHjnEUYSG_at5j-clADH_FhBz2rHhyk=",
      "Unit Test Radar plus AuctionGenius Mobile",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-RADARAGMOBILE",
      "TESTS"
    ],
    [
      "OFrgBa41CF5kzTmPwrpXjnNsrD6WJrLHB1-AIsp4OCI=",
      "Unit Test Old Stocking Radar AGMobile",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-OLDSTOCKINGRADARAGMOBILE",
      "TESTS"
    ],
    [
      "lpVDf1FrsKKRXRtSF3_gTD9b7nRwl1lSpDYpjkmsMew=",
      "Unit Test Stocking plus Appraisal",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-STOCKINGAPPRAISAL",
      "TESTS"
    ],
    [
      "X10GbWElaH5dEWhG6xA2N2mu3j1ibyLAaLQn0shfEQk=",
      "Unit Test All Products",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-ALLPRODUCTS",
      "TESTS"
    ],
    [
      "ttPhZZFb3VpSnOF361CXqGZ2RJwszdkW6jNXdPtYbkc=",
      "***Unit Test - Lou Fusz Chevrolet - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Saint Peters",
      "MO",
      0,
      1,
      "UT-MP4214",
      "TESTS"
    ],
    [
      "cXo4xsNTM-T8Fp5TC_A04lNeQhfvXQnWEcpxJ5oqKN8=",
      "SQL Templates  Test - Integra MP325",
      "3wV2TC96MVcN4JPQbzMUfx0A9js4dKGMCvxuxKOUchk=",
      "SQL Templates  Test - Container",
      "Dealer",
      "Napa",
      "CA",
      0,
      1,
      "SQLTEMP-INTEGRA",
      "SQLTEMP-CONTAINER"
    ],
    [
      "UpMMxvaASSM5eZoB8bsXDT1B59mTDy5kMJw1trxcoSk=",
      "SQL Templates  Test - Conquest MP708",
      "3wV2TC96MVcN4JPQbzMUfx0A9js4dKGMCvxuxKOUchk=",
      "SQL Templates  Test - Container",
      "Dealer",
      "Roanoke",
      "VA",
      0,
      1,
      "SQLTEMP-CONQUEST",
      "SQLTEMP-CONTAINER"
    ],
    [
      "AZ82vaZhEtT_ekfETGZe9by48kTVEgCQGZt4Mrq6qpk=",
      "Test RadarBasic Dealer",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "TESTRADARBASIC",
      "USA"
    ],
    [
      "qn0E2QZbDm8qdfu5jalkOWwHDVkkupqMU_81ZSvOSZk=",
      "Test_DP_reddy",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG128163",
      "NGIN"
    ],
    [
      "l9g5B9fP36Da1FXmeJNUxr9GUDtBfiIDZINu83j6wYM=",
      "Chris Stutsman test 92626",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Costa Mesa",
      "CA",
      0,
      1,
      "CMSTEST_92626",
      "CMS_TEST_CONTAINER"
    ],
    [
      "ZiKff9DWX_wIbflS6bwBM6HwnbEyF2lpOS_um3_SB8A=",
      "Chris Stutsman test 17545",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Manheim",
      "PA",
      0,
      1,
      "CMSTEST_17545",
      "CMS_TEST_CONTAINER"
    ],
    [
      "x6cAmk3H5ZLMMDJGuxZh7H7ud0FKo9tdUiipK_F_1cw=",
      "enterprise test",
      "myQTBvK6HJGfzen6RS04ppNVhsUyhbc_OmzOx3_xSUU=",
      "unit test QA_1",
      "Enterprise",
      "Austin",
      "TX",
      0,
      3,
      "UT-ENT-TEST",
      "UT-QA_1"
    ],
    [
      "q03s1lYuKNIJ3e6Z_ZO3ZqeTsJTO6dhdxJIsTtw9Uv4=",
      "Clutch test",
      "Yw52KvKqcK0bXj9azN3lCanV0Et4bl3VIEQEF6tbK4w=",
      "Major Accounts ATG Demo Reports",
      "Dealer",
      "Atlanta",
      "GA",
      0,
      1,
      "AVISDEMOSANFRAN",
      "ATGDEMOC"
    ],
    [
      "QJhqkZyh-jNLfB_X-8dB6t9LGHv5ISrpXhxtRVbuwP0=",
      "Stutsman Used Car Sales",
      "Z11ZTJEKAA_4Zoparn1ZKdbt1K8fUb-e0SbMVmOxAwE=",
      "Unit Test QA Group 1",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-TEST-EBAY",
      "UT-QAG1"
    ],
    [
      "_0Bq8lAyd0483NwR65sipikvi42CBe0GPkUMsTCtwFA=",
      "Unit Test Canadian Group 1",
      "qNZ3lfANcPIZ1Na32cZpPRw2vHtpu0Bc5Hoyu6tGLpc=",
      "TraderCanada Testing",
      "Enterprise",
      null,
      null,
      3,
      3,
      "UTC-QAG1",
      "TC-TESTS"
    ],
    [
      "Wzol1l5oUjMd3tDJ4ey2CYyys5sHvFQTK0q8K_C8zMA=",
      "Lilly1",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      null,
      null,
      0,
      1,
      "LILLYTEST1",
      "DEMOS"
    ],
    [
      "lDR7Qpxwq37R0Xk3hK5GYa8zgw2gO7NeXWocHaY60EU=",
      "RFEENEYDEMO",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "New Braunfels",
      "TX",
      0,
      1,
      "FEENEYTEST",
      "DEMOS"
    ],
    [
      "B5VZlDOrVIVARC4FjU8VmmFDwXsYLAwT_MVdL0jrMWw=",
      "Haystak Demo Dealer for Automated Testing",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Carrollton",
      "TX",
      0,
      1,
      "HAYSTAK_DEMO",
      "DEMOS"
    ],
    [
      "xWGo2VAnaVFnJHoqakyWnLhNIYnFREompX_Ew5GVJJo=",
      "Jon Phillips Test Account",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "JONPTEST",
      "DEMOS"
    ],
    [
      "sJVsXwQA7x84UChz5pfPnteEoVh9nwaYmcTxk9OTrCQ=",
      "Blake Test 2",
      "I7pGkbL7tX0cv6fWZ7O2gqy74QSLhsD0FP1NZmvsBH0=",
      "Blake Test HQ",
      "Dealer",
      "Williamston",
      "NC",
      0,
      1,
      "BLAKEMAN",
      "PILSBURY"
    ],
    [
      "ddkQMaAVzruJ1WGcMLN7b8ngr0pD79VfOeM4os3IzUM=",
      "unit test container",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Container",
      "Austin",
      "TX",
      1,
      0,
      "UT_CONT_SR",
      "UT-QAG"
    ],
    [
      "scNCYPBMrqanRbSVXgpDOLYYxKgIVKOES6gn-B_nKUg=",
      "Rowan's Test",
      "jVsVY51qDZJnGObVSr3wjtn6IiKL1_On035jKanlEfg=",
      "Homenet API Conversion Project",
      "Dealer",
      null,
      null,
      0,
      1,
      "RODEMO",
      "HNCONV"
    ],
    [
      "K9_EOn9aHc06Oj7Avzmp5eJrJDXxP3N0Px7OLPbTRe8=",
      "NCP CHRYSLER Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_CHRYSLER",
      "NCP_CONTAINER"
    ],
    [
      "o8r2BVwkUVC3qEpTAmEBaraY9G7sbuwLGZQjPsz94F0=",
      "NCP FORD Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_FORD",
      "NCP_CONTAINER"
    ],
    [
      "bASV077Jqv8zW6AkJGWt5EhFhjvuvzx_Cu_-4aYvN64=",
      "NCP JAGUAR Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_JAGUAR",
      "NCP_CONTAINER"
    ],
    [
      "B8g8KFQi-MWUlxZ2D9x2zb2PJyRZjgkZeQeedBTgK7Q=",
      "NCP KIA Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_KIA",
      "NCP_CONTAINER"
    ],
    [
      "C6Pdz0CDLCAdUPjxPPSC73i8aMVGv6hQjI7KycW5aqs=",
      "NCP RAM Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_RAM",
      "NCP_CONTAINER"
    ],
    [
      "Ip4HgMpeS2tc-L_P-ngkAraJyimpwQMpfucBbndsdtQ=",
      "NCP CHEVROLET Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_CHEVROLET",
      "NCP_CONTAINER"
    ],
    [
      "F5hyE76ijReArMxERDnyOfEWWSMjzGJ9O0nIy8klyRI=",
      "cab Rbook Test",
      "oleujlXVBQ1NahnbSeeWRTzzWXxv9sTiQNBRcf_hwMU=",
      "Ohio",
      "Dealer",
      "Garrettsville",
      "OH",
      1,
      1,
      "CABRBOOK",
      "REGION$OH"
    ],
    [
      "ya-DiXQRzFGJZpGmJEmSx6-vbknn7auaMmMhm4VVS2w=",
      "Davidson Ford of Liverpool test",
      "AMT6C2vWjtHQPpa9UGv1T3NkvGTMixtvEEm6xYX5nF8=",
      "Demo Dealers",
      "Dealer",
      "Liverpool",
      "NY",
      0,
      1,
      "GARYDEMO7",
      "CA-DEMO"
    ],
    [
      "uTnOBLmLAewGtyDxp0vaPIuC28Ke2TAs4wz7hK4OiOE=",
      "AutoTest Stockwave Dealer4",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW4",
      "UT-ATG"
    ],
    [
      "sRMmf_kxa3yfn1sTIZUuwTIvd_lkVrpWonAruyP_P4M=",
      "AutoTest Stockwave Deale21",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW21",
      "UT-ATG"
    ],
    [
      "0VmsfIkO1BenGKXXf_VceFICinYy3DwL45r8hxSml7U=",
      "AutoTest Stockwave Dealer9",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW9",
      "UT-ATG"
    ],
    [
      "GTl0ShamJdiVKVqxfw2vQWmSxjKC0IjacUAnUUPEYdc=",
      "AutoTest Stockwave Dealer15",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW15",
      "UT-ATG"
    ],
    [
      "jshKaBB-FJ-V4soMgfgVdGErpHrVpBwLHicoJKBvaMY=",
      "AutoTest Standalone Stockwave Dealer2",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Cerritos",
      "CA",
      0,
      1,
      "UT-AUTOTESTSTANDALONESW2",
      "UT-ATG"
    ],
    [
      "qlPrMQCa1b6gfRoF8zlLJ_Dhs66AJwxK7vQlTFj4Q-Q=",
      "AutoTest Dealership 4",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTEST04",
      "UT-ATG"
    ],
    [
      "qxwrYOXFzJhM0CSuF0Hrr1tJXGL1E6zPb8ZwB7zSDpM=",
      "QA Autotest Mobile Entity 01",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Concord",
      "NC",
      0,
      1,
      "UT-AUTOTESTMOBILE01",
      "UT-ATG"
    ],
    [
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Enterprise",
      "Honolulu",
      "HI",
      78,
      3,
      "UT-QAG",
      "TESTS"
    ],
    [
      "Kc_IdCaEjNgmltZLTEy8ETal-NvFTiGK3sQyH29vSgc=",
      "Unit Test Stocking Radar AG Mobile",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-STOCKINGRADARAGMOBILE",
      "TESTS"
    ],
    [
      "wpb2Aq0uLOGK8ds7Sgih545s0bVwdFhRILOsrOksc6A=",
      "Provision Test",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-PROVISION",
      "TESTS"
    ],
    [
      "IN74uOoPS2DEeZPi4DDCqrlwITUnK4xFhXho9xUi47E=",
      "Unit Test - All Conquest",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-ALLCONQ3",
      "TESTS"
    ],
    [
      "RmnjrE3mNRrfuAlwDV9f_I2Jt4DtZT2oWMgJoxFHdm4=",
      "***Unit Test - Lou Fusz Subaru - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Saint Louis",
      "MO",
      0,
      1,
      "UT-MP16490",
      "TESTS"
    ],
    [
      "okN5xLib387PLqandKorVWZZO7RgeCqoWz4qVv0NXDU=",
      "***Unit Test - Lou Fusz Ford - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Chesterfield",
      "MO",
      0,
      1,
      "UT-MP4216",
      "TESTS"
    ],
    [
      "YXsSjx5JXa5IjnEGTjmtlD0aVDVgEsvJvBVkHxsp6_4=",
      "App Data Test",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "APPDATATEST",
      "TESTS"
    ],
    [
      "UCdP0q9xU9NzOPzixTB25cfTYD735yWMucJQFwxwXRg=",
      "SQL Templates  Test - RCI MP6374",
      "3wV2TC96MVcN4JPQbzMUfx0A9js4dKGMCvxuxKOUchk=",
      "SQL Templates  Test - Container",
      "Dealer",
      "Houston",
      "TX",
      0,
      1,
      "SQLTEMP-RCI",
      "SQLTEMP-CONTAINER"
    ],
    [
      "kMXnoBkBKZNKICSyscZOqKs327tVnZKKDIaFE_GnJAQ=",
      "SQL Templates  Test - Morries MP226",
      "3wV2TC96MVcN4JPQbzMUfx0A9js4dKGMCvxuxKOUchk=",
      "SQL Templates  Test - Container",
      "Dealer",
      "Chippewa Falls",
      "WI",
      0,
      1,
      "SQLTEMP-MORRIES",
      "SQLTEMP-CONTAINER"
    ],
    [
      "EsD9uS_pvLiMZhfLoWNluBms_ECiVDXwHmfcjkvyNtM=",
      "SQL Templates  Test - SelectQu MP6015",
      "3wV2TC96MVcN4JPQbzMUfx0A9js4dKGMCvxuxKOUchk=",
      "SQL Templates  Test - Container",
      "Dealer",
      "Elkhorn",
      "NE",
      0,
      1,
      "SQLTEMP-SELECTQU",
      "SQLTEMP-CONTAINER"
    ],
    [
      "HELQ1_deh0LOg56rUaQLwMGouEe-RMR-13Jy5hgoz28=",
      "SQL Templates  Test - Penske MP1923",
      "3wV2TC96MVcN4JPQbzMUfx0A9js4dKGMCvxuxKOUchk=",
      "SQL Templates  Test - Container",
      "Dealer",
      "Port Chester",
      "NY",
      0,
      1,
      "SQLTEMP-PENSKE",
      "SQLTEMP-CONTAINER"
    ],
    [
      "a9yBA7Wnnmz9ARMHkRzIXwb8CJgo--Aecml56ektIW0=",
      "Testing 12345",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Container",
      "Austin",
      "TX",
      0,
      0,
      "TEST12345",
      "USA"
    ],
    [
      "2BA4ZlHI_GseuKgvEFrvSxmRs7Ap2hkR0bmtC-k-Oi0=",
      "test123",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Enterprise",
      "Oak Brook",
      "IL",
      0,
      3,
      "TEST123",
      "USA"
    ],
    [
      "CxGHvuxDI5DC5iz5xpEdkXK8jEQarsgu2y5SaOCQqnM=",
      "UNIT TEST QA 30",
      "1igJ4TCbFu_P83nb9Qn3d5ckQk3WgaNwEWJAqb7JP_c=",
      "UNIT TEST QA GROUP03",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-QA30",
      "UT-QAG03"
    ],
    [
      "6TGb3luanQGQKPTKeJWaJfgzwRChtReVNpoxuebohUM=",
      "Chris Stutsman test 96819",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Honolulu",
      "HI",
      0,
      1,
      "CMSTEST_96819",
      "CMS_TEST_CONTAINER"
    ],
    [
      "sM0T2QwynuafBjDP3_N3z2I8SJUnE8u8M13I-C-k6i8=",
      "Chris Stutsman test 94304",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Palo Alto",
      "CA",
      0,
      1,
      "CMSTEST_94304",
      "CMS_TEST_CONTAINER"
    ],
    [
      "L3W85vdpyUNNvhufm_82LDNwl6tIeoDMRytHmZaQ2SY=",
      "Chris Stutsman test 95377",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Tracy",
      "CA",
      0,
      1,
      "CMSTEST_95377",
      "CMS_TEST_CONTAINER"
    ],
    [
      "5ml1Kd7jEBz4ZJN7Tt-yDdEGurVGkAowErwK8FcwN6M=",
      "Chris Stutsman test 20166",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Sterling",
      "VA",
      0,
      1,
      "CMSTEST_20166",
      "CMS_TEST_CONTAINER"
    ],
    [
      "21n8Dw-YrxmJkt-vSnUx1OX-q5u0QFdxBJqCSjAlciE=",
      "Chris Stutsman test 34761",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Ocoee",
      "FL",
      0,
      1,
      "CMSTEST_34761",
      "CMS_TEST_CONTAINER"
    ],
    [
      "RN5ZPU5Abn9oM7stInZTMZ0twwjIgF_zWljDKnkdcXE=",
      "Chris Stutsman test 08835",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Manville",
      "NJ",
      0,
      1,
      "CMSTEST_08835",
      "CMS_TEST_CONTAINER"
    ],
    [
      "mckSIvreKseRAtIBK1Y97bUj_VLqssTZz5SWli-CBmc=",
      "Matt's Test Account",
      "nMcmQkbyNkPMqlcUnH51rSo0fmq0ZnQh_q4PEyGJP3Y=",
      "Homenet CDK conversions",
      "Dealer",
      "Lawrenceburg",
      "IN",
      0,
      1,
      "MSTEW",
      "HNCDK"
    ],
    [
      "URX6m3wTRhJq0vDXZSXgUlCipJ19RdBhytfQOFNQmrs=",
      "Antonio's test account 🦆",
      "nMcmQkbyNkPMqlcUnH51rSo0fmq0ZnQh_q4PEyGJP3Y=",
      "Homenet CDK conversions",
      "Dealer",
      "Milford",
      "NH",
      0,
      1,
      "GUZMANTEST",
      "HNCDK"
    ],
    [
      "yL6Y6iR95an0XJGj7VuC85FnXIOloSWf5kBa-c376Ic=",
      "cab Sandy and Bubba Test",
      "180wJIhmsg3Bqj33Az0I6xviB0gScDDLXhi8AgwI810=",
      "Stockwave Demo",
      "Dealer",
      "Milton",
      "FL",
      0,
      1,
      "CABCON2",
      "STOCKWAVE_DEMO"
    ],
    [
      "3_tIBzO1vA2uzHEn3lmd_KYLg_IC4PjQpdNMlR-uoRw=",
      "Oleg Test Account",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "OKT1",
      "DEMOS"
    ],
    [
      "q4ER-kyReFb7QjONHhEqB4Ua3ZM5kg22FZGLAiM2aOE=",
      "RKUKULAKDEMO",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "RAFALTEST",
      "DEMOS"
    ],
    [
      "GzCoLt3kpv2wvDxYtpO4NHDfUyanIPr_XJkjSMAMAI0=",
      "Rebeca Test Account",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "RHHN",
      "DEMOS"
    ],
    [
      "z7YAobSDdkC5O4S7w2fZQI3TvGWQP4JMbsk0BYcaOUs=",
      "DLEEDEMO",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Willowbrook",
      "IL",
      0,
      1,
      "DENZELTEST",
      "DEMOS"
    ],
    [
      "5S7lw8nOS4_qd81SjcskSPky5KBV_djFSNBz-aNrgrw=",
      "Cindy Test",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Leavenworth",
      "KS",
      0,
      1,
      "CABTEST",
      "DEMOS"
    ],
    [
      "gZ5tNmmKfoBiNGB7tgGLtd7WqUO0SHsKs8EG_L7oQ_g=",
      "Lilly3",
      "6qkg6CwOIDiOQ6wtaSmX0zontoTq0QTzx7x501swGuE=",
      "Lilly2",
      "Container",
      "Bloomington",
      "IL",
      1,
      0,
      "LILLYTEST3",
      "LILLYTEST2"
    ],
    [
      "9cdiUwdqcVsL6LcuhVCSbYVqbYYpUq5LVFuM0IbXBhc=",
      "Andrew's Canada Test",
      "KCaiOCPdGQljmTdlGnTqP-idbcBHoJS25kXmw0eF5Aw=",
      "Canadian ID Demos",
      "Dealer",
      null,
      null,
      0,
      1,
      "ADOLANCATEST",
      "DESIGNDEMOS_CA"
    ],
    [
      "Jfb3tkN64pLZL3S66nVlbggHuv6bxXkmcDedaAKmd4c=",
      "Unit Test QAG CT02",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Container",
      "Villa Park",
      "IL",
      11,
      0,
      "UT-QAGCT02",
      "UT-QAG"
    ],
    [
      "RDfsZPKfqhREmHwQyorp_WnxhvVC-wWzLGQhtjByyio=",
      "Test1",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Container",
      "New York",
      "NY",
      0,
      0,
      "TESTWR",
      "UT-QAG"
    ],
    [
      "0VjmZc6WnEOTwwxRkPgVJsKvzRg-5CJ7e8Zq-nkGTZ8=",
      "Horacio Test",
      "jVsVY51qDZJnGObVSr3wjtn6IiKL1_On035jKanlEfg=",
      "Homenet API Conversion Project",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "HPT01",
      "HNCONV"
    ],
    [
      "ktKSKF9qKA1s97j5T-ojINXMabAHbhBkW-2rqmHC6Ao=",
      "Cindy SmartAuto Test",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Dealer",
      "Knoxville",
      "TN",
      0,
      1,
      "CABSMART",
      "ROOT"
    ],
    [
      "TwiuA0v4CmVnvqU8n0NXo7VlgViqqNoz8mthpwn26g8=",
      "The Warehouse",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Dealer",
      "Conshohocken",
      "PA",
      0,
      1,
      "LUKETEST",
      "ROOT"
    ],
    [
      "seeB4RmZ0VzmD83bmDLLiHUCRChcmnl3qjeS1v_1tVk=",
      "NCP INFINITI Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_INFINITI",
      "NCP_CONTAINER"
    ],
    [
      "YQpIg74r4MG7wHYGd9_JtN2FKN810fK9IZHV3Zb0BP8=",
      "NCP VOLKSWAGEN Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_VOLKSWAGEN",
      "NCP_CONTAINER"
    ],
    [
      "aMtsRovPQBH1XHwR2paoX7TOmSqaMWlsPSRLBwjWjx8=",
      "NCP VOLVO Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_VOLVO",
      "NCP_CONTAINER"
    ],
    [
      "TXcIYgknGVms9v5-MgMcKBFaZ8QA1D9Xjt6iud5XO6U=",
      "NCP AUDI Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_AUDI",
      "NCP_CONTAINER"
    ],
    [
      "xSXAqPR2wPAcmDvW4DRA1ypjMM8hrpkD0gOm0ODyYEc=",
      "NCP Honda Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_HONDA",
      "NCP_CONTAINER"
    ],
    [
      "5dgJYSAG-gQR-RCPKxaUsQv_XFUOV_tG6ZWDKQoBjow=",
      "Cindy Test HQ Entity 2",
      "XI3vXkEgAnwmKVrdiieHG2U36nuL2H1IeaoLAZuTOlM=",
      "Cindy Bodnar HQ Test",
      "Dealer",
      "New Castle",
      "DE",
      0,
      1,
      "CABHQ2",
      "CABHQTEST"
    ],
    [
      "1jyltuKDwR46LHkzffhXh8xrW7VBzNToYr3GxWBG6sI=",
      "Testing nested containers",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Container",
      "Austin",
      "TX",
      2,
      0,
      "VASUPPORT_DEMO_CONTAINER_CTB",
      "SUPPORTDC"
    ],
    [
      "2ydlSM0D_JgtEkDARcHBUv4Zm-4G5ARnJX8iGfFspt4=",
      "Keene Test Entity -DMS Switches",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Berwyn",
      "IL",
      0,
      1,
      "KEENETEST",
      "SUPPORTDC"
    ],
    [
      "AWi_FyALBHo6K9AFAz8ZklJWOYGhZuh64MAU4T8bTxY=",
      "Ken Test Account",
      "2tNUg7B3Nw1gHihw_uaGa0Me5_C0EdHQn2t8z8si3NE=",
      "RCI Conversion",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "RCIK",
      "RCITOP"
    ],
    [
      "WBAnE1Zd0ujTHVXVYBeZnLm_Y-q28bz_dZo4w9jpKow=",
      "Cindy Bodnar - Canada Test",
      "AMT6C2vWjtHQPpa9UGv1T3NkvGTMixtvEEm6xYX5nF8=",
      "Demo Dealers",
      "Dealer",
      null,
      null,
      0,
      1,
      "CABT2",
      "CA-DEMO"
    ],
    [
      "bZUFA-DcKuP6OttSbJLsL1R7Y-qs8C_Bpog6CHQ_CC8=",
      "utce-tc1-appdatatestentity",
      "A474CYmuel2DpPvhtKtWdGIBmrvnUfdSvee5AQD0eiw=",
      "utce-qag - AutoCanada",
      "Dealer",
      null,
      null,
      0,
      1,
      "UTCE-APPDATA",
      "UTCE-QAG"
    ],
    [
      "Mp5voSvQgs8vVe3s7o94X37Ece7mSphNNNuhneaFGUA=",
      "AutoTest Stockwave Dealer19",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW19",
      "UT-ATG"
    ],
    [
      "-zPQSav5P-gyaI_JjsHwyv3aZTegakPxS0UMcZCqV9k=",
      "AutoTest Stockwave Dealer6",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW6",
      "UT-ATG"
    ],
    [
      "TdBzNLRBn7WG0-4i2G11MoNv4mu8rzqbTWx8UwYgVAc=",
      "AutoTest Stockwave Dealer17",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW17",
      "UT-ATG"
    ],
    [
      "HM6PftyG0k0P0hX0wS0nwH8mYp-vymdS0ilXqXiQkC8=",
      "AutoTest Stockwave Dealer13",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW13",
      "UT-ATG"
    ],
    [
      "fjG7feq00Oi28YX3_MtH3XwAwCD27vZDOHrL6BlBYCg=",
      "OffersTestEntity",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-OFFERSTESTSANDBOX",
      "UT-ATG"
    ],
    [
      "3zU5PGco1GmkMCOQuasOplngNnXFz9OeL8wpxBhr47Y=",
      "Autotest Appraisal Only",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTAPPRAISAL",
      "UT-ATG"
    ],
    [
      "QaQdxKZoYe7WHT05AJGNL5cp9_R9BdEyL0_ycsHFfEM=",
      "Lilly6",
      "EnMGq4_L5F7L2su4NWsT2bLfDQIfg4oAzb-KJLUnKlo=",
      "Lilly5",
      "Dealer",
      "Newtown Square",
      "PA",
      0,
      1,
      "LILLYTEST6",
      "LILLYTEST5"
    ],
    [
      "Z11ZTJEKAA_4Zoparn1ZKdbt1K8fUb-e0SbMVmOxAwE=",
      "Unit Test QA Group 1",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Container",
      "Cedar Park",
      "TX",
      7,
      0,
      "UT-QAG1",
      "TESTS"
    ],
    [
      "dtxej8flQpNJkZDRS-uUA-Qs0yH_yziZWk0bYs44VPo=",
      "Ross Test",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "ROSSTEST",
      "TESTS"
    ],
    [
      "9f3Q2XamMmxVTnjExoZQ7_HVgZld0C2V2cwYkWmubHs=",
      "Unit Test Stocking Only",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-STOCKING",
      "TESTS"
    ],
    [
      "Tgq4BvHMmFcqYidQ-OMViHZr6uBieCiJnWEzeKzhzhg=",
      "TESTING TESTING",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Enterprise",
      "Austin",
      "TX",
      0,
      3,
      "HARI",
      "TESTS"
    ],
    [
      "2qut0sA0hVV3EFI4GJY0tKcXszXn53aX0t2UxQPG0lw=",
      "***Unit Test - Lou Fusz Toyota - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Saint Louis",
      "MO",
      0,
      1,
      "UT-MP4220",
      "TESTS"
    ],
    [
      "TsNrj9kVTCuaNywdtxtLSY5HEA_FEKK3hZ2E2FimfNU=",
      "***Unit Test - Lou Fusz Fiat Metro East - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Fairview Heights",
      "IL",
      0,
      1,
      "UT-MP4414",
      "TESTS"
    ],
    [
      "RsJIm3jkN_kpugUGbZIMu6pwIdgrXrc-k0L4uhLt1OQ=",
      "Testing",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "TEST1234",
      "USA"
    ],
    [
      "JLcqC691PN3FNiNOL5fC5xAUx4IXcV7iX3LnyGJ3sjA=",
      "Test 08",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Container",
      "Austin",
      "TX",
      0,
      0,
      "TEST08",
      "USA"
    ],
    [
      "dFnjblKBbc3JZB3Y5CFuv9oifMawslPLUOc9AAwBH5U=",
      "Lilly4",
      "gZ5tNmmKfoBiNGB7tgGLtd7WqUO0SHsKs8EG_L7oQ_g=",
      "Lilly3",
      "Dealer",
      "Newtown Square",
      "PA",
      0,
      1,
      "LILLYTEST4",
      "LILLYTEST3"
    ],
    [
      "l4VSQhiZYkoyLAQMEAH1LZWFEWjRPRiLBhRewk6ECkg=",
      "180 - Pagan Test",
      "8YNCbp8rEdR5SvVDVTpzN7wHk_3maWui3rXfPEo1_CA=",
      "Canadian Conversions 180 CDK",
      "Dealer",
      null,
      null,
      0,
      1,
      "HCPAGAN",
      "DATA_TEAM"
    ],
    [
      "qPj9Z27cK9D26oT8ikmOQhTu9WNwSj7KtG1jOHnLkS4=",
      "Test app for profiles and permissions",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Fishers",
      "IN",
      0,
      1,
      "NG128158",
      "NGIN"
    ],
    [
      "wBpg8Jiaz_iQetjACH6LtAazWK-pyaGwZjjxTE99xLU=",
      "Testapp - Test App - Ranjith",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Indianapolis",
      "IN",
      0,
      1,
      "NG128160",
      "NGIN"
    ],
    [
      "rGrKwaKpmLykUc16TNhbas2AsHjS25AT3TNNW3YIU4k=",
      "ProdTest1_ML",
      "SbnBPktOkQMtPnEg474ofOBGC70EoMZlMB41A48ZVSI=",
      "IL",
      "Dealer",
      "Chicago",
      "IL",
      0,
      1,
      "NG128161",
      "NGIL"
    ],
    [
      "FzVwf7-OukkRYX8T8RRC-aSjwZufmZQE7imSTc9TDyU=",
      "Chris Stutsman test 98004",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Bellevue",
      "WA",
      0,
      1,
      "CMSTEST_98004",
      "CMS_TEST_CONTAINER"
    ],
    [
      "46aL80ZpX3mHZMYZnq0-SZ7_NsT_HXPB13Sw48wBJHE=",
      "Chris Stutsman test 80011",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Aurora",
      "CO",
      0,
      1,
      "CMSTEST_80011",
      "CMS_TEST_CONTAINER"
    ],
    [
      "GrsNwUYcoMCYFhXSayug0vT5BevZxxpVesNrNY_j5Oo=",
      "Chris Stutsman test 94544",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Hayward",
      "CA",
      0,
      1,
      "CMSTEST_94544",
      "CMS_TEST_CONTAINER"
    ],
    [
      "HPIZQY1UFwAsLlTNC1qgCyGFcgrHqHnygaww64MuyxE=",
      "Chris Stutsman test 01702",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Framingham",
      "MA",
      0,
      1,
      "CMSTEST_01702",
      "CMS_TEST_CONTAINER"
    ],
    [
      "TyXz07afYwy7YwduRNeBdkJuJl_EDWCW2VvCun_AQkU=",
      "AuctionGenius Test 1",
      "85iDZc_8IPF0MBqcXDbdcJ74a42pIJStw30LvZxyfTk=",
      "Auction Genius USA Dealers",
      "Dealer",
      "Longmont",
      "CO",
      0,
      1,
      "AGTEST1",
      "AGUSA"
    ],
    [
      "gp4Gu4J0Xx5ZV0ywhOCxwFUn0oVXKL5r3GOPDhqT3Kc=",
      "Price Advisor Test Entity",
      "85iDZc_8IPF0MBqcXDbdcJ74a42pIJStw30LvZxyfTk=",
      "Auction Genius USA Dealers",
      "Dealer",
      "Longmont",
      "CO",
      0,
      1,
      "TESTPAR",
      "AGUSA"
    ],
    [
      "U0vM5t5Zy8o3MsD9kwbV_O9bNA7fD3oKDbcKWx67q1Q=",
      "CAB Mobile",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Peoria",
      "IL",
      0,
      1,
      "CABMOBILETEST",
      "DEMOS"
    ],
    [
      "m7neEBLm7WrEtQdb07sptkMrIeyRs6KC6q-hOjUJY_U=",
      "Melissa Nelson",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "MELISSA_TEST",
      "DEMOS"
    ],
    [
      "h10ukpGCgSXzkvOrenNIIiBeykW0VrcH8OMpTKFRn98=",
      "Kayleen Kabella",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "KAYLEENTEST",
      "DEMOS"
    ],
    [
      "lrQqGJQwzzsxopX6j9yxGl67_pVO9MkdL2THbvPor8E=",
      "Alison Schulthesz Test Account",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "SCHUL94",
      "DEMOS"
    ],
    [
      "ACDB-YY18J71W6DFnwYxrqtDAsA5dvUANnOyonr51Jk=",
      "Blake Test 1",
      "I7pGkbL7tX0cv6fWZ7O2gqy74QSLhsD0FP1NZmvsBH0=",
      "Blake Test HQ",
      "Dealer",
      "Santa Monica",
      "CA",
      0,
      1,
      "BLAKETEST",
      "PILSBURY"
    ],
    [
      "42W9TGYjoCNVFSgK0R81qXicZ7M0tWF_a0rhChgONWQ=",
      "autototest",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Dealer",
      null,
      null,
      0,
      1,
      "UT-AUTOTESTCA",
      "UT-QAG"
    ],
    [
      "8v3V3zHG-kHXQ09kEP6tfpJqQXkSTZtkgnnQWfDhZuE=",
      "unit test",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Container",
      "Austin",
      "TX",
      5,
      0,
      "UT-CONTAINER3",
      "UT-QAG"
    ],
    [
      "YfJIZHqrG-CUXceE6TgixErYdX2TROpVfPxIHFojFVg=",
      "vAuto Longmont",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Dealer",
      "Longmont",
      "CO",
      0,
      1,
      "SWTEST",
      "ROOT"
    ],
    [
      "A_NZ9YIxeWnx1qQLRPFDwF8AWxE6aKBBh5-5BXlA4t0=",
      "NCP HYUNDAI Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_HYUNDAI",
      "NCP_CONTAINER"
    ],
    [
      "6RTXY8sEDfCZ9fQuz4raPnXCK8q4aDwXksg6bKFMXXY=",
      "NCP ACURA Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_ACURA",
      "NCP_CONTAINER"
    ],
    [
      "_ejYpBiBTcO09xctrmgH6wpcMS-o26xw8ObTAKjnIMs=",
      "Cindy Stockwave Auction Genius Test",
      "XI3vXkEgAnwmKVrdiieHG2U36nuL2H1IeaoLAZuTOlM=",
      "Cindy Bodnar HQ Test",
      "Dealer",
      "Puyallup",
      "WA",
      0,
      1,
      "CABSWAG",
      "CABHQTEST"
    ],
    [
      "vlCRLX3Ngp7r6MYn-h3h8eAdUMsS7qFhWI_XB2qqjno=",
      "Benny's Test Store",
      "_vw95iw4KoDb3192f51bSIXn7uA2tniESGY2EZ9JjEU=",
      "Michigan",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "BENNYTEST",
      "REGION$MI"
    ],
    [
      "qgEPwFr0s1o43xpylEE-VLNGAgIr7ZQ7Da7wZ1b3fec=",
      "MHogan Test3 - CQDEMO",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Big Spring",
      "TX",
      0,
      1,
      "MHOGANDEMO3",
      "SUPPORTDC"
    ],
    [
      "LDs4D45uvRDwLDmHHo5i2igKmnDe5JC-uiyf4gJeQG4=",
      "MHOGAN TEST4 - NO SW",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "MHOGANDEMO4",
      "SUPPORTDC"
    ],
    [
      "wARVREBOBRmc2I4DIsz36BWhKepyFkJ1DWyEngXJRwI=",
      "Testing Container",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Container",
      "Indianapolis",
      "IN",
      2,
      0,
      "TESTING_CONTAINER",
      "SUPPORTDC"
    ],
    [
      "Feg6pzdFWoVlbK8KZ5ggh0LO5hiW26AAstIzHz7QevE=",
      "ConQuest Support -  GM AUX TEST",
      "TxyxDq3JU54TW9wDiJIVrWkt-ESHklDYf-qbdD4vcVk=",
      "Conquest Beta",
      "Dealer",
      "Melbourne",
      "FL",
      0,
      1,
      "CONDEMO9",
      "CONBETA"
    ],
    [
      "Pj2EXM7OzPorPW_WrYIom7D3rDYFwFEJUM5aruBnT04=",
      "Mary Test",
      "2tNUg7B3Nw1gHihw_uaGa0Me5_C0EdHQn2t8z8si3NE=",
      "RCI Conversion",
      "Dealer",
      "Longmont",
      "CO",
      0,
      1,
      "RCIMD",
      "RCITOP"
    ],
    [
      "f3-aGY8_79Y_UdCjBUsY6KgepvKigDadi8ZiFixy4Lc=",
      "AutoTest Stockwave Dealer10",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW10",
      "UT-ATG"
    ],
    [
      "m8LRj3TG0UIKYJYb61JevRf2ki5lsNSr4adeJRtCIKY=",
      "AutoTest Stockwave Dealer2",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW2",
      "UT-ATG"
    ],
    [
      "JVEYjtGeajoUr_uaM-o2A5W8D-PQc4HJarlif3Ii0Zw=",
      "Stephen's Test",
      "wARVREBOBRmc2I4DIsz36BWhKepyFkJ1DWyEngXJRwI=",
      "Testing Container",
      "Dealer",
      "Indianapolis",
      "IN",
      0,
      1,
      "STW1",
      "TESTING_CONTAINER"
    ],
    [
      "0sclObTF6VITB-uElOnku8_QT-ZoyVX5r7dpbsOuaPY=",
      "Unit Test Old Stocking Radar",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Sacramento",
      "CA",
      0,
      1,
      "UT-OLDSTOCKINGRADAR",
      "TESTS"
    ],
    [
      "VXinLINovN_jpqDmXuR3WSDdrSqYUPH-cOojtxSyX6w=",
      "Brian Test 2",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "BRIANR2",
      "TESTS"
    ],
    [
      "GpAbSgagYdkMZyneT00c-kZJZVQoaKXkauONrAg2vmQ=",
      "NCP Test Dealer",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_TEST01",
      "TESTS"
    ],
    [
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Enterprise",
      "Austin",
      "TX",
      38,
      3,
      "UT-ATG",
      "TESTS"
    ],
    [
      "X9R4RSDelu9w3lY4YwMgEgZJfbXJ-W7kUczICjVaJUo=",
      "Unit Test Radar Only",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-RADAR",
      "TESTS"
    ],
    [
      "WgE8n7bHgRgFHC771seA_2gBmK0AwU9xi67QrAZGTyM=",
      "Unit Test No Demo",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-NODEMO",
      "TESTS"
    ],
    [
      "fUVIQGR_u4qvZWcEP2x1M_89wOaG-eI9QihMLGei31M=",
      "CMS Test With Load",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "CMS1011",
      "TESTS"
    ],
    [
      "tzF5KGcfvySOVDVrwE9-2KnnwIxCrx9Mnsy5LtRpvTo=",
      "Cindy Test3",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Enterprise",
      "Columbus",
      "OH",
      1,
      3,
      "CABTEST3",
      "TESTS"
    ],
    [
      "ASv_KTdKRP6P4QPceHGJLEUW8sms9whHsSrgv_2yixo=",
      "***Unit Test - Lou Fusz Mitsubishi - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Saint Peters",
      "MO",
      0,
      1,
      "UT-MP4413",
      "TESTS"
    ],
    [
      "H0z8xbv5_kw2MvAKFl2ZWWCwGZMyvx0KfqdbLuBN3QM=",
      "TEST ENTITY",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "RCITEST1",
      "USA"
    ],
    [
      "hmM6o3k2sIeYiAgRN6ycIPE3EEo-R8k-rY4Dm2ycWBc=",
      "Veracruz Auto Sales - Alpha Test Development, Inc.",
      "hUeotL27Mv1dP-QidRc-WUMp4LisG7P1heCRUoTkack=",
      "TX",
      "Dealer",
      "Cedar Park",
      "TX",
      0,
      1,
      "NG85833",
      "NGTX"
    ],
    [
      "pOktkvABDTKai2XV1ZBWse_TADURsb1SNKCeeRBkhMw=",
      "Chris Stutsman test 60642",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Chicago",
      "IL",
      0,
      1,
      "CMSTEST_60642",
      "CMS_TEST_CONTAINER"
    ],
    [
      "XG3Zn6qfb_5LcWFUIzQnJwHrwCIik6hUaQa1aFavg8k=",
      "Chris Stutsman test 77477",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Stafford",
      "TX",
      0,
      1,
      "CMSTEST_77477",
      "CMS_TEST_CONTAINER"
    ],
    [
      "krU9sAPe8ZFdYYZP_vTZaWb124wyCPgnZ-oPtAS1tCE=",
      "Chris Stutsman test 92504",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Riverside",
      "CA",
      0,
      1,
      "CMSTEST_92504",
      "CMS_TEST_CONTAINER"
    ],
    [
      "ag8k0ONayZEIv4B26QSl6ThEQllyqB7IKswd7P91gH0=",
      "Chris Stutsman test 75244",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Dallas",
      "TX",
      0,
      1,
      "CMSTEST_75244",
      "CMS_TEST_CONTAINER"
    ],
    [
      "i56xiyPzPCaX85vgn1A4YIEcxCM8-igKTVxLe42mpes=",
      "Chris Stutsman test 75051",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Grand Prairie",
      "TX",
      0,
      1,
      "CMSTEST_75051",
      "CMS_TEST_CONTAINER"
    ],
    [
      "LSAT1E-Q90aNygCEa2W8u0kkI8cq2is8BKBtbD9xg4s=",
      "Chris Stutsman test 33444",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Delray Beach",
      "FL",
      0,
      1,
      "CMSTEST_33444",
      "CMS_TEST_CONTAINER"
    ],
    [
      "k5jQrN4l7QVWkiZ4joOcjES6FvywIEaxeoPToOV757k=",
      "UT-TEST-WITH-LOAD4",
      "Z11ZTJEKAA_4Zoparn1ZKdbt1K8fUb-e0SbMVmOxAwE=",
      "Unit Test QA Group 1",
      "Dealer",
      "Tustin",
      "CA",
      0,
      1,
      "UT-TEST-WITH-LOAD4",
      "UT-QAG1"
    ],
    [
      "EO__FwO5hTpHh9ZpqkC-DaQ_lKrgNarqZ9jtl57rI4U=",
      "Unit Test QAG1 CT02",
      "Z11ZTJEKAA_4Zoparn1ZKdbt1K8fUb-e0SbMVmOxAwE=",
      "Unit Test QA Group 1",
      "Container",
      "Austin",
      "TX",
      2,
      0,
      "UT-QAG1CT02",
      "UT-QAG1"
    ],
    [
      "L5EPSiPRjDTkadnRyKpWkNPs4axQyR3VJvNe51mgGEM=",
      "(test account)",
      "RyuXsIokdr0Ipe8pGBqkdCrThZimbiOWOt-AL6T9e5k=",
      "Illinois",
      "Dealer",
      "Oak Brook",
      "IL",
      0,
      1,
      "BRIANSTORE",
      "REGION$IL"
    ],
    [
      "1mKUGPeySImh3tiWZ8RkZ8C4Spauk-2TkSq-2FUbxvk=",
      "Suchi Test",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Container",
      "Austin",
      "TX",
      0,
      0,
      "SUCHI123",
      "DEMOS"
    ],
    [
      "l3xrLQMA5B6xNbHdSyVw6_v4hoRuRrBDpZAse0YpieE=",
      "SWTestDE25638",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "TESTDE25638",
      "DEMOS"
    ],
    [
      "wW76yje-K67XGXrj684faJMWU_W0q8nQIWb59dZIxKk=",
      "Jessica Fuller Test Account",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "JFHN",
      "DEMOS"
    ],
    [
      "Zagyg5vQiObmcCscRMyTSfSL69kOrvmSzalAux7RAnM=",
      "TESTING",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Enterprise",
      "Villa Park",
      "IL",
      0,
      3,
      "VASUPPORTTESTDEMO",
      "DEMOS"
    ],
    [
      "TA30In6qGF3vMYdkDw9W9Kf2goDhfcZKf4SmDJVxOJ8=",
      "unit test 22",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Dealer",
      "Beverly Hills",
      "CA",
      0,
      1,
      "UT-QA22",
      "UT-QAG"
    ],
    [
      "HS5Lnd1fC-OOE4CjHaM90BkjgahembX2fDeKh0JbjuY=",
      "Homenet syndication",
      "mDEqHFy2UFPG2mjrPzD8AiNd1GNU-JtYOPEWe7ENqIY=",
      "Maryland",
      "Dealer",
      "Annapolis",
      "MD",
      0,
      1,
      "KJTEST7",
      "REGION$MD"
    ],
    [
      "nqf5UA8-igb8K6kQnzzEzBcNKn5S7CJzO4XMxb-z-Hk=",
      "NCP Mini Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_MINI",
      "NCP_CONTAINER"
    ],
    [
      "L8dpBgCm4IqizBPRwE5DQfVEjwa2jcjP6cw0k7o93fs=",
      "NCP DODGE Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_DODGE",
      "NCP_CONTAINER"
    ],
    [
      "g_58IYrPUAvG3KegvRu4bZVGRehcbaDLbI9NsyULqSA=",
      "NCP FIAT Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_FIAT",
      "NCP_CONTAINER"
    ],
    [
      "beE9wJ5ukmVJPSiUxgxVv_Cu9w2rxr2nFL6i2xdcf80=",
      "NCP JEEP Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_JEEP",
      "NCP_CONTAINER"
    ],
    [
      "9OLueUch6pf44uI68cvl_DsGobDZIMuk8Rv9K-z0UO4=",
      "NCP LAND ROVER Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_LAND ROVER",
      "NCP_CONTAINER"
    ],
    [
      "xwh9AvHn1mwhzWcz4Oe2GjTRvv41Bhbvyr4AYDd2OLg=",
      "NCP MAZDA Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_MAZDA",
      "NCP_CONTAINER"
    ],
    [
      "axwTtsp97JwyWagYwsEgexeUyBdlcs3Bduu-koNKwdQ=",
      "NCP MERCEDES-BENZ Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_MERCEDES-BENZ",
      "NCP_CONTAINER"
    ],
    [
      "xZ-WZ_B3AKPl91aaoCNs8Iv2_T1lbM-ho7wEeNveyrM=",
      "NCP MITSUBISHI Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_MITSUBISHI",
      "NCP_CONTAINER"
    ],
    [
      "WJjtU3rm2uoT7mZU7kNxjv0KFIWCNyM0GMJJNFVIGy8=",
      "NCP BUICK Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_BUICK",
      "NCP_CONTAINER"
    ],
    [
      "YaypRKqBZcLcuJnnQwGCP_xop3ZPxKc4K8aFa0t4rmQ=",
      "Stephen's Test",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "STW2",
      "SUPPORTDC"
    ],
    [
      "YGF7y2ZOm2R3gSr5lpWkNQj2iZhMoLqgkbkiVU4iupM=",
      "Gary Test 7",
      "9DdVnQJCH7u9XFuHB_oyYf1S9X0p6LwNtIXuMsLWOg0=",
      "Gary Test Auto Group",
      "Dealer",
      "Hinsdale",
      "IL",
      0,
      1,
      "GARYTEST7",
      "GARYAUTO"
    ],
    [
      "THLQk3hSHQJ_A-jLSknwXgYHi2MXsnZYPjcWHvDKhjw=",
      "OP-TEST-ALL Products",
      "Zy9HuJA0DW9Da01inKjexP74wCR4fFBbx0wzRj2c2fM=",
      "OP-TEST-DEMOS",
      "Dealer",
      "McHenry",
      "IL",
      0,
      1,
      "OPTALLPRODUCTS",
      "OPTESTDEMOS"
    ],
    [
      "fHLVSan4gZFXpCxvHwrvYhJ9oX3jCkac7uJB_mKCBQM=",
      "AutoTest Stockwave Dealer3",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW3",
      "UT-ATG"
    ],
    [
      "5-5x3PnvMfKKYbxHf6sWmHDt1sas09sdQbx6fxPiu-8=",
      "AutoTest Stockwave Dealer5",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW5",
      "UT-ATG"
    ],
    [
      "yJX9NsEy8n-RjJJ-9k0Q0qy880DM9jS_kmb6MojTL44=",
      "AutoTest Stockwave Dealer18",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW18",
      "UT-ATG"
    ],
    [
      "O3EQm5vhPCiZchhsn0NVYUb4wMxlvK1hPC0DhWbpf7U=",
      "AutoTest Standalone Stockwave Dealer",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Cerritos",
      "CA",
      0,
      1,
      "UT-AUTOTESTSTANDALONESW",
      "UT-ATG"
    ],
    [
      "stdYrsciypYq8J293ZYltvP3FQGvfuDGumYJUOuz6ds=",
      "Autotest Trade 2",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "San Antonio",
      "TX",
      0,
      1,
      "UT-AUTOTESTTRADE2",
      "UT-ATG"
    ],
    [
      "0PSch95f6Cr7ZDBqXGHoCdAdpn3PSgsfEqWcZ8CWxrU=",
      "AutoTest Standalone Stockwave Sandbox Dealer",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSTANDALONESW-SANDBOX",
      "UT-ATG"
    ],
    [
      "vKw6PIZZcv94Kgqu-gFSUzoNQt9Xfdb6TPgpdnyLFNw=",
      "QA Autotest Mobile Entity 02",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Concord",
      "NC",
      0,
      1,
      "UT-AUTOTESTMOBILE02",
      "UT-ATG"
    ],
    [
      "uYLgai69Y6DqWJJ-Qj-iFw2rIvEO1qmAsCRrZjhUq6g=",
      "Test Dealership 05",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Fallon",
      "NV",
      0,
      1,
      "TEST05",
      "TESTS"
    ],
    [
      "2kM8r8_PjA3wYZ2lG8dpUidy2cRw-ZUZvH_3KGn_9ZQ=",
      "***Unit Test - Lou Fusz Buick GMC - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Saint Louis",
      "MO",
      0,
      1,
      "UT-MP4213",
      "TESTS"
    ],
    [
      "zkPeBNzDmuPKNG4ZByCwCUxKMIqAn9Hc0MRO9piTXmo=",
      "***Unit Test - Lou Fusz Kia - Westport - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Saint Louis",
      "MO",
      0,
      1,
      "UT-MP12491",
      "TESTS"
    ],
    [
      "Sx5ATgyfih3W9KA469I9FDqWCcktbrrPAszO0301z-U=",
      "Unit Test Wholesaler",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-WHOLESALER",
      "TESTS"
    ],
    [
      "C83SUrjBDcMIEu4VJrsoUSmhLpoaZ9sEo3Mt_BTzLTg=",
      "Unit Test - Alpha 3",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-ALPHA3",
      "TESTS"
    ],
    [
      "UFPCwGAh8ZNRYmuLQVQ9At5oToFoYjsge0c9K8BJWm8=",
      "Test QA Group 02",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Enterprise",
      "Austin",
      "TX",
      1,
      3,
      "UT-QAG02",
      "TESTS"
    ],
    [
      "fGYnQ-EI8HOhsadHSHCfCHQhXGSOlNtb8B6UkdtFIzs=",
      "***Unit Test - Lou Fusz Subaru of St. Peters - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Saint Peters",
      "MO",
      0,
      1,
      "UT-MP13449",
      "TESTS"
    ],
    [
      "U7xw1YugszHQucwOSqlFCsR4rqkw3Bc8LNFXEpeDQJg=",
      "Unit Test - Radar and Appraisal",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Mc Lean",
      "VA",
      0,
      1,
      "UT-RADARAPPRAISAL",
      "TESTS"
    ],
    [
      "Qb1mL-DyDCujeg_GYHeHKCvfXJPW3E7tWAj-iB8yAoU=",
      "Test Dealership 03",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Suitland",
      "MD",
      0,
      1,
      "TEST03",
      "TESTS"
    ],
    [
      "VU-lECf7sFa2QQ_ZYEk_osKYALKSRaKOzuo04k7up1o=",
      "SQL Templates  Test - Homenet MP371",
      "3wV2TC96MVcN4JPQbzMUfx0A9js4dKGMCvxuxKOUchk=",
      "SQL Templates  Test - Container",
      "Dealer",
      "Las Vegas",
      "NV",
      0,
      1,
      "SQLTEMP-HOMENET",
      "SQLTEMP-CONTAINER"
    ],
    [
      "O34bRTqnluy3Q5nb5sPC2UIzLePGyXjo51EC3slx20M=",
      "Test group for ATG Vin Repository",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Container",
      "Austin",
      "TX",
      2,
      0,
      "CMS_VIN_REPO_GROUP",
      "USA"
    ],
    [
      "Q9Zo6bFIuiPve6x0C2EgkHHIcArXhMMBNM6dt0nxPuY=",
      "Luke's Cars - Test Dealer Application Test3",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG119187",
      "NGIN"
    ],
    [
      "ppX07lQ-9TYzZBovI1x-VUW9jXOcubZwzBubRbXhbvk=",
      "Chris Stutsman test 98032",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Kent",
      "WA",
      0,
      1,
      "CMSTEST_98032",
      "CMS_TEST_CONTAINER"
    ],
    [
      "ZJjKdswXSP_W_fgZFFJZ9VBwksnPNhDolS6Nx7INcxw=",
      "Chris Stutsman test 75236",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Dallas",
      "TX",
      0,
      1,
      "CMSTEST_75236",
      "CMS_TEST_CONTAINER"
    ],
    [
      "kf0fg2uLqvhL1TDWhP9yL2aCVL6e10G_sCqQbI9k4oI=",
      "Ultimate Test Drive Los Angeles",
      "Yw52KvKqcK0bXj9azN3lCanV0Et4bl3VIEQEF6tbK4w=",
      "Major Accounts ATG Demo Reports",
      "Dealer",
      "Los Angeles",
      "CA",
      0,
      1,
      "AVISDEMOLA",
      "ATGDEMOC"
    ],
    [
      "O8ASizvkOQEeJJGLAQbN29NYxUTm0uqvXAzT02fKGOw=",
      "Ultimate Test Drive Orlando",
      "Yw52KvKqcK0bXj9azN3lCanV0Et4bl3VIEQEF6tbK4w=",
      "Major Accounts ATG Demo Reports",
      "Dealer",
      "Orlando",
      "FL",
      0,
      1,
      "AVISDEMOORLANDO",
      "ATGDEMOC"
    ],
    [
      "LXvbFCpAiVVWLZ-JhnrDowxCt9E9f5oIoB8HAd_0dus=",
      "Ro🅱 K Test Account",
      "nMcmQkbyNkPMqlcUnH51rSo0fmq0ZnQh_q4PEyGJP3Y=",
      "Homenet CDK conversions",
      "Dealer",
      "San Antonio",
      "TX",
      0,
      1,
      "ROBKTEST",
      "HNCDK"
    ],
    [
      "n3RTbsjDx7VdukB5Uiafq9VoWfNL7RRlP6SY-itrots=",
      "MHogan Test - Code",
      "nMcmQkbyNkPMqlcUnH51rSo0fmq0ZnQh_q4PEyGJP3Y=",
      "Homenet CDK conversions",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "MHOGANDEMOC",
      "HNCDK"
    ],
    [
      "Xknxi2slinRAxmlujB7kqrtGCY2IpzZFrikE7ep1pCg=",
      "Unit Test QAG1 CT01",
      "Z11ZTJEKAA_4Zoparn1ZKdbt1K8fUb-e0SbMVmOxAwE=",
      "Unit Test QA Group 1",
      "Container",
      "Muleshoe",
      "TX",
      0,
      0,
      "UT-QAG1CT01",
      "UT-QAG1"
    ],
    [
      "ktqE_grZ9nlw7IRJRPWxGvsuKJ7QssBNLC8kOqW6h8g=",
      "cab rbook test 2",
      "F5hyE76ijReArMxERDnyOfEWWSMjzGJ9O0nIy8klyRI=",
      "cab Rbook Test",
      "Dealer",
      "Garrettsville",
      "OH",
      0,
      1,
      "CABRBOOK2",
      "CABRBOOK"
    ],
    [
      "LMmQB9Th_ev-aoKFd__6ZXWndTx2z5IX5gXEzQcGCek=",
      "Norm Reeves Research",
      "QsICVGYC94esa0X8fRD8ipwTh6cPdweQ7TJRTvWtxXI=",
      "The CAR Group",
      "Dealer",
      "Irvine",
      "CA",
      0,
      1,
      "NRTEST",
      "NORMREEVES"
    ],
    [
      "3wV2TC96MVcN4JPQbzMUfx0A9js4dKGMCvxuxKOUchk=",
      "SQL Templates  Test - Container",
      "9yDlyBv5J4sRxVvk-iNQMLN8-DU2QCnq5XZDmqkTM_8=",
      "SQL Templates  Test - Enterprise",
      "Container",
      "Las Vegas",
      "NV",
      18,
      0,
      "SQLTEMP-CONTAINER",
      "SQLTEM-ENTERPRISE"
    ],
    [
      "BVbAqty4ck8L8OV842iGtPsBJl1wflVoenEDaTgGX44=",
      "CA Test Dealership 01",
      "qNZ3lfANcPIZ1Na32cZpPRw2vHtpu0Bc5Hoyu6tGLpc=",
      "TraderCanada Testing",
      "Dealer",
      null,
      null,
      0,
      1,
      "CA-TEST01",
      "TC-TESTS"
    ],
    [
      "omhQSi0QAt4KNznAJrqByFGFTUeutkiAYcUQdk3_L2U=",
      "CAB Redshift Test",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Cedar Park",
      "TX",
      0,
      1,
      "CABRSTEST",
      "DEMOS"
    ],
    [
      "Dl3etofNMFcIjW7gvvzK0E-2bk_-Vzlk6kvL7rk7buA=",
      "Ashleigh Test Account",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "ASH17",
      "DEMOS"
    ],
    [
      "ft2VFBxdIgUjPDncseIgfGT2yWkWnNDX5TZbQoLgzzs=",
      "Test Demo Sub HQ",
      "REr304D49nSPgzK3lDX7oK8xjwyOFLwf8bDXcyrwNSc=",
      "Team_Demos",
      "Container",
      "Woodridge",
      "IL",
      1,
      0,
      "TESTSUBHQ",
      "TEAMDEMOS"
    ],
    [
      "CLXMtqKJRdUQlSMYynFPltaXdPe8_PEs15ZrxHMjurw=",
      "UT-TEST-WITH-LOAD",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Dealer",
      "Tustin",
      "CA",
      0,
      1,
      "UT-TEST-WITH-LOAD",
      "UT-QAG"
    ],
    [
      "VqOuZrEfK_c0-_xmuq5OSbYhXuu0PwdxWGM8J6qekF8=",
      "JP Homenet Template test",
      "jVsVY51qDZJnGObVSr3wjtn6IiKL1_On035jKanlEfg=",
      "Homenet API Conversion Project",
      "Dealer",
      "Saint Albans",
      "VT",
      0,
      1,
      "JPTEMPLATEHN",
      "HNCONV"
    ],
    [
      "FKHyTeAqVodXaNKfzvy5ACC8CIs52rygF3Ixh6AW4x0=",
      "X45TEST",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Dealer",
      "Cedar Park",
      "TX",
      0,
      1,
      "X45TEST",
      "ROOT"
    ],
    [
      "W8zBUqEeKYVI8gHD_lQohTYhQHG0RO-StFp8rjR4y0Y=",
      "Kevin's RCI Test Account",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "RCIKL",
      "ROOT"
    ],
    [
      "Qm46yTvdC_YHFOel-PNzi59aTVIRTQ9qWpHW0N9ZsSU=",
      "Gary Test 10",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Container",
      "Woodridge",
      "IL",
      0,
      0,
      "GARYDEMO10",
      "ROOT"
    ],
    [
      "ict8bpZ7ansCCTLEeGUFV5bfQkrmI0tpzmi_HIWbtUM=",
      "NCP SMART Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_SMART",
      "NCP_CONTAINER"
    ],
    [
      "KrIFiaojryhtlki72nOe2T_Jfipg62DAR0b_il8lKY0=",
      "NCP SUBARU Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_SUBARU",
      "NCP_CONTAINER"
    ],
    [
      "fV9CUFGDN0FTyiWVSIFGpUQiN1YBV4Bbo1pG35E1wxs=",
      "Don Tester Ford Lincoln",
      "oleujlXVBQ1NahnbSeeWRTzzWXxv9sTiQNBRcf_hwMU=",
      "Ohio",
      "Dealer",
      "Norwalk",
      "OH",
      0,
      1,
      "MP4683",
      "REGION$OH"
    ],
    [
      "4DCVSxuwPwQc-iFBT2Mt9A78FFlpqCDdeLYTbCMnqYU=",
      "Caitlyn Anderson Demo",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "CAITLYNTEST",
      "SUPPORTDC"
    ],
    [
      "iKZZ1UspDYR0Yi-ZkHD677VV4mNgmKdeiyVyGuJmMo4=",
      "MHogan Test2",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Memphis",
      "TN",
      0,
      1,
      "MHOGANDEMO2",
      "SUPPORTDC"
    ],
    [
      "X7Q5uMRp-epFOZcGI5lknsMgUsypOhdMBVLSaot0Ou8=",
      "KJ Test 3 S&K Buick",
      "TxyxDq3JU54TW9wDiJIVrWkt-ESHklDYf-qbdD4vcVk=",
      "Conquest Beta",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "KJTEST3",
      "CONBETA"
    ],
    [
      "qNZ3lfANcPIZ1Na32cZpPRw2vHtpu0Bc5Hoyu6tGLpc=",
      "TraderCanada Testing",
      "skDUTxVnMn37Zrs3nLrXi952tgDoCZsCpBJ34opv8KE=",
      "Canadian Dealers",
      "Container",
      null,
      null,
      36,
      0,
      "TC-TESTS",
      "CANADA"
    ],
    [
      "WXAnqlleDkyq2rbm3_yJf1enWrpOxM9bbIxFtQxJXwI=",
      "test",
      "8v3V3zHG-kHXQ09kEP6tfpJqQXkSTZtkgnnQWfDhZuE=",
      "unit test",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-TEST1",
      "UT-CONTAINER3"
    ],
    [
      "LJhy5IEiDUKV8nJj5Je9AQiU7Gskw-nBVMCV8tRC3Xc=",
      "Pagan TEST",
      "2tNUg7B3Nw1gHihw_uaGa0Me5_C0EdHQn2t8z8si3NE=",
      "RCI Conversion",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "RCI9",
      "RCITOP"
    ],
    [
      "Q1a7ZlQQdEEy6lq33dOgd7CkDzSNa3uVxhzO_KRqV0s=",
      "AutoTest Stockwave Dealer11",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW11",
      "UT-ATG"
    ],
    [
      "sEUM2BwDnheEGtOKv4MW-YgjtL0Q_aZZ0z7xl-PWBN4=",
      "AutoTest Stockwave Dealer22",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW22",
      "UT-ATG"
    ],
    [
      "4_lazUWzzvmfHK-ybLfTz25DeCErLeRSkGFZB2KqB8Q=",
      "AutoTest Stockwave Dealer12",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW12",
      "UT-ATG"
    ],
    [
      "H-uNVM1b3cv0ygqOud7mkIXEFVoFmgipLJssC3alEeg=",
      "AutoTest Stockwave Dealer16",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW16",
      "UT-ATG"
    ],
    [
      "BDqT8Z1DtR3_A1bpfYosAVEnleYy_7uirMELXp6Kagk=",
      "AutoTest Stockwave Dealer7",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW7",
      "UT-ATG"
    ],
    [
      "Z7cfMaNy2-oIY6Ia7c4MCiz4PapsPjIK5FqbpalPDMw=",
      "Autotest Dealership 03",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "San Antonio",
      "TX",
      0,
      1,
      "UT-AUTOTEST03",
      "UT-ATG"
    ],
    [
      "q2NCx7YSJjJ77-nukwXbxnf_ngk6_bEmlAf8nRBAoW4=",
      "Test StephenWade",
      "tzF5KGcfvySOVDVrwE9-2KnnwIxCrx9Mnsy5LtRpvTo=",
      "Cindy Test3",
      "Dealer",
      null,
      null,
      0,
      1,
      "CABTEST3A",
      "CABTEST3"
    ],
    [
      "1igJ4TCbFu_P83nb9Qn3d5ckQk3WgaNwEWJAqb7JP_c=",
      "UNIT TEST QA GROUP03",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Enterprise",
      "Austin",
      "TX",
      1,
      3,
      "UT-QAG03",
      "TESTS"
    ],
    [
      "9tmkwgetTAetTkYoP4c1TRK13aOp0tcFCyJbuA5rbx0=",
      "Unit Test Appraisal Lite",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-APPRAISAL-LITE",
      "TESTS"
    ],
    [
      "dWghTO-ZIFN8g9v55ixQzM0Xp80WAJbc5GCu0sKG38s=",
      "Unit Test - All Conquest",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-ALLCONQ-1",
      "TESTS"
    ],
    [
      "LUQ8KrUagwxyymf9IWASbfpW8bPTz5dKKbhYL23Y0GE=",
      "Test Dealership 09",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "TEST09",
      "TESTS"
    ],
    [
      "1Elich4qodyfKPvYBVKrUC5pzuGtzRdlqtGVdVSH9gE=",
      "Unit Test Multi Out",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-MULTIOUT",
      "TESTS"
    ],
    [
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Container",
      "Austin",
      "TX",
      399,
      0,
      "TESTS",
      "USA"
    ],
    [
      "EnMGq4_L5F7L2su4NWsT2bLfDQIfg4oAzb-KJLUnKlo=",
      "Lilly5",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Enterprise",
      "Bloomington",
      "IL",
      1,
      3,
      "LILLYTEST5",
      "USA"
    ],
    [
      "wQAqxzQY8dnwVpDlGG-f1qk7_YHKUgpD7uNP3mKjyA4=",
      "myNextGear Motors Test - Test Corp Quick Buyer",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG81455",
      "NGIN"
    ],
    [
      "f2yHZ7yrDjJbrfB12mCb54k9m2qtgyS-Na3UTPZcDEU=",
      "Test_deepika_reddy",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG128157",
      "NGIN"
    ],
    [
      "x-koMMDLGJsQrjesPwecPFnbBGvUcKPHnIhhwH3LgWc=",
      "Tower Auto Sales - Tower Auto Sales, Inc.",
      "rtViiwy5GS3UGBgORdX-QDArOY7OLlK-JjKy9LjhPME=",
      "NY",
      "Dealer",
      "Whitestone",
      "NY",
      0,
      1,
      "NG113365",
      "NGNY"
    ],
    [
      "EBlcQHfyh9pbo5cC7A0A0QHcyz7Qi0n-2kn206coQYw=",
      "Chris Stutsman test 98034",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Kirkland",
      "WA",
      0,
      1,
      "CMSTEST_98034",
      "CMS_TEST_CONTAINER"
    ],
    [
      "L1SRnkjE4jradWu2s1ywODDGvJ521Ss-8BmS4FICLJU=",
      "Chris Stutsman test 32771",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Sanford",
      "FL",
      0,
      1,
      "CMSTEST_32771",
      "CMS_TEST_CONTAINER"
    ],
    [
      "czGoNNBV4g0iQxsvusxSKlpiYtpTMQ_WPCcOmcaawds=",
      "Ultimate Test Drive Sacramento",
      "Yw52KvKqcK0bXj9azN3lCanV0Et4bl3VIEQEF6tbK4w=",
      "Major Accounts ATG Demo Reports",
      "Dealer",
      "Sacramento",
      "CA",
      0,
      1,
      "AVISDEMOSAC",
      "ATGDEMOC"
    ],
    [
      "eZxx8WhrHnxm9TEzNOdhAeY2tKr5Q1zjWTiMftSIlBM=",
      "Test Dealer for ATG Vin Repo NoMkt",
      "O34bRTqnluy3Q5nb5sPC2UIzLePGyXjo51EC3slx20M=",
      "Test group for ATG Vin Repository",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "CMS_VIN_REPO_DEALER_NOMKT",
      "CMS_VIN_REPO_GROUP"
    ],
    [
      "RssNq2rqfFYze80aK8RqyibMtUod9_3p8rMfPM6YglM=",
      "Joeylynn Test",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Seattle",
      "WA",
      0,
      1,
      "JOEYLYNNTEST",
      "DEMOS"
    ],
    [
      "Zy9HuJA0DW9Da01inKjexP74wCR4fFBbx0wzRj2c2fM=",
      "OP-TEST-DEMOS",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Container",
      "McHenry",
      "IL",
      3,
      0,
      "OPTESTDEMOS",
      "DEMOS"
    ],
    [
      "aJilDOmOxoVwZ14S3Hj51_UARd7-AQgg6oBbcsyEGcs=",
      "Rebeca Test Account 2",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "RHHN2",
      "DEMOS"
    ],
    [
      "Ug22YIflrxr2cahHpmzWu9oHiK7wIQ84ISyIgjH-bzY=",
      "UT-TEST-WITH-LOAD3",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-TEST-WITH-LOAD3",
      "UT-QAG"
    ],
    [
      "iizJScYvvcgNTNoq7PPAxXiCJGgXfnVFWZXjg7Wc_y8=",
      "UT-TEST-WITH-LOAD2",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-TEST-WITH-LOAD2",
      "UT-QAG"
    ],
    [
      "5UVwYI10-ETYnKSSkmsZ99eUzEhzAp6bzNPV02AD4cY=",
      "Stephen Conquest Dealership",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "SLMTEST_NCP",
      "ROOT"
    ],
    [
      "jVAupLlDxpVT5ikGVLAYlH3MCXLCnoaqyxW7GOCT0WI=",
      "NCP NISSAN Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_NISSAN",
      "NCP_CONTAINER"
    ],
    [
      "CUW4hOhOmqBKr6ssog5bt7ZQvhfT0cI7CAmPvkGG9zs=",
      "NCP SAAB Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_SAAB",
      "NCP_CONTAINER"
    ],
    [
      "mwIJrQzPAUVGNA1uJCXBefOxH9FOPLHLRsVWOxqIz1k=",
      "NCP SUZUKI Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_SUZUKI",
      "NCP_CONTAINER"
    ],
    [
      "hYgnVmTuZJpOR85UxwYodPLEm_ORThEBF-3MXQQA_0Q=",
      "Martin Test - No SQLTemplates",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "MARTTEST",
      "SUPPORTDC"
    ],
    [
      "34wmqfMkHbJ4AyOYMJxKa4luw01LJE0rlIJc2SPK6iA=",
      "jerry test ca",
      "skDUTxVnMn37Zrs3nLrXi952tgDoCZsCpBJ34opv8KE=",
      "Canadian Dealers",
      "Dealer",
      null,
      null,
      0,
      1,
      "JERRYTESTCA",
      "CANADA"
    ],
    [
      "Zg98iC-IzxUqoR06hSY8RV3etcDyYpWDEB8NfT_vatM=",
      "OP-TEST-STOCKWAVE",
      "Zy9HuJA0DW9Da01inKjexP74wCR4fFBbx0wzRj2c2fM=",
      "OP-TEST-DEMOS",
      "Dealer",
      "Alpharetta",
      "GA",
      0,
      1,
      "OPTSTOCKWAVE",
      "OPTESTDEMOS"
    ],
    [
      "BWSXP3zl5Jmz9fEvnb8unIQT4w-5lX6BVuTH-QG7lBc=",
      "AutoTest Stockwave Dealer20",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW20",
      "UT-ATG"
    ],
    [
      "coOk4MOVU8OSs3VKLFsoRok9SsZ4cTdby_caYKJ7POY=",
      "AutoTest Stockwave Dealer8",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW8",
      "UT-ATG"
    ],
    [
      "QXJZ6CmuHWCMxtgVUyZGxGAvT8MvPtwKXVEjxJNlxuU=",
      "AutoTest Stockwave Dealer14",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTSW14",
      "UT-ATG"
    ],
    [
      "DQudpdyr4FWJId1hqM1e8UwUF-vyR5SVq4ioKpLIRD8=",
      "Autotest Trade 1",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTESTTRADE1",
      "UT-ATG"
    ],
    [
      "yH_bOsIA0p9yIs4fOC56AWt__Pk-LLBo-62XfFZL8G8=",
      "AutoTest Dealership 1",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-AUTOTEST01",
      "UT-ATG"
    ],
    [
      "3uyA_hcSscPX7mb7zm894piQsYd_h5CmyDv986LaAio=",
      "Unit Test Autotrader 1",
      "G6i8qo-_EXEN0KJAq9zShPcn704k3qIwLPrBo15olpM=",
      "Unit Test Autotest Group",
      "Dealer",
      "Atlanta",
      "GA",
      0,
      1,
      "UT-AUTOTRADER1",
      "UT-ATG"
    ],
    [
      "dIKz1bOhsFAnzuqydlz-TFIDMD4bzC3P9SBwNByjFAE=",
      "Unit Test Stocking plus Radar",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Honolulu",
      "HI",
      0,
      1,
      "UT-STOCKINGRADAR",
      "TESTS"
    ],
    [
      "SGkSR5sNj0rWU9cgPb9YfjPRb759YTHh8yw8_kIwfXQ=",
      "Test Dealership 02",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Pinconning",
      "MI",
      0,
      1,
      "TEST02",
      "TESTS"
    ],
    [
      "n89bBMjpfn-zot_peM7lsXHJMTgzes2OSLF9cA5Z-cw=",
      "***Unit Test - Lou Fusz Mazda - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Saint Louis",
      "MO",
      0,
      1,
      "UT-MP4218",
      "TESTS"
    ],
    [
      "43-WQfiPzAeYBEfev8CuVqdOTw22vjg_-cucB_Zz5Zs=",
      "BRIANS TEST",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "BRIANRO",
      "TESTS"
    ],
    [
      "dk4HeVH0zLkx48tZwOCeMyFlI7v-GMvz1KOfSzhZzfE=",
      "***Unit Test - Lou Fusz Chrysler Jeep Dodge - DO NOT USE***",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "O Fallon",
      "MO",
      0,
      1,
      "UT-MP4215",
      "TESTS"
    ],
    [
      "uZ9e03zDODFJ4VPIK-fByYkrmGHwngrvYFBQzPid154=",
      "Test Dealership 04",
      "V4AwTInFk_tJI2W_Zfv16NYEUXUJnjq9x3CoQhQb0YA=",
      "Testing",
      "Dealer",
      "New York",
      "NY",
      0,
      1,
      "TEST04",
      "TESTS"
    ],
    [
      "y3LjzpsU0wjtWuLYqn-3eGgr9gC3Mntrtq_MmbG9ojA=",
      "Andrew's Test Demo",
      "2LAncepAnFlIpISVTrDfHb9d0fjxGFQ6mrOK0ONWG1U=",
      "Teams Leads Demo",
      "Dealer",
      "Oklahoma City",
      "OK",
      0,
      1,
      "ADTEST",
      "TEAMLEADSDEMO"
    ],
    [
      "bP8L2FJhCZcFkK1PsfycIYiO6mgBzYSi2Vug6exoIjM=",
      "Andrew's SW Standalone Demo",
      "2LAncepAnFlIpISVTrDfHb9d0fjxGFQ6mrOK0ONWG1U=",
      "Teams Leads Demo",
      "Dealer",
      "Cairo",
      "GA",
      0,
      1,
      "ADSWSTEST",
      "TEAMLEADSDEMO"
    ],
    [
      "88jEEAVcqLdGr1QQ3tzwh9cRAQMiiNQLKb-MwbB_RgY=",
      "Test 07",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Enterprise",
      "Austin",
      "TX",
      0,
      3,
      "TEST07",
      "USA"
    ],
    [
      "I7pGkbL7tX0cv6fWZ7O2gqy74QSLhsD0FP1NZmvsBH0=",
      "Blake Test HQ",
      "IiD8YS3Y0kmiUhvLGARfccqlXYqg8LJ-bRiDCsRIgLk=",
      "USA Dealers",
      "Enterprise",
      "Spring Grove",
      "IL",
      3,
      3,
      "PILSBURY",
      "USA"
    ],
    [
      "pbaLF78aL5Enk8iKKOjn58Wn9I39ZCgPNibRA_UKA74=",
      "Bandit's Motor Cars - Test Dealer Application Test2",
      "f_HFNshKj4pZglC7lzmdoW1Xashambte1SFiCiVL1TQ=",
      "IN",
      "Dealer",
      "Carmel",
      "IN",
      0,
      1,
      "NG119186",
      "NGIN"
    ],
    [
      "vw8k4jVG5W-bE6ZsUSpD7F9hxPwe4r6rAtXg10n02F0=",
      "CENTRA AUTO SALES - JUDITH M TESTA",
      "rtViiwy5GS3UGBgORdX-QDArOY7OLlK-JjKy9LjhPME=",
      "NY",
      "Dealer",
      "Frankfort",
      "NY",
      0,
      1,
      "NG71002",
      "NGNY"
    ],
    [
      "O4gdLVgcRA6yTxQWUPkEmFmtLGXgYse0ey9gxm-jw5M=",
      "Chris Stutsman test 23060",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Glen Allen",
      "VA",
      0,
      1,
      "CMSTEST_23060",
      "CMS_TEST_CONTAINER"
    ],
    [
      "G5JppEIerW9kIoC34SQOR3BQTIKapqNRy051xo0M1WM=",
      "Chris Stutsman test 30349",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Atlanta",
      "GA",
      0,
      1,
      "CMSTEST_30349",
      "CMS_TEST_CONTAINER"
    ],
    [
      "ZoScZantlWG43UU7d69hTOm6vjHAuIupOXIfqvGNF5U=",
      "Chris Stutsman test 08505",
      "J7ZmzXKAVoHuIFph3CgtbSGfzazMi3VtLhyPbGbjyHc=",
      "Chris Stutsman Test Container",
      "Dealer",
      "Bordentown",
      "NJ",
      0,
      1,
      "CMSTEST_08505",
      "CMS_TEST_CONTAINER"
    ],
    [
      "JfcjVgzx_SQM4QAsoQFtKLXOEz6_V8crTeDr1Ua1vR0=",
      "Cindy Bodnar Test",
      "180wJIhmsg3Bqj33Az0I6xviB0gScDDLXhi8AgwI810=",
      "Stockwave Demo",
      "Dealer",
      "Columbus",
      "OH",
      0,
      1,
      "CABSWONLY",
      "STOCKWAVE_DEMO"
    ],
    [
      "LVUG1oqbnn3jz_DxNZRzsA7lOIB4U7pm62ac5tMn1lw=",
      "VIN Repo Test With Market",
      "O34bRTqnluy3Q5nb5sPC2UIzLePGyXjo51EC3slx20M=",
      "Test group for ATG Vin Repository",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "CMS_VIN_REPO_DEALER_MKT",
      "CMS_VIN_REPO_GROUP"
    ],
    [
      "9yDlyBv5J4sRxVvk-iNQMLN8-DU2QCnq5XZDmqkTM_8=",
      "SQL Templates  Test - Enterprise",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Enterprise",
      "Las Vegas",
      "NV",
      19,
      3,
      "SQLTEM-ENTERPRISE",
      "DEMOS"
    ],
    [
      "SkxdkbFnqC30T1fMOdwbS3_daenSQTUG--IGpQ5Gk5s=",
      "Rebeca Test Account 1",
      "7wzSrBSKkVuzIUQn9XJo0DNYoPFgPKedZ7EpYkzUgVA=",
      "Demos (Demo)",
      "Dealer",
      "Villa Park",
      "IL",
      0,
      1,
      "RHT1",
      "DEMOS"
    ],
    [
      "giAwMVL_ZbsLIQVXWiQe8_ueoJSBMY4-Csf_uPRPPq8=",
      "unit test 23",
      "EO__FwO5hTpHh9ZpqkC-DaQ_lKrgNarqZ9jtl57rI4U=",
      "Unit Test QAG1 CT02",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "UT-QA23",
      "UT-QAG1CT02"
    ],
    [
      "Mtq22dVXBxQ52NB7tW6_CArs6QRbe2nHbN8_1JDsdx0=",
      "Unit Test QAG CT01",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Container",
      "Austin",
      "TX",
      7,
      0,
      "UT-QAGCT0111",
      "UT-QAG"
    ],
    [
      "ntL5tV34ltf_omKbu7LDSwCwNnG1vLCY0HRWZSBYaPs=",
      "HARITHA-TEST",
      "AtiGXC6WiTTGeCBtsaOzoUknqx7LFqEkylUsNKr2Hks=",
      "Unit Test QA Group",
      "Dealer",
      "Round Rock",
      "TX",
      0,
      1,
      "HARITHA-TEST",
      "UT-QAG"
    ],
    [
      "DzUzCTUQJhukb3OklCN3JqYJu-07VOKGTKR50s_QHVI=",
      "Tom Wood Toyota",
      "MtknP464MLHXyHuFpb2p_BGe85opYUqqphC5G7YQBko=",
      "Tom Wood BKE Sub HQ",
      "Dealer",
      "Whitestown",
      "IN",
      0,
      1,
      "MP2873",
      "TOMWOODBKE_SUBHQ"
    ],
    [
      "Zn06MdJtMXWULmd0vemeKmBC93OfsqiKcqWQlQQyb90=",
      "Ross Test 2",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "ROSSTEST2",
      "ROOT"
    ],
    [
      "EVAIvBEowqu2GFmuufVgHBKSOhKULInZ65hxzS0jnJM=",
      "New SW Test",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Dealer",
      "Eveleth",
      "MN",
      0,
      1,
      "NEWSWTEST",
      "ROOT"
    ],
    [
      "XI3vXkEgAnwmKVrdiieHG2U36nuL2H1IeaoLAZuTOlM=",
      "Cindy Bodnar HQ Test",
      "_7m-fv8BkCJIcC4xWhOFOsfs_g62aao7CTdQUtdacEc=",
      "vAuto",
      "Enterprise",
      "Dover",
      "DE",
      3,
      3,
      "CABHQTEST",
      "ROOT"
    ],
    [
      "IEJkkzJtZu9IxsFMCikYA2zj5pv51TCKrrb72Xmy7yA=",
      "NCP GMC Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_GMC",
      "NCP_CONTAINER"
    ],
    [
      "i9xiPq4hWmWY8DfQjbWwiY7tsSKeOCScFnHz43jtBA8=",
      "NCP NISSAN TRUCK Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_NISSAN TRUCK",
      "NCP_CONTAINER"
    ],
    [
      "5o_-MCL1wE6sXaabZOx8kM4Ix_udj6VEb4aMqGozOWE=",
      "NCP TOYOTA Test",
      "w8qjI14F4n5LP_extJGxMK_Py0DEc6IWQAElH_hKFVY=",
      "New Car Pricing Dealers",
      "Dealer",
      "Austin",
      "TX",
      0,
      1,
      "NCP_TOYOTA",
      "NCP_CONTAINER"
    ],
    [
      "usf5ifJ1lSYRO5I8JhAx6-cwPUParKy-L_Q9GY8s610=",
      "Martin's Test Account - SQLTemplates",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Three Rivers",
      "MI",
      0,
      1,
      "MARTINTEST",
      "SUPPORTDC"
    ],
    [
      "L829VanVkXd61UjoG_blT0QKhBSrTPmw6oD8B7px6C4=",
      "MHogan Test",
      "X5qzVcNAbCnPugeSahBT2DPf6cew3Z7uKK56UMWK1QI=",
      "Support Demo Container",
      "Dealer",
      "Memphis",
      "TN",
      0,
      1,
      "MHOGANDEMO",
      "SUPPORTDC"
    ],
    [
      "OOQhgEq1K6HR7oRTaAlj3AJHCmfy3var8FQJijCILBo=",
      "KJ Test Two",
      "TxyxDq3JU54TW9wDiJIVrWkt-ESHklDYf-qbdD4vcVk=",
      "Conquest Beta",
      "Dealer",
      "Des Plaines",
      "IL",
      0,
      1,
      "KJTEST2",
      "CONBETA"
    ],
    [
      "4Rc_OKENEvPdxBBRLdjPQPOAk73BMWO1jz2U16XRUgs=",
      "test",
      "1mG7jG2cI-9ZWHSmvZXGh0sZmEMxxyZ_Bt1-9CzMStQ=",
      "Jerry's Auto HQ - Demo",
      "Dealer",
      "Itasca",
      "IL",
      0,
      1,
      "JERDEMOC3",
      "JERDEMOHQ"
    ],
    [
      "Evi6hPfg9SwaymrkqBvcxm72mrfOPRwiJxEv7SjVlPw=",
      "Brian Canadian Testing Demo",
      "skDUTxVnMn37Zrs3nLrXi952tgDoCZsCpBJ34opv8KE=",
      "Canadian Dealers",
      "Dealer",
      null,
      null,
      0,
      1,
      "BRIANCANADA",
      "CANADA"
    ],
    [
      "CwEcA4BuFgp3HGz612QC8Yt7OY8IV70AwDMV2kjyXxE=",
      "unit test qa 21",
      "8v3V3zHG-kHXQ09kEP6tfpJqQXkSTZtkgnnQWfDhZuE=",
      "unit test",
      "Dealer",
      "Woodway",
      "TX",
      0,
      1,
      "UT-QA21",
      "UT-CONTAINER3"
    ],
    [
      "fudwjvmzGOIXXaUokGEujjNKP9DzM6MkiSYuIymVuAQ=",
      "RCI JB Test",
      "2tNUg7B3Nw1gHihw_uaGa0Me5_C0EdHQn2t8z8si3NE=",
      "RCI Conversion",
      "Dealer",
      "Oak Brook",
      "IL",
      0,
      1,
      "RCIJBTEST",
      "RCITOP"
    ]
  ]
};

export const themeConfig = {
  currentTheme: {
    name: "provision",
    isExternalTheme: false
  },
  themes: [
    {
      name: "conquest",
      isExternalTheme: false
    },
    {
      name: "stockwave",
      isExternalTheme: true
    },
    {
      name: "irecon",
      isExternalTheme: true
    },
    {
      name: "priceadvisor",
      isExternalTheme: true
    }
  ],
  isProfitTime: true
};

export const pageConfig = {
  showMenu: true,
  showQuickSearch: true,
  showLanguageToggle: true
};

export const performanceManagerInfo = {
  name: "Richard Bligh",
  workPhone: "(272) 717-2222",
  mobilePhone: null,
  email: "rbligh@apple.com",
  supportNumber: "(877) 828-8614 option 2"
};
