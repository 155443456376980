import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { DropdownMenu } from "@coxkit/dropdown";
import iconMenuDown from "./icon-menu-down.png";
import { DataContext } from "../DataContext";
import { AppList } from "./AppList";

class RawAppSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const dataContext = this.context.data || {},
      themeConfig = dataContext.themeConfig || {},
      currentTheme = themeConfig.currentTheme || {},
      themesList = themeConfig.themes || [],
      themeClass = `${currentTheme.name}${themeConfig.isProfitTime ? " profit-time" : ""}`;

    console.log(themeConfig);

    return (
      <div className={`va-app-switcher ${this.props.className}`}>
        <a href="/Va" className={`va-logo ${themeClass}`}>
          <img alt="" src={require("../../images/pixel.gif")} />
        </a>
        <DropdownMenu
          className="va-selector-dropdown"
          style={{ display: themesList.length ? "flex" : "none" }}
          text={<img src={iconMenuDown} />}
          button={false}
          placement="pos-below align-right"
          autoUpdate={false}
        >
          <AppList />
        </DropdownMenu>
      </div>
    );
  }
}

RawAppSwitcher.contextType = DataContext;

RawAppSwitcher.propTypes = {};

export const AppSwitcher = themed("appSwitcher", RawAppSwitcher);
