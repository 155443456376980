import styled from "styled-components";

export const cxAnnouncementPopoverTheme = {
  announcementPopover: component => styled(component)`
    width: 480px;
    margin: 16px 8px;

    .va-delete-button {
      margin-top: 16px;
      margin-bottom: 0;
    }

    .va-announcement-popover-title {
      margin-bottom: 8px;
    }

    .va-announcement-popover-title,
    .va-announcement-popover-title > div {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
    }

    .va-announcement-popover-title svg {
      margin-right: 4px;
    }
  `
};
