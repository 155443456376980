export const data = {
  en: {
    common: {
      "submit-button": "Submit",
      "close-button": "Close",
      "edit-button": "Edit",
      "cancel-button": "Cancel"
    }
  }
};
