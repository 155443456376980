import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";
import { LocaleContext } from "../LocaleContext";

class RawInventoryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <LocaleContext.Consumer>{value => this.renderContext(value)}</LocaleContext.Consumer>;
  }

  renderContext({ t }) {
    const vehicle = this.props.item;
    const price = vehicle.PendingPrice || vehicle.ListPrice;

    return (
      <a
        key={vehicle.Id}
        href={`/Va/Ranking/Vehicle.aspx?type=InventoryVehicle&vehicleId=${vehicle.Id}`}
        className={`va-quicksearch-item ${this.props.className}`}
      >
        <div className="va-quicksearch-inv-details">
          <h6>
            {vehicle.ModelYear} {vehicle.Make} {vehicle.Model} {vehicle.Series}
          </h6>
          <div>{vehicle.Vin}</div>
          <div>{vehicle.StockNumber}</div>
        </div>
        <div className="va-quicksearch-spacer" />
        <div className="va-quicksearch-inv-price">
          {price ? <div>{`$${price.toLocaleString()}`}</div> : null}
          {vehicle.Age ? <div>{t("inventory-item:age", { age: vehicle.age })}</div> : null}
        </div>
      </a>
    );
  }
}

RawInventoryItem.propTypes = {
  item: PropTypes.shape({
    Age: PropTypes.number,
    Id: PropTypes.string,
    ListPrice: PropTypes.number,
    Make: PropTypes.string,
    Model: PropTypes.string,
    ModelYear: PropTypes.number,
    PendingPrice: PropTypes.number,
    Series: PropTypes.string,
    StockNumber: PropTypes.string,
    Vin: PropTypes.string
  })
};

export const InventoryItem = themed("inventoryItem", RawInventoryItem);
