import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";
import { DataContext } from "../DataContext";
import { Textbox, TextArea, Select, LabeledField, Button } from "@coxkit/core";
import { Dialog } from "../../components/Dialog";
import { AlertDialog } from "../../components/AlertDialog";
import { LocaleContext } from "../LocaleContext";

class RawFeedbackForm extends React.Component {
  constructor(props) {
    super(props);

    //this.contextType = DataContext;
    this.state = {
      nameValue: "",
      phoneValue: "",
      emailValue: "",
      categoryValue: "",
      commentsValue: ""
    };

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    const userInfo = this.context.data.userInfo;
    this.setState({
      emailValue: userInfo.userEmail,
      nameValue: userInfo.displayUserName
    });
  }

  onSubmitForm = (evt, isValid, domEvent) => {
    evt.preventDefault();

    this.setState({ isSending: true });
    this.context.api
      .sendFeedback({
        name: this.state.nameValue,
        phone: this.state.phoneValue,
        email: this.state.emailValue,
        category: this.state.categoryValue,
        comments: this.state.commentsValue
      })
      .then(() => {
        if (this.props.onHide) {
          this.props.onHide();
        }
      })
      .catch(error => {
        console.error(error);
        AlertDialog.show({
          header: "Error",
          message: "Error sending form",
          showOk: true,
          style: { width: "256px" }
        });
      })
      .finally(() => {
        this.setState({ isSending: false });
      });
  };

  onChangeNameText = (cxEvent, isValid, domEvent) => {
    this.setState({ nameValue: cxEvent.target.value });
  };

  onChangePhoneText = (cxEvent, isValid, domEvent) => {
    this.setState({ phoneValue: cxEvent.target.value });
  };

  onChangeEmailText = (cxEvent, isValid, domEvent) => {
    this.setState({ emailValue: cxEvent.target.value });
  };

  onChangeCategoryText = (cxEvent, isValid, domEvent) => {
    this.setState({ categoryValue: cxEvent.target.value });
  };

  onChangeCommentsText = (cxEvent, isValid, domEvent) => {
    this.setState({ commentsValue: cxEvent.target.value });
  };

  onChangeCategoryText = (cxEvent, isValid, domEvent) => {
    this.setState({ categoryValue: cxEvent.target.value });
  };

  noOp = (cxEvent, isValid, domEvent) => {
    //Do nothing.
  };

  render() {
    return <LocaleContext.Consumer>{value => this.renderContext(value)}</LocaleContext.Consumer>;
  }

  renderContext({ t }) {
    let htmlDescription = <div className="va-feedback-form-description">{t("feedback:description")}</div>;

    let htmlForm = (
      <div className="va-feedback-form">
        <LabeledField name={t("feedback:name-label")}>
          <Textbox required onChange={this.onChangeNameText} value={this.state.nameValue} />
        </LabeledField>
        <LabeledField name={t("feedback:phone-label")}>
          <Textbox onChange={this.onChangePhoneText} value={this.state.phoneValue} />
        </LabeledField>
        <LabeledField name={t("feedback:email-label")}>
          <Textbox required onChange={this.onChangeEmailText} value={this.state.emailValue} />
        </LabeledField>
        <LabeledField name={t("feedback:category-label")}>
          <Select onChange={this.onChangeCategoryText} value={this.state.categoryValue}>
            <option value="Suggestion">{t("feedback:suggestion")}</option>
            <option value="Complaint">{t("feedback:complaint")}</option>
            <option value="Problem">{t("feedback:problem")}</option>
            <option value="Other">{t("feedback:other")}</option>
          </Select>
        </LabeledField>
        <LabeledField name={t("feedback:comments-label")}>
          <TextArea required rows="8" onChange={this.onChangeCommentsText} value={this.state.commentsValue} />
        </LabeledField>
      </div>
    );

    const header = <div>{t("feedback:provide-feedback")}</div>;

    const footer = (
      <div className="va-feedback-form-footer">
        <Button primary onClick={this.onSubmitForm}>
          {t("common:submit-button")}
        </Button>
        <Button default onClick={this.props.onHide}>
          {t("common:close-button")}
        </Button>
      </div>
    );

    return (
      <Dialog
        className={`va-feedback-form-container ${this.props.className}`}
        visible={this.props.visible}
        onHide={this.props.onHide}
        header={header}
        footer={footer}
      >
        {htmlDescription}
        {htmlForm}
      </Dialog>
    );
  }
}

RawFeedbackForm.contextType = DataContext;

RawFeedbackForm.propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func
};

export const FeedbackForm = themed("feedback", RawFeedbackForm);
