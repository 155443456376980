import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { DataContext } from "../DataContext";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { RecentItem } from "./RecentItem";
import { AlertDialog } from "../../components/AlertDialog";

class RawRecentItemsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      recentItems: {
        data: { rows: [] }
      }
    };

    this.handlePinItemClick = this.handlePinItemClick.bind(this);
  }

  componentDidMount() {
    this.setState(state => {
      if (!state.recentItems.data.rows.length && !state.isLoading) {
        this.fetchRecentItems()
          .then(items => {
            this.setState({ recentItems: items, isLoading: false });
          })
          .catch(error => {
            console.error(error);
            AlertDialog.show({
              header: "Error",
              message: "Error loading recent items",
              showOk: true,
              style: { width: "256px" }
            });
            this.setState({ isLoading: false });
          });
        return { isLoading: true };
      }
      return {};
    });
  }

  handlePinItemClick(evt, id) {
    this.setState(state => {
      const recentItems = { ...state.recentItems };
      recentItems.data.rows.filter(i => i.id === id).forEach(i => (i.p = !i.p));
      return { recentItems };
    });
  }

  fetchRecentItems() {
    const api = this.context.api;
    return api.getRecentItems();
  }

  render() {
    const isLoading = this.state.isLoading;
    const rows = this.state.recentItems && this.state.recentItems.data && this.state.recentItems.data.rows;
    const items = [...(rows || [])];
    items.sort((a, b) => this.compareItems(a, b));

    const itemElements = items.map(item => (
      <RecentItem key={item.id} item={item} onPinItem={this.handlePinItemClick} />
    ));

    return (
      <div className={`${this.props.className}${isLoading ? " loading" : ""}`}>
        {isLoading ? <LoadingIndicator /> : itemElements}
      </div>
    );
  }

  compareItems(a, b) {
    if (a.p < b.p) {
      return 1;
    } else if (a.p > b.p) {
      return -1;
    } else {
      let mashA = this.getMashId(a);
      let mashB = this.getMashId(b);
      if (mashA < mashB) {
        return -1;
      } else if (mashA > mashB) {
        return 1;
      } else {
        if (a.lm < b.lm) {
          return 1;
        } else if (a.lm > b.lm) {
          return -1;
        } else {
          return 0;
        }
      }
    }
  }

  getMashId(item) {
    const title = [item.y, item.m, item.md, item.sr, item.sd].filter(i => i).join(" ");
    return item.p
      ? (title || "") +
          (item.d || "") +
          (item.y || "") +
          (item.m || "") +
          (item.md || "") +
          (item.sr || "") +
          (item.v || "")
      : "";
  }
}

RawRecentItemsList.contextType = DataContext;

export const RecentItemsList = themed("recentItemsList", RawRecentItemsList);
