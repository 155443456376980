import styled from "styled-components";

export const cxAnnouncementsTheme = {
  announcements: component => styled(component)`
    .va-carousel {
      display: block;
      position: relative;
      text-align: center;
      cursor: pointer;

      > * {
        transition-property: opacity;
        transition-duration: 0.5s;
        position: absolute;
        left: 0;
        top: 0;
        white-space: nowrap;
      }

      a {
        color: white;
        text-decoration: underline;
      }
    }
  `
};
