import styled from "styled-components";

export const cxSearchFieldTheme = {
  searchField: component => styled(component)`
    position: relative;

    > * {
      margin-right: 8px;
    }

    > svg {
      margin-top: 3px;
      margin-bottom: 3px;
      display: block;
    }

    > input {
      display: none;
      background: white;
      top: 0;
      right: 0;
      padding: 0 4px;
      border: 0;
      outline: 0;
    }

    &.expanded svg {
      display: none;
    }

    &.expanded input {
      display: block;
    }
  `
};
