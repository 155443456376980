import styled from "styled-components";

export const cxLoadingIndicatorTheme = {
  loading: component => styled(component)`
    display: flex;

    @keyframes scale-dot {
      0% {
        transform: scale(0.5);
        opacity: 0.25;
      }

      25% {
        transform: scale(0.75);
        opacity: 1;
      }

      75% {
        transform: scale(0.5);
        opacity: 0.5;
      }

      100% {
        transform: scale(0.5);
        opacity: 0.25;
      }
    }

    .va-loading-dot {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #6d727a;
      transform: scale(0.5);
      animation: scale-dot 1.2s ease-out infinite;

      &:nth-of-type(2) {
        animation-delay: 0.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 0.3s;
      }
    }
  `
};
