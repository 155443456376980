import styled from "styled-components";

export const listItem = component => styled(component)`
  display: flex;
  flex-direction: row;
  padding: 8px;
  font-size: 12px;

  color: #4b5053;
  text-decoration: none;

  h6 {
    color: #2b6bdd;
    margin-bottom: 0;
  }

  &:hover {
    background-color: #fafafa;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ebf0fa;
  }

  .va-quicksearch-spacer {
    flex: 1;
  }
`;

export const appraisalItem = component => styled(listItem(component))`
  .va-quicksearch-appr-status {
    align-self: center;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 8px;
    border: 2px solid #ebf0fa;
    background-color: #ebf0fa;

    &.InProcess {
      background-color: #7c91fd;
      border-color: #4e67e5;
    }

    &.Pending {
      background-color: #f9e341;
      border-color: #e5d23c;
    }

    &.Completed {
      background-color: #74fb1d;
      border-color: #6ce51c;
    }

    &.Deleted {
      background-color: #fb503f;
      border-color: #f9503f;
    }
  }
`;

export const cxQuickSearchTheme = {
  appraisalItem: appraisalItem,

  inventoryItem: listItem,

  quickSearchList: component => styled(component)`
    display: flex;
    flex: 1;
    flex-direction: column;

    &.loading {
      align-items: center;
      justify-content: center;
    }

    h6 {
      margin: 0;
      font-size: 13px;
    }
    a {
      text-decoration: none;
    }
    .va-search-section {
      padding: 4px 8px;
      color: #b5bec9;
      background-color: #e9edf3;
    }
  `,

  quickSearchButton: component => styled(component)``
};
