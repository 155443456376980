import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";

const RawBody = ({ className, children }) => {
  return (
    <div className={`va-body-section ${className}`}>
      <div className="va-body">{children}</div>
    </div>
  );
};

RawBody.propTypes = {
  /** Any child components. */
  children: PropTypes.node
};

export const Body = themed("body", RawBody);
