import React from "react";
import ReactDOM from "react-dom";
import { themed } from "@coxkit/core/theme-engine";
import { DataContext } from "../DataContext";
import { EntitySelect } from "./EntitySelect";

class RawUserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showEntityList: false };

    this.handleEntityClick = this.handleEntityClick.bind(this);
    this.handleHideEntityList = this.handleHideEntityList.bind(this);
  }

  static contextType = DataContext;

  handleEntityClick() {
    this.setState({ showEntityList: true });
  }

  handleHideEntityList() {
    this.setState({ showEntityList: false });
  }

  render() {
    const userInfo = this.context.data.userInfo || {};

    if (userInfo && userInfo.userName) {
      let entitySelect;
      if (this.state.showEntityList) {
        entitySelect = <EntitySelect visible={true} onHide={this.handleHideEntityList} />;
      }

      return (
        <div className={`va-user-info ${this.props.className}`}>
          <div>{userInfo.displayUserName}</div>
          {/* eslint-disable-next-line no-script-url */}
          <a href="javascript:void(0);" onClick={this.handleEntityClick}>
            {userInfo.selectedEntityName} - {userInfo.selectedEntityLogicalId}
          </a>
          {ReactDOM.createPortal(entitySelect, document.body)}
        </div>
      );
    } else {
      return null;
    }
  }
}

RawUserInfo.propTypes = {};

export const UserInfo = themed("userInfo", RawUserInfo);
