import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { Dropdown } from "@coxkit/dropdown";
import { DataContext } from "../DataContext";
import { PopupDialog } from "../../components/PopupDialog";
import { RecentItemsList } from "./RecentItemsList";
import { LocaleContext } from "../LocaleContext";

class RawRecentItemsButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchRecentItems() {
    const api = this.context.api;
    return api.getRecentItems();
  }

  render() {
    return <LocaleContext.Consumer>{value => this.renderContext(value)}</LocaleContext.Consumer>;
  }

  renderContext({ t }) {
    return (
      <Dropdown
        // eslint-disable-next-line no-script-url
        text={<a href="javascript:void(0);">Recent</a>}
        placement="pos-bottom align-right"
        autoUpdate={false}
        autoClose={true}
        target={({ open, targetRef }) => (
          // eslint-disable-next-line no-script-url
          <a href="javascript:void(0);" className={`va-menu-button ${this.props.className}`} onClick={e => open()} ref={targetRef}>
            {t("recent-items:recent")}
          </a>
        )}
      >
        <PopupDialog visible={true} modal={false} header={t("recent-items:recent-activity")}>
          <RecentItemsList />
        </PopupDialog>
      </Dropdown>
    );
  }
}

RawRecentItemsButton.contextType = DataContext;

export const RecentItemsButton = themed("recentItemsButton", RawRecentItemsButton);
