import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { DataContext } from "../DataContext";
import { MenuBar } from "../../components/MenuBar";
import { QuickSearchButton } from "./QuickSearchButton";
import { RecentItemsButton } from "./RecentItemsButton";


class RawMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const dataContext = this.context.data || {},
      menu = dataContext.menu || {},
      page = dataContext.pageConfig || {};

    if (!page.showMenu) {
      return null;
    }

    let menus = menu.map(m => {
      const items = m.children.map(i => (
        <MenuBar.Item key={i.id} href={i.url}>
          {i.title}
        </MenuBar.Item>
      ));
      return (
        <MenuBar.Menu className="va-menu-button" key={m.id} name={m.title} href={m.url}>
          {items}
        </MenuBar.Menu>
      );
    });

    return (
      <div className={`va-menu-section ${this.props.className}`}>
        <div className="va-menu-bar">
          {menus}
          <div className="va-spacer" />
        </div>
      </div>
    );
  }
}

RawMenu.contextType = DataContext;

RawMenu.propTypes = {};

export const Menu = themed("menu", RawMenu);
