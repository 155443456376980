import React from "react";
import { themed } from "@coxkit/core/theme-engine";

class RawMenuBar extends React.Component {
  render() {
    return <div className={`va-menu-bar ${this.props.className}`}>{this.props.children}</div>;
  }
}

class RawMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    }
  }

  render() {
    return (
      <div className={`va-menu-bar-menu ${this.props.className}`} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <a href={this.props.href}>{this.props.name}</a>
        <div className="va-popup-menu-container">
          {this.state.showMenu ? <div className="va-popup-menu">{this.props.children}</div> : null}
        </div>
      </div>
    );
  }

  onMouseEnter = () => {
    this.setState({ showMenu: true });
  }

  onMouseLeave = () => {
    this.setState({ showMenu: false });
  }
}

class RawItem extends React.Component {
  render() {
    // eslint-disable-next-line no-script-url
    return <a className={`va-menu-bar-item ${this.props.className}`} href={this.props.href}>{this.props.children}</a>;
  }
}

export const MenuBar = themed("menuBar", RawMenuBar);
MenuBar.Menu = themed("menuBarMenu", RawMenu);
MenuBar.Item = themed("menuBarItem", RawItem);
