import styled from "styled-components";

export const cxMenuTheme = {
  menu: component => styled(component)`
    .va-menu-bar {
      height: 47px;
      display: flex;
      margin: auto;
      padding: 8px 16px;
      align-items: center;
      color: #0d2345;

      a {
        color: #0d2345;
      }

      > * {
        margin-right: 0;
      }

      .va-spacer {
        flex: 1;
      }

      .cx-loadingIndicator {
        margin-left: 16px;
      }

      .va-menu-button {
        color: #63758d;
        text-decoration: none;

        border: solid rgba(0, 0, 0, 0);
        border-width: 0 0 3px;

        &:hover {
          border-color: #e7814d;
        }

        &[data-open="true"] {
          background: #e9edf3;
        }
      }

      > .va-menu-button {
        position: relative;

        &:not(:last-of-type) {
          margin-right: 18px;
        }
      }
    }

    .va-quicksearch {
      display: flex;
      align-items: center;

      button {
        padding: 0 4px;
        border: 0;
      }

      .icon-search {
        position: relative;
        top: 2px;
      }

      .loading .va-dialog-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  `
};
