import styled from "styled-components";

export const cxSettingsButtonTheme = {
  settings: component => styled(component)`
    display: flex;
    position: relative;
    align-items: center;

    .va-settings-button {
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
        transition: 0.3s ease-out;
        transition-property: transform;
        color: #6d727a;
      }

      &:hover svg {
        color: #b2c6e2;
      }

      &.va-open svg {
        transform: rotate(-60deg);
      }
    }
  `
};
