export const data = {
  en: {
    feedback: {
      "provide-feedback": "Provide Feedback",
      description:
        "Thank you for taking the time to complete this form. Your feedback is an important way for us to improve vAuto.com. Please take a moment to make any suggestions or to report any errors you may have discovered with this application. We welcome all of your comments and suggestions.",
      suggestion: "Suggestion",
      complaint: "Complaint",
      problem: "Problem",
      other: "Other",
      "name-label": "Name:",
      "phone-label": "Phone:",
      "email-label": "Email:",
      "category-label": "Category:",
      "comments-label": "Comments:"
    }
  }
};
