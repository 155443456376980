import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";
import { ReactComponent as IconSearch } from "./icon-search.svg";

class RawSearchField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };

    this.input = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.expanded) {
      this.input.current.focus();
    }
  }

  render() {
    return (
      <div className={`va-search-field ${this.props.expanded ? "expanded" : ""} ${this.props.className}`}>
        <IconSearch />
        <input
          type="text"
          ref={this.input}
          value={this.props.value}
          onBlur={this.handleBlur}
          onChange={e => this.props.onChange(e.target.value)}
        />
      </div>
    );
  }
}

RawSearchField.defaultProps = {
  onChange: () => {}
};

RawSearchField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  expanded: PropTypes.bool
};

export const SearchField = themed("searchField", RawSearchField);
