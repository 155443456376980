import styled from "styled-components";

export const cxMenuBarTheme = {
  menuBar: component => styled(component)`
  `,

  menuBarMenu: component => styled(component)`
    a {
      text-decoration: none;
      cursor: pointer;
    }

    .va-popup-menu-container {
      position: relative;

      a {
        color: black;
      }
    }

    .va-popup-menu {
      position: absolute;
      top: 3px;
      display: flex;
      flex-direction: column;
      z-index: 999;

      border: 1px solid rgba(0,0,0,0.15);
      border-radius: 4px;
      background: #fff;
      box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    }
  `,

  menuBarItem: component => styled(component)`
    padding: 8px 16px;
    border-radius: 3px;
    white-space: nowrap;

    &:hover {
      background: #f5f5f5;
    }
  `
};
