import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";

export const RawSimpleDialog = ({ children, visible, ...otherProps }) => {
  return (
    <div style={{ display: visible ? "inherit" : undefined }} {...otherProps}>
      {children}
    </div>
  );
};

RawSimpleDialog.propTypes = {
  visible: PropTypes.bool
};

export const SimpleDialog = themed("simpleDialog", RawSimpleDialog);
