import styled from "styled-components";

// ["va-entity-container", "va-entity-dealer", "va-entity-enterprise", "va-entity-wholesaler"];

export const cxEntityListTheme = {
  entityList: component => styled(component)`
    a {
      text-decoration: none;
      color: #2b6bdd;
      &:hover {
        color: #6090e5;
      }
    }

    em {
      display: block;
    }

    table {
      width: 100%;
    }

    td {
      vertical-align: top;
    }

    /* The exact value of max-width doesn't seem to matter (some weird table sizing voodoo)
     * The important thing though is that this constrains the first column from being too large
     */
    td:nth-child(1) {
      max-width: 373px;
    }

    td:nth-child(2) {
      max-width: 192px;
    }

    .va-entity-row {
      display: flex;
      flex-direction: row;
    }

    .va-entity-row > *:not(:last-child) {
      margin-right: 8px;
    }

    .va-entity-type-col {
      display: flex;
      align-items: center;
    }

    .va-entity-type {
      width: 16px;
      height: 16px;
      border: 0;
      background-image: url(${require("../../images/tree-sprite.png")});
    }

    .va-entity-container img {
      background-position-y: -16px;
    }

    .va-entity-dealer img {
      background-position-y: -48px;
    }

    .va-entity-enterprise img {
      background-position-y: -32px;
    }

    .va-entity-wholesaler img {
    }

    .dummy {
      visibility: hidden;
    }
  `
};
