import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";
import { Textbox } from "@coxkit/core";
import { Button } from "@coxkit/core/components";
import { EntityList } from "./EntityList";
import { Dialog } from "../../components/Dialog";
import { ReactComponent as IconKeyboardArrowLeft } from "./icon-keyboard-arrow-left.svg";
import { LocaleContext } from "../LocaleContext";

class RawEntitySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      showEntityList: false,
      history: [],
      root: null
    };
  }

  handleChange = evt => {
    this.setState({ search: evt.target.value });
  };

  handleCancelClick = () => {
    if (this.props.onHide) {
      this.props.onHide();
    }
  };

  handleBackClick = () => {
    this.setState(state => {
      let history = [...state.history];
      if (history.length) {
        let item = history.pop();
        return {
          history: history,
          search: "",
          root: item
        };
      }
    });
  };

  navigate = newState => {
    this.setState(state => {
      let history = [...(state.history || [])];
      if (state.root) {
        history.push({
          Id: state.root.Id,
          Name: state.root.Name
        });
      } else {
        history.push(null);
      }

      return {
        history: history,
        ...newState
      };
    });
  };

  handleRemoveRoot = () => {
    this.navigate({
      search: "",
      root: null
    });
  };

  handleSelectRoot = root => {
    this.navigate({ search: "", root: root });
  };

  handleInitialLoad = root => {
    this.setState({
      root: {
        Id: root.Id,
        Name: root.Name,
        Initial: true // Initial: true indicates to the list not to re-fetch the data
      }
    });
  };

  handleEntitySelected = entity => this.props.onEntitySelect(entity);

  render() {
    return <LocaleContext.Consumer>{value => this.renderContext(value)}</LocaleContext.Consumer>;
  }

  renderContext({ t }) {
    let buttonState = { disabled: !this.state.history.length };
    return (
      <Dialog
        header={t("entity-select:select-entity")}
        footer={
          <Button primary onClick={this.handleCancelClick}>
            {t("common:cancel-button")}
          </Button>
        }
        {...this.props}
      >
        <div className="va-entity-nav">
          <Button default className="va-entity-history-button" onClick={this.handleBackClick} {...buttonState}>
            <IconKeyboardArrowLeft />
          </Button>
          <span>{t("entity-select:search")}:&nbsp;</span>
          <Textbox className="va-entity-search-box" onChange={this.handleChange} value={this.state.search} />
          <div className="va-spacer" />
          {this.renderNavNode(t)}
        </div>
        <EntityList
          search={this.state.search}
          root={this.state.root}
          onSelectRoot={this.handleSelectRoot}
          onInitialLoad={this.handleInitialLoad}
          onEntitySelect={this.props.onEntitySelect ? this.handleEntitySelected : null}
        />
      </Dialog>
    );
  }

  renderNavNode(t) {
    if (this.state.root && this.state.root.Name) {
      return (
        <div className="va-entity-nav-node">
          {t("entity-select:children-of", { name: this.state.root.Name })}
          {/* eslint-disable-next-line no-script-url */}
          <a href="javascript:void(0);" onClick={this.handleRemoveRoot}>
            ×
          </a>
        </div>
      );
    } else {
      return null;
    }
  }
}

RawEntitySelect.propTypes = {
  onHide: PropTypes.func,
  onEntitySelect: PropTypes.func
};

export const EntitySelect = themed("entitySelect", RawEntitySelect);
