import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { DataContext } from "../DataContext";

class RawAppList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const themeConfig = this.context.data.themeConfig;
    const themes = themeConfig.themes.filter(t => t.name !== themeConfig.currentTheme.name);
    const items = themes.map(t => (
      <li
        key={t.name}
        className={`${t.name}${themeConfig.isProfitTime ? " profit-time" : ""}`}
        onClick={() => this.handleClick(t)}
      />
    ));

    return (
      <div className={`va-theme-list ${this.props.className}`}>
        <ul>{items}</ul>
      </div>
    );
  }

  handleClick = theme => {
    var targetUrl = "/Va?_theme=" + theme.name;

    // Open external url in new tab/popup
    if (theme.isExternalTheme) {
      window.open(targetUrl, "_blank");
    } else {
      window.location.href = targetUrl;
    }
  };
}

RawAppList.contextType = DataContext;

RawAppList.propTypes = {};

export const AppList = themed("appList", RawAppList);
