import styled from "styled-components";

export const cxDialogTheme = {
  dialog: component => styled(component)`
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 600px;
    margin: 30px auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    outline: 0;

    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.3s ease-out;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    background-clip: padding-box;

    color: black;

    &.p-dialog-visible {
      display: block;
    }

    &,
    * {
      box-sizing: border-box;
    }

    .p-dialog-titlebar {
      display: flex;
      padding: 16px 16px 0;
      border: 0;
    }

    .p-dialog-titlebar-icon {
      border: 0;
      padding: 0;
      background: transparent;
      appearance: none;

      &:hover {
        opacity: 0.7;
      }
    }

    .p-dialog-titlebar-close-icon {
      color: #b2b6bf;
      cursor: pointer;
      font-size: 21px;
      font-weight: 700;
      line-height: 1;
      text-shadow: 0 1px 0 #fff;
      color: #000;
      opacity: 0.2;

      &::after {
        content: "×";
      }
    }

    .p-dialog-title {
      margin: 0;
      line-height: 1.4286;
      font-size: 16px;
      font-weight: 500;
      flex: 1;
    }

    .p-dialog-content {
      display: flex;
      padding: 16px;
      position: relative;
      flex-direction: column;
    }

    .p-dialog-footer {
      display: flex;
      justify-content: flex-end;
      padding: 0 16px 16px;
      border: 0;
    }

    &.p-dialog-enter {
      display: block;
      opacity: 0;
      transform: translateX(-50%) translateY(-50%) scale(0.7);
    }

    &.p-dialog-enter-active {
      opacity: 1;
      transform: translateX(-50%) translateY(-50%) scale(1);
      transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
    }

    &.p-dialog-enter-done {
      display: block;
    }

    &.p-dialog-exit {
      opacity: 1;
      display: block;
    }

    &.p-dialog-exit-active {
      opacity: 0;
      transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.p-dialog-exit-done {
      display: none;
    }
  `,

  simpleDialog: component => styled(component)`
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 16px;
    border-radius: 8px;
    outline: 0;

    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    background-clip: padding-box;

    color: black;

    &,
    * {
      box-sizing: border-box;
    }
  `
};
