import styled from "styled-components";

export const cxAdminHelperTheme = {
  adminHelper: component => styled(component)`
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 999;

    input {
      width: 100%;
      &[readonly] {
        opacity: 0.5;
      }
    }

    .va-admin-helper-info-box {
      margin-bottom: 16px;
    }

    .va-admin-helper-edit-row {
      display: flex;

      input {
        flex: 1;
      }

      > * {
        margin-bottom: 0;
        margin-right: 0;
      }

      > *:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      > *:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }
    }
  `
};
