import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";
import { Textbox, LabeledField, Button } from "@coxkit/core";
import { parseQueryString, buildQueryString } from "@vauto/simple-web-tools/lib/query";
import { DataContext } from "../DataContext";
import { LocaleContext } from "../LocaleContext";
import { SimpleDialog } from "../../components/SimpleDialog";

class RawAdminHelper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeEntityValue: "",
      changeUserValue: ""
    };
  }

  onChangeEntity = () => {
    const query = parseQueryString(window.location.search);
    query["entityId"] = this.state.changeEntityValue;
    const queryStr = buildQueryString(query);
    console.log(queryStr);
    window.location.search = queryStr;
  };

  onEditEntity = () => {
    window.location.href = `/Va.Admin/Enrollment/EntityEdit.aspx?lid=${this.state.changeEntityValue}`;
  };

  onChangeUser = () => {
    window.location.href = `/Va.Admin/Enrollment/Impersonate.ashx?Impersonate=${this.state.changeUserValue}`;
  };

  onEditUser = () => {
    window.location.href = `/Va.Admin/Enrollment/UserEdit.aspx?username=${this.state.changeUserValue}`;
  };

  onChangeEntityText = cxEvent => {
    this.setState({ changeEntityValue: cxEvent.target.value });
  };

  onChangeUserText = cxEvent => {
    this.setState({ changeUserValue: cxEvent.target.value });
  };

  render() {
    return <LocaleContext.Consumer>{value => this.renderContext(value)}</LocaleContext.Consumer>;
  }

  renderContext({ t }) {
    const menuConfig = this.context.data.menuConfig;
    const htmlForm = (
      <div>
        <LabeledField className="va-admin-helper-info-box" name={t("admin:version-label")}>
          <Textbox readOnly value={menuConfig.version} />
        </LabeledField>
        <LabeledField className="va-admin-helper-info-box" name={t("admin:host-label")}>
          <Textbox readOnly value={menuConfig.host} />
        </LabeledField>
        <LabeledField className="va-admin-helper-info-box" name={t("admin:entity-label")}>
          <div className="va-admin-helper-edit-row">
            <Textbox onChange={this.onChangeEntityText} value={this.state.changeEntityValue} />
            <Button submit className="va-admin-helper-button" onClick={this.onChangeEntity}>
              {t("admin:go")}
            </Button>
            <Button submit className="va-admin-helper-button" onClick={this.onEditEntity}>
              {t("common:edit-button")}
            </Button>
          </div>
        </LabeledField>
        <LabeledField className="va-admin-helper-info-box" name="User:">
          <div className="va-admin-helper-edit-row">
            <Textbox onChange={this.onChangeUserText} value={this.state.changeUserValue} />
            <Button submit className="va-admin-helper-button" onClick={this.onChangeUser}>
              {t("admin:go")}
            </Button>
            <Button submit className="va-admin-helper-button" onClick={this.onEditUser}>
              {t("common:edit-button")}
            </Button>
          </div>
        </LabeledField>
        <Button primary onClick={this.props.onHide} text={t("admin:close-helper")}>
          {t("common:close-button")}
        </Button>
      </div>
    );

    return (
      <SimpleDialog
        className={`va-admin-helper-container ${this.props.className}`}
        visible={this.props.visible}
        onHide={this.props.onHide}
        modal={false}
        dismissableMask={true}
        style={{ width: "288px" }}
      >
        {htmlForm}
      </SimpleDialog>
    );
  }
}

RawAdminHelper.contextType = DataContext;

RawAdminHelper.propTypes = {
  onHide: PropTypes.func,
  visible: PropTypes.bool
};

export const AdminHelper = themed("adminHelper", RawAdminHelper);
