import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";
import { rowToObject, columnIndexes } from "@vauto/simple-web-tools/lib/list";
import { DataContext } from "../DataContext";
import { DataTable } from "../../components/DataTable";
import { Column } from "primereact/column";
import { LocaleContext } from "../LocaleContext";

const PAGE_SIZE = 5;

const DEFAULT_SEARCH_RESULTS = {
  totalCount: 0,
  sort: "Distance ASC",
  columns: ["Id"],
  description: "Entities",
  rows: []
};

function reifyListResult(result) {
  let list = { ...result };
  let indexes = columnIndexes(result);
  list.rows = [];

  for (let row of result.rows) {
    list.rows.push(rowToObject(indexes, row));
  }

  return list;
}

class RawEntityList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: DEFAULT_SEARCH_RESULTS,
      first: 0,
      loading: false
    };
    this.timer = undefined;

    this.handlePage = this.handlePage.bind(this);
  }

  componentDidMount() {
    this.search(this.state.first, true).then(list => {
      if (this.props.onInitialLoad) {
        this.props.onInitialLoad(list.Root);
      }
    });
  }

  // TODO: The interaction betwen EntityList and EntitySelect is kind of wonky.
  // We handle selection of the root ID, which we use to notify the EntitySelect,
  // who then in turn changes the root prop on this component. This is because
  // EntitySelect owns the entity navigation bar, but we control fetching data.
  // Maybe a better option is to just move entity navigation into EntityList?
  componentDidUpdate(prevProps) {
    const rootId = this.props.root && this.props.root.Id;
    const prevRootId = prevProps.root && prevProps.root.Id;

    if (this.props.search !== prevProps.search) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.search({ first: 0 });
      }, 500);
    } else if (rootId !== prevRootId && !(this.props.root || {}).Initial) {
      this.search({ first: 0 });
    }
  }

  search(pagination, initial) {
    const api = this.context.api,
      root = this.props.root || {};

    this.setState({
      loading: true,
      first: pagination.first
    });

    return api
      .getEntityList({
        root: root.Id,
        quickSearch: this.props.search,
        start: pagination.first,
        limit: PAGE_SIZE,
        initial: initial
      })
      .then(list => {
        this.setState({
          loading: false,
          searchResults: reifyListResult(list)
        });
        return list;
      });
  }

  render() {
    return <LocaleContext.Consumer>{value => this.renderContext(value)}</LocaleContext.Consumer>;
  }

  renderContext({ t }) {
    const data = this.fillPage(this.state.searchResults.rows);

    return (
      <DataTable
        className={`${this.props.className} va-entity-list`}
        value={data}
        lazy={true}
        paginator={true}
        rows={PAGE_SIZE}
        first={this.state.first}
        totalRecords={Math.max(1, this.state.searchResults.totalCount)}
        onPage={this.handlePage}
        loading={this.state.loading}
      >
        <Column
          field="LogicalId"
          header={t("entity-list:entity")}
          style={{ width: "66%" }}
          body={(row, column) => this.renderEntity(row, column, t)}
        />
        <Column
          field="ParentLogicalId"
          header={t("entity-list:parent")}
          style={{ width: "34%" }}
          body={(row, column) => this.renderLineage(row, column, t)}
        />
      </DataTable>
    );
  }

  fillPage(rows) {
    const result = [...rows];
    const len = Math.ceil(Math.max(rows.length, PAGE_SIZE) / PAGE_SIZE) * PAGE_SIZE;
    const diff = len - rows.length;

    for (let i = 0; i < diff; i++) {
      result.push({ IsDummy: true });
    }

    return result;
  }

  renderEntity = (row, column, t) => {
    const classNames = ["va-entity-container", "va-entity-dealer", "va-entity-wholesaler", "va-entity-enterprise"];
    const alts = [
      t("entity-list:container"),
      t("entity-list:dealer"),
      t("entity-list:wholesaler"),
      t("entity-list:enterprise")
    ];
    const entity = row || {};
    const selectUrl = `/Va?entity=${entity.Id}`;

    let entityLink = <a href={selectUrl}>{entity.Name ? entity.Name : <em>{t("entity-list:no-name")}</em>}</a>;

    // If a callback was passed, assume the caller needs the selected entity
    if (this.props.onEntitySelect) {
      // eslint-disable-next-line no-script-url
      entityLink = <a href="javascript:void(0);" onClick={() => this.props.onEntitySelect(entity)}>
                      {entity.Name ? entity.Name : <em>{t("entity-list:no-name")}</em>}
                   </a>;
    }

    return (
      <div key={entity.Id} className={`va-entity-row ${classNames[entity.TypeId]}${row.IsDummy ? " dummy" : ""}`}>
        <div className="va-entity-type-col">
          <img className="va-entity-type" src={require("../../images/pixel.gif")} alt={alts[entity.TypeId]} />
        </div>
        <div className="va-entity-info-col">
          {entityLink}
          {entity.City && entity.Region ? (
            <div>
              {entity.City}, {entity.Region}
            </div>
          ) : (
            <em>{t("entity-list:no-location")}</em>
          )}
          {entity.ChildCount ? (
            // eslint-disable-next-line no-script-url
            <a href="javascript:void(0);" onClick={() => this.handleSelectChildren(entity)}>
              {t("entity-list:child-count", { count: entity.ChildCount })}
            </a>
          ) : (
            <em>No children</em>
          )}
        </div>
      </div>
    );
  };

  renderLineage = (row, column) => {
    const entity = row || {};

    let label;
    if (entity.ParentLogicalId && !entity.IsDummy) {
      label = (
        <>
          <a href="javascript:void(0);" onClick={() => this.handleSelectParent(entity)}>
            {entity.ParentLogicalId}
          </a>
          &nbsp;-&gt;&nbsp;
          {entity.LogicalId}
        </>
      );
    } else {
      label = undefined;
    }
    return <span className={`va-entity-lineage-col${row.IsDummy ? " dummy" : ""}`}>{label}</span>;
  };

  handlePage = pagination => {
    this.search(pagination);
  };

  handleSelectChildren = row => {
    if (this.props.onSelectRoot) {
      this.props.onSelectRoot({ Id: row.Id, Name: row.Name });
    }
  };

  handleSelectParent = row => {
    if (this.props.onSelectRoot) {
      this.props.onSelectRoot({ Id: row.ParentId, Name: row.ParentName });
    }
  };

  handleRowClick() {}
}

RawEntityList.contextType = DataContext;

RawEntityList.propTypes = {
  onSelectRoot: PropTypes.func,
  onInitialLoad: PropTypes.func,
  onEntitySelect: PropTypes.func,
  root: PropTypes.shape({
    Id: PropTypes.string
  })
};

export const EntityList = themed("entityList", RawEntityList);
