import styled from "styled-components";

export const cxHeaderTheme = {
  header: component => styled(component)`
    width: 100%;
    background: white;
    border-top: 1px solid #efefef;
    color: #0d2345;

    &.conquest,
    &.conquest .va-nav-bar {
      background: #2b3e51;
    }

    /*
      Bah, this is a horrible hack. The button component uses an anchor tag instead of
      the proper sementic button tag, which means we can't change the style of just links
      and not button. However, it just so happens that all of our buttons are nested fairly
      deep, so we can isolate them by limiting our nesting level
    */
    > a,
    > * > a,
    > * > * > a,
    > * > * > * > a {
      text-decoration: none;
      color: #0d2345;

      &:hover {
        color: #b2c6e2;
      }
    }

    .va-nav-bar {
      min-width: 985px;
      max-width: 1200px;
      display: flex;
      margin: auto;
      padding: 0 16px;
      align-items: center;
    }

    .va-app-switcher-ct {
      display: flex;
      align-self: stretch;
      flex: 1;
    }

    .va-settings {
      font-size: 12px;
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  `,

  appSwitcher: component => styled(component)`
    display: flex;
    align-items: center;

    .va-logo {
      width: 160px;
      height: 35px;
      background-repeat: no-repeat;
      background-size: 160px 35px;
      margin-right: 16px;

      &.provision {
        background-image: url(${require("../../images/logos/logo_Pv_white.svg")});
        &.profit-time {
          width: 189px;
          height: 21px;
          background-size: auto auto;
          background-image: url(${require("../../images/logos/logo_ProfitTimeVA_new.png")});
        }
      }
      &.conquest {
        background-image: url(${require("../../images/logos/logo_Cq_color.svg")});
      }
      &.priceadvisor {
        width: 108px;
        background-size: 108px 35px;
        background-image: url(${require("../../images/logos/logo_PAR_white.svg")});
      }
    }

    .va-selector-dropdown {
      display: flex;
      align-items: center;
      align-self: stretch;
      padding-right: 8px;

      &:hover {
        background: rgba(0, 0, 0, 0.25);
      }

      &:hover:before {
        background: none;
      }

      &:before {
        content: " ";
        width: 1px;
        height: 48px;
        margin-right: 8px;
        background: rgba(255, 255, 255, 0.1);
      }
    }
  `,

  appList: component => styled(component)`
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 220px;
      margin: 0;
      padding: 16px;
    }
    li {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 160px 35px;
      width: 160px;
      height: 35px;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .provision {
      background-image: url(${require("../../images/logos/logo_Pv_mono.svg")});
      &.profit-time {
        background-image: url(${require("../../images/logos/logo_Pv_PT_mono.svg")});
        &:hover {
          background-image: url(${require("../../images/logos/logo_Pv_PT_color.svg")});
        }
      }
      &:hover {
        background-image: url(${require("../../images/logos/logo_Pv_color.svg")});
      }
    }
    .conquest {
      background-image: url(${require("../../images/logos/logo_Cq_mono.svg")});
      &:hover {
        background-image: url(${require("../../images/logos/logo_Cq_color.svg")});
      }
    }
    .stockwave {
      background-image: url(${require("../../images/logos/logo_Sw_mono.svg")});
      background-size: 160px 25px;
      &:hover {
        background-image: url(${require("../../images/logos/logo_Sw_color.svg")});
      }
    }
    .irecon {
      background-image: url(${require("../../images/logos/logo_iRecon_mono.svg")});
      background-size: 131px 35px;
      &:hover {
        background-image: url(${require("../../images/logos/logo_iRecon_color.svg")});
      }
    }
    .priceadvisor {
      background-image: url(${require("../../images/logos/logo_PAR_mono.svg")});
      background-size: 108 35px;
      &:hover {
        background-image: url(${require("../../images/logos/logo_PAR_color.svg")});
      }
    }
  `
};
