import React from "react";
import PropTypes from "prop-types";
import { LocaleContext } from "./LocaleContext";
import i18next from "i18next";
import merge from "merge";

export class Localized extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      t: () => null
    };
  }

  componentDidMount() {
    i18next.init(
      {
        lng: "en",
        debug: true,
        resources: this.resources()
      },
      this.handleLocalization
    );
  }

  resources() {
    let resources = [
      require("../i18n/common"),
      require("../i18n/feedback"),
      require("../i18n/links"),
      require("../i18n/pm"),
      require("../i18n/admin"),
      require("../i18n/entity-list"),
      require("../i18n/entity-select"),
      require("../i18n/inventory-item"),
      require("../i18n/quicksearch"),
      require("../i18n/recent-items")
    ];

    let result = {};
    for (let res of resources) {
      result = merge.recursive(result, res.data);
    }

    return result;
  }

  handleLocalization = (err, t) => {
    this.setState({ t: t });
  };

  render() {
    return <LocaleContext.Provider value={this.state}>{this.props.children}</LocaleContext.Provider>;
  }
}

Localized.propTypes = {
  /** Any child components. */
  children: PropTypes.node
};
