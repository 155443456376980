export const data = {
  en: {
    admin: {
      "version-label": "Version:",
      "host-label": "Host:",
      "entity-label": "Entity:",
      go: "Go!",
      "close-helper": "Close Admin Helper"
    }
  }
};
