import styled from "styled-components";

export const cxFooterTheme = {
  footer: component => styled(component)`
    font-size: 12px;
    color: #747880;

    a {
      color: #747880;
      text-decoration: none;
    }

    a:hover {
      color: #2c3644;
    }

    .va-footer {
      min-width: 985px;
      max-width: 1200px;
      padding: 16px;
      display: flex;
      margin: auto;
      margin-top: 8px;
      align-items: start;
    }

    .va-spacer {
      flex: 1;
    }
  `,

  links: component => styled(component)`
    display: flex;
    align-items: center;

    ul,
    ol {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      margin-right: 8px;
    }

    li:not(:last-child)::after {
      content: "|";
      padding-left: 8px;
    }

    .va-vauto-logo {
      width: 61px;
      height: 20px;
      margin-right: 16px;
      background-image: url(${require("../../images/footer-logo.png")});
    }

    .va-spacer {
      flex: 1;
    }
  `,

  performanceManager: component => styled(component)`
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    border-left: 2px solid rgba(0, 0, 0, 0.25);

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 0;
    }

    h6 {
      margin: 0;
      font-size: 16px;
    }

    .va-spacer {
      flex: 1;
    }
  `
};
