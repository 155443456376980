import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { DataContext } from "../DataContext";
import { PerformanceManager } from "./PerformanceManager";
import { Links } from "./Links";

class RawFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFeedbackForm: false
    };
  }

  render() {
    return (
      <div className={`va-footer-section ${this.props.className}`}>
        <div className="va-footer">
          <Links />
          <div className="va-spacer" />
          <PerformanceManager />
        </div>
      </div>
    );
  }
}

RawFooter.contextType = DataContext;

RawFooter.propTypes = {};

export const Footer = themed("footer", RawFooter);
