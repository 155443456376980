import styled from "styled-components";

export const cxPopupTheme = {
  popup: component => styled(component)`
    display: none;
    position: relative;
    width: 600px;

    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    background: white;
    color: black;

    flex-direction: column;
    width: 336px;
    height: 458px;

    &.p-dialog-visible {
      display: flex;
    }

    &,
    * {
      box-sizing: border-box;
    }

    .p-dialog-titlebar-icon {
      border: 0;
      padding: 0;
      background: transparent;
      appearance: none;

      &:hover {
        opacity: 0.7;
      }
    }

    .p-dialog-title {
      color: white;
    }

    .p-dialog-titlebar {
      display: flex;
      padding: 8px;
      border-radius: 3px 3px 0 0;
      color: white;
      background-color: #475670;
    }

    .p-dialog-content {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      flex: 1;
    }
  `
};
