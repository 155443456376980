import React from "react";

import { Page } from "./lib/page/Page";
import { MenuApi as MockMenuApi } from "./lib/api/mockMenuApi";

export const App = () => (
  <Page api={new MockMenuApi()}>
    <img
      alt="page body"
      style={{ display: "block", margin: "auto" }}
      width="1174"
      src={require("./lib/images/dummy-content.png")}
    />
  </Page>
);
