import React from "react";
import ReactDOM from "react-dom";
import { themed } from "@coxkit/core/theme-engine";
import { parseCookie } from "@vauto/simple-web-tools/lib/cookie";
import { parseQueryString, buildQueryString } from "@vauto/simple-web-tools/lib/query";
import { FeedbackForm } from "./FeedbackForm";
import { LocaleContext } from "../LocaleContext";
import { DataContext } from "../DataContext";

class RawLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showFeedbackForm: false
    };

    this.openFeedbackForm = this.openFeedbackForm.bind(this);
    this.closeFeedbackForm = this.closeFeedbackForm.bind(this);
  }

  openFeedbackForm() {
    this.setState({ showFeedbackForm: true });
  }

  closeFeedbackForm() {
    this.setState({ showFeedbackForm: false });
  }

  handleToggleLanguage = () => {
    const query = parseQueryString(window.location.search);
    const cookie = parseCookie(document.cookie);
    const [lang] = (cookie._locale || "").split("-");
    const newLang = !lang || lang === "en" ? "fr" : "en";
    query["lang"] = newLang;
    window.location.search = buildQueryString(query);
  };

  render() {
    return <LocaleContext.Consumer>{context => this.renderContext(context)}</LocaleContext.Consumer>;
  }

  renderContext({ t, data }) {
    // Only render if we have a username.
    // This allows for async loading of the user info so we get default values set for the form
    const dataContext = this.context.data || {},
      pageConfig = dataContext.pageConfig || {},
      userInfo = dataContext.userInfo || {};

    let feedbackForm = null;
    if (userInfo.userName && this.state.showFeedbackForm) {
      feedbackForm = ReactDOM.createPortal(
        <FeedbackForm visible={true} onHide={this.closeFeedbackForm} />,
        document.body
      );
    }

    return (
      <div className={this.props.className}>
        <div className="va-vauto-logo" />
        <div>
          {pageConfig.showLanguageToggle ? (
            // eslint-disable-next-line no-script-url
            <a href="javascript:void(0);" onClick={this.handleToggleLanguage}>
              {t("links:language")}
            </a>
          ) : null}
          <ul>
            <li>
              <a href="http://www.vauto.com/MTACS">{t("links:terms-of-use")}</a>
            </li>
            <li>
              <a href="http://www.vauto.com/privacy-policy/">{t("links:privacy-policy")}</a>
            </li>
            <li>
              {/* eslint-disable-next-line no-script-url */}
              <a href="javascript:void(0);" onClick={this.openFeedbackForm}>
                {t("links:feedback")}
              </a>
            </li>
            <li>
              <a href="http://www.vauto.com/about/contact/">{t("links:contact-us")}</a>
            </li>
          </ul>
          <a href="http://www.vauto.com/">
            &copy;2006-{new Date().getFullYear()} {t("links:vauto")}
          </a>
        </div>
        {feedbackForm}
      </div>
    );
  }
}

RawLinks.contextType = DataContext;

RawLinks.propTypes = {};

export const Links = themed("links", RawLinks);
