import styled from "styled-components";

export const cxTableTheme = {
  table: component => styled(component)`
    position: relative;

    table {
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 14px;
    }
    tbody {
      td {
        padding: 8px;
        border: 1px solid #cacfd9;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      tr:hover {
        background: #f5f6f7;
      }
    }
    th {
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #6d727a;
      padding: 8px;
    }
    svg {
      display: none;
    }

    .p-datatable-loading-content {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.5);
    }

    .pi-spinner {
      width: 60px;
      height: 20px;
      background: #6d727a;
      mask: no-repeat url("${require("./loading-indicator.svg")}");
    }

    .p-paginator {
      display: flex;
      padding: 0;
      margin-top: 16px;
      justify-content: center;
      align-items: center;

      .p-link {
        margin: 0 4px;
        border: none;
        background: none;
        color: #2b6bdd;
        text-decoration: none;
        border-radius: 4px;
        padding: 2px 4px;
        cursor: pointer;

        &.p-highlight {
          color: white;
          background: #2b6bdd;
        }
      }
    }

    .p-paginator-prev,
    .p-paginator-next {
      display: block;
      width: 24px;
      height: 24px;

      &.p-disabled {
        cursor: not-allowed;
        &:after {
          background: #cacfd9;
        }
      }

      &:not(.p-disabled):hover:after {
        background: #6090e5;
      }

      &:after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: black;
      }
    }

    .p-paginator-prev {
      mask: url(${require("./icon-keyboard-arrow-left.svg")});
    }

    .p-paginator-next {
      mask: url(${require("./icon-keyboard-arrow-right.svg")});
    }

    .p-paginator-last,
    .p-paginator-first {
      display: none;
    }`
};
