import styled from "styled-components";

export const cxBodyTheme = {
  body: component => styled(component)`
    padding: 16px;
    background-image: url(${require("../../images/dropshadow.png")});

    .va-body {
      min-width: 985px;
      max-width: 1200px;
      min-height: 600px;
      margin: auto;
    }
  `
};
