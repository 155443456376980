import React from "react";
import { Hr } from "@coxkit/core/components";
import { themed } from "@coxkit/core/theme-engine";
import { DropdownMenu, MenuItem } from "@coxkit/dropdown";
import { DataContext } from "../DataContext";
import { ReactComponent as IconSettings } from "./icon-settings.svg";
import { AdminHelper } from "./AdminHelper";

class RawSettingsButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdminHelper: false,
      showSettings: false
    };

    this.openAdminHelper = this.openAdminHelper.bind(this);
    this.closeAdminHelper = this.closeAdminHelper.bind(this);
  }

  handleItemClick(item) {
    switch (item.action) {
      case "url":
        document.location = item.href;
        break;
      case "admin":
        this.openAdminHelper();
        break;
      default:
        break;
    }
  }

  openAdminHelper() {
    this.setState({ showAdminHelper: true });
  }

  closeAdminHelper() {
    this.setState({ showAdminHelper: false });
  }

  handleSettingsOpen = () => {
    this.setState({ showSettings: true });
  }

  handleSettingsClose = () => {
    this.setState({ showSettings: false });
  }

  renderItem(item, idx) {
    if (item.type === "item") {
      return (
        <MenuItem key={`${item.type}:${item.action}:${item.href}`} onClick={() => this.handleItemClick(item)}>
          {item.text}
        </MenuItem>
      );
    } else if (item.type === "separator") {
      return <Hr key={`${item.type}:${idx}`} />;
    }
  }

  insertSeparators(menu) {
    let result = [];
    for (let i = 0; i < menu.length; i++) {
      let prev = i > 0 ? menu[i - 1] : null;
      let curr = menu[i];

      if (prev && prev.group !== curr.group) {
        result.push({ type: "separator" });
      }

      result.push(curr);
    }

    return result;
  }

  render() {
    if (!this.context.data.userMenu) {
      return null;
    }

    const menu = this.insertSeparators(this.context.data.userMenu || []);
    const menuListItems = menu.map((item, idx) => this.renderItem(item, idx));

    let adminHelper;
    if (this.state.showAdminHelper) {
      adminHelper = <AdminHelper onHide={this.closeAdminHelper} />;
    }

    return (
      <div className={`va-settings-button ${this.props.className}`}>
        <DropdownMenu
          button={false}
          onOpen={this.handleSettingsOpen}
          onClose={this.handleSettingsClose}
          placement="pos-below align-right"
          text={
            <div className={this.state.showSettings ? "va-settings-button va-open" : "va-settings-button va-close"}>
              <IconSettings />
            </div>
          }
        >
          {menuListItems}
        </DropdownMenu>
        {adminHelper}
      </div>
    );
  }
}

RawSettingsButton.contextType = DataContext;

RawSettingsButton.propTypes = {};

export const SettingsButton = themed("settings", RawSettingsButton);
