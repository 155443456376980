import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { DataContext } from "../DataContext";
import { LocaleContext } from "../LocaleContext";

class RawPerformanceManager extends React.Component {
  render() {
    return (
      <DataContext.Consumer>
        {data => <LocaleContext.Consumer>{t => this.renderContext({ t: t.t, data: data.data })}</LocaleContext.Consumer>}
      </DataContext.Consumer>
    );
  }

  renderContext({ t, data }) {
    const info = data.performanceManagerInfo;

    if (!info) {
      return null;
    }

    return (
      <div {...this.props}>
        {t("pm:your-pm")}
        <h6>{info.name}</h6>
        <ul>
          {info.workPhone ? (
            <>
              <li>
                {t("pm:office")}: {info.workPhone}
              </li>
            </>
          ) : null}
          {info.mobilePhone ? (
            <>
              <li>
                {t("pm:mobile")}: {info.mobilePhone}
              </li>
            </>
          ) : null}
          {info.email ? (
            <>
              <li>
                {t("pm:email")}: {info.email}
              </li>
            </>
          ) : null}
          {info.supportNumber ? (
            <>
              <li>
                {t("pm:tech-support")}: {info.workPhone}
              </li>
            </>
          ) : null}
        </ul>
      </div>
    );
  }
}

export const PerformanceManager = themed("performanceManager", RawPerformanceManager);
