import styled from "styled-components";

export const cxUserInfoTheme = {
  userInfo: component => styled(component)`
    margin: 16px 0;
    text-align: right;
    margin-right: 8px;

    > div:first-child {
      font-weight: bold;
    }
  `
};
