export const data = {
  en: {
    "entity-list": {
      entity: "Entity",
      parent: "Parent",
      container: "container",
      dealer: "dealer",
      wholesaler: "wholesaler",
      enterprise: "enterprise",
      "no-name": "No Name",
      "no-location": "No Location",
      "child-count": "{{count}} child",
      "child-count_plural": "{{count}} children"
    }
  }
};
