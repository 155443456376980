import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { Dropdown } from "@coxkit/dropdown";
import { PopupDialog } from "../../components/PopupDialog";
import { SearchField } from "./SearchField";
import { QuickSearchList } from "./QuickSearchList";
import { DataContext } from "../DataContext";

class RawQuickSearchButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      showSearch: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange(value) {
    this.setState({ search: value });
  }

  handleOpen() {
    this.setState({ showSearch: true });
  }

  handleClose() {
    this.setState({ showSearch: false });
  }

  render() {
    const page = this.context.data.pageConfig;

    if (!page.showQuickSearch) {
      return null;
    }

    const searchPopup = (
      <PopupDialog visible={true} modal={false} header="Search" className="va-quicksearch-popup">
        <QuickSearchList search={this.state.search} />
      </PopupDialog>
    );

    return (
      <Dropdown
        placement="pos-below align-right"
        button={false}
        autoUpdate={false}
        autoToggle={false}
        autoClose={true}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        target={({ open, targetRef }) => (
          // eslint-disable-next-line no-script-url
          <a href="javascript:void(0);" className={`va-menu-button ${this.props.className}`} onClick={e => open()} ref={targetRef}>
            <SearchField value={this.state.search} onChange={this.handleChange} expanded={this.state.showSearch} />
          </a>
        )}
      >
        {searchPopup}
      </Dropdown>
    );
  }
}

RawQuickSearchButton.contextType = DataContext;

export const QuickSearchButton = themed("quickSearchButton", RawQuickSearchButton);
