import styled from "styled-components";

export const cxEntitySelectTheme = {
  entitySelect: component => styled(component)`
    .va-entity-nav {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .va-entity-history-button {
      display: flex;
      margin: 0;
      margin-right: 12px;
      padding: 0;
    }

    .va-spacer {
      flex: 1;
    }

    .va-entity-nav-node {
      padding: 8px;
      border-radius: 4px;
      background: #2b6bdd;
      color: white;

      a {
        margin-left: 4px;
        color: white;
        text-decoration: none;
      }
    }
  `
};
