import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";
import { rowToObject, columnIndexes } from "@vauto/simple-web-tools/lib/list";
import { DataContext } from "../DataContext";
import { InventoryItem } from "./InventoryItem";
import { AppraisalItem } from "./AppraisalItem";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { AlertDialog } from "../../components/AlertDialog";
import { LocaleContext } from "../LocaleContext";

const DEFAULT_SEARCH_RESULTS = {
  inventory: { columns: [], rows: [] },
  appraisals: { columns: [], rows: [] }
};

class RawQuickSearchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: DEFAULT_SEARCH_RESULTS,
      showSearch: false,
      isLoading: false
    };
  }

  componentDidMount() {
    if (this.props.search) {
      this.search();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.search();
      }, 500);
    }
  }

  search() {
    const api = this.context.api;

    if (this.props.search) {
      this.setState({ isLoading: true });
      api
        .getQuickSearch(this.props.search)
        .then(list => {
          this.setState({ searchResults: list, isLoading: false });
        })
        .catch(error => {
          console.error(error);
          AlertDialog.show({
            header: "Error",
            message: "Error loading search data",
            showOk: true,
            style: { width: "256px" }
          });
        });
    } else {
      this.setState({
        searchResults: DEFAULT_SEARCH_RESULTS,
        isLoading: false
      });
    }
  }

  render() {
    return <LocaleContext.Consumer>{value => this.renderContext(value)}</LocaleContext.Consumer>;
  }

  renderContext({ t }) {
    if (this.state.isLoading) {
      return (
        <div className={`${this.props.className} loading`}>
          <LoadingIndicator className="va-loading-indicator" />
        </div>
      );
    } else {
      const results = this.state.searchResults;
      const inventoryColumns = columnIndexes(results.inventory);
      const appraisalColumns = columnIndexes(results.appraisals);

      const inventory = results.inventory.rows.map(i => {
        const obj = rowToObject(inventoryColumns, i);
        return <InventoryItem key={obj.Id} item={obj} />;
      });

      const appraisals = results.appraisals.rows.map(a => {
        const obj = rowToObject(appraisalColumns, a);
        return <AppraisalItem key={obj.Id} item={obj} />;
      });

      return (
        <div className={this.props.className}>
          <div className="va-search-section">
            {t("quicksearch:appraisal-count", {
              count: results.appraisals.totalCount || 0
            })}
          </div>
          {appraisals}
          <div className="va-search-section">
            {t("quicksearch:inventory-count", {
              count: results.inventory.totalCount || 0
            })}
          </div>
          {inventory}
        </div>
      );
    }
  }
}

RawQuickSearchList.propTypes = {
  search: PropTypes.string
};

RawQuickSearchList.contextType = DataContext;

export const QuickSearchList = themed("quickSearchList", RawQuickSearchList);
