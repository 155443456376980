export const data = {
  en: {
    quicksearch: {
      "appraisal-count": "{{count}} Appraisal Found",
      "appraisal-count_plural": "{{count}} Appraisals Found",
      "inventory-count": "{{count}} Vehicle Found",
      "inventory-count_plural": "{{count}} Vehicles Found"
    }
  }
};
