import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";

class RawAppraisalItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const appraisal = this.props.item;
    const ymm = `${appraisal.ModelYear} ${appraisal.Make} ${appraisal.Model}`;
    return (
      <a
        key={appraisal.Id}
        href={`/Va/Appraisal/Default.aspx?id=${appraisal.Id}`}
        className={`va-quicksearch-item ${this.props.className}`}
      >
        <div className={`va-quicksearch-appr-status ${appraisal.AppraisalStatus}`} />
        <div className="va-quicksearch-appr-details">
          <h6>{ymm}</h6>
          {appraisal.Appraiser ? <div>{appraisal.Appraiser}</div> : null}
          {appraisal.CustomerName ? <div>{appraisal.CustomerName}</div> : null}
        </div>
        <div className="va-quicksearch-spacer" />
        <div className="va-quicksearch-appr-modified">
          {new Date(Date.parse(appraisal.AppraisalLastModified)).toLocaleDateString()}
        </div>
      </a>
    );
  }
}

RawAppraisalItem.propTypes = {
  item: PropTypes.shape({
    AppraisalLastModified: PropTypes.string,
    AppraisalStatus: PropTypes.string,
    Appraiser: PropTypes.string,
    CustomerName: PropTypes.string,
    Id: PropTypes.string,
    Make: PropTypes.string,
    Model: PropTypes.string,
    ModelYear: PropTypes.number
  })
};

export const AppraisalItem = themed("appraisalItem", RawAppraisalItem);
