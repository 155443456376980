import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@coxkit/core/theme-engine";

import { Localized } from "./Localized";
import { DataLoader } from "./DataLoader";
import { BridgeBar } from "./BridgeBar";
import { Header } from "./Header/Header";
import { Menu } from "./Menu/Menu";
import { Body } from "./Body/Body";
import { Footer } from "./Footer/Footer";
import { MainStyle } from "./MainStyle";
import { DialogOverlayStyle } from "../components/Dialog";
import { AlertDialogContainer } from "../components/AlertDialog";

import { cxTableTheme } from "@vauto/coxkit-components/lib/themes/cx-theme/table";
import { Theme as cxTemplateTheme } from "../theme";

const cxTheme = Object.assign({},
  cxTemplateTheme,
  cxTableTheme
);


export const Page = ({children, api, ...others}) => (
  <Localized>
    <DataLoader api={api}>
      <ThemeProvider theme={cxTheme}>
        <BridgeBar />
        <Header />
        <Body {...others}>
          {children}
        </Body>
        <Footer />
        <MainStyle />
        <DialogOverlayStyle />
        <AlertDialogContainer />
      </ThemeProvider>
    </DataLoader>
  </Localized>
);

Page.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object
};
