export const data = {
  en: {
    links: {
      "terms-of-use": "Terms of Use",
      "privacy-policy": "Privacy Policy",
      feedback: "Feedback",
      "contact-us": "Contact Us",
      vauto: "vAuto",
      language: "Français"
    }
  }
};
