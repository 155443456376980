import React from "react";
import PropTypes from "prop-types";
import { themed } from "@coxkit/core/theme-engine";
import { DataContext } from "../DataContext";
import { ReactComponent as IconFlag } from "./icon-flag.svg";
import { AlertDialog } from "../../components/AlertDialog";

class RawRecentItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {}
    };

    this.handlePinItemClick = this.handlePinItemClick.bind(this);
  }

  handlePinItemClick(evt, item) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.props.onPinItem) {
      this.props.onPinItem(evt, item.id);
    }

    const api = this.context.api;
    api.pinRecentItem(item.id, item.p ? "f" : "t").catch(error => {
      console.error(error);
      AlertDialog.show({
        header: "Error",
        message: "Error pinning item",
        showOk: true,
        style: { width: "256px" }
      });
    });
  }

  render() {
    const item = this.props.item;
    let iconClass = "";

    let url;
    switch (item.t) {
      case "i":
        iconClass = "sprite-inventory";
        url = `/Va/Ranking/Vehicle.aspx?type=InventoryVehicle&vehicleId=${item.cid}`;
        break;
      case "a":
        iconClass = "sprite-appraisal";
        url = `/Va/Appraisal/Default.aspx?id=${item.cid}`;
        break;
      case "v":
        iconClass = "sprite-vinscan";
        // url = ?? no idea
        break;
      default:
        break;
    }

    return (
      <a href={url} className={this.props.className}>
        <div className={`sprite ${iconClass}`} />
        <div className="va-recent-item-details">
          <h6>
            {item.y} {item.m} {item.md} {item.sr}
          </h6>
          <em>{item.en}</em>
          <div>{item.v}</div>
          <div>{item.sn}</div>
        </div>
        <div className="va-recent-item-pricing">
          {item.val ? <div>${item.val.toLocaleString()}</div> : null}
          {item.age ? <div>{`${item.age} days`}</div> : null}
          <div
            onClick={evt => this.handlePinItemClick(evt, item)}
            className={`va-flag-icon ${item.p ? "pinned" : null}`}
          >
            <IconFlag />
          </div>
        </div>
      </a>
    );
  }
}

RawRecentItem.propTypes = {
  onPinItem: PropTypes.func,
  item: PropTypes.shape({
    age: PropTypes.number,
    en: PropTypes.string,
    m: PropTypes.string,
    md: PropTypes.string,
    p: PropTypes.bool,
    sn: PropTypes.string,
    sr: PropTypes.string,
    t: PropTypes.string,
    v: PropTypes.string,
    val: PropTypes.number,
    y: PropTypes.number
  })
};

RawRecentItem.contextType = DataContext;

export const RecentItem = themed("recentItem", RawRecentItem);
