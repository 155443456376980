import cxTheme from "@coxkit/cx-theme";
import cxDropdownTheme from "@coxkit/cx-theme/dropdown-menu";
import { cxDialogTheme } from "./themes/cx-theme/dialog";
import { cxFooterTheme } from "./themes/cx-theme/footer";
import { cxFeedbackTheme } from "./themes/cx-theme/feedback";
import { cxAdminHelperTheme } from "./themes/cx-theme/admin-helper";
import { cxAnnouncementsTheme } from "./themes/cx-theme/announcements";
import { cxLoadingIndicatorTheme } from "./themes/cx-theme/loading";
import { cxHeaderTheme } from "./themes/cx-theme/header";
import { cxSettingsButtonTheme } from "./themes/cx-theme/settings";
import { cxUserInfoTheme } from "./themes/cx-theme/user-info";
import { cxQuickSearchTheme } from "./themes/cx-theme/quick-search";
import { cxRecentItemsTheme } from "./themes/cx-theme/recent-items";
import { cxSearchFieldTheme } from "./themes/cx-theme/search-field";
import { cxMenuTheme } from "./themes/cx-theme/menu";
import { cxBodyTheme } from "./themes/cx-theme/body";
import { cxPopupTheme } from "./themes/cx-theme/popup";
import { cxTableTheme } from "./themes/cx-theme/table";
import { cxEntityListTheme } from "./themes/cx-theme/entity-list";
import { cxEntitySelectTheme } from "./themes/cx-theme/entity-select";
import { cxAnnouncementPopoverTheme } from "./themes/cx-theme/announcement-popover";

import { cxMenuBarTheme } from "./themes/cx-theme/menu-bar";

export const Theme = Object.assign(
  {},
  cxTheme,
  cxDropdownTheme,
  cxDialogTheme,
  cxEntityListTheme,
  cxEntitySelectTheme,
  cxAdminHelperTheme,
  cxAnnouncementsTheme,
  cxAnnouncementPopoverTheme,
  cxBodyTheme,
  cxFeedbackTheme,
  cxFooterTheme,
  cxHeaderTheme,
  cxLoadingIndicatorTheme,
  cxMenuTheme,
  cxPopupTheme,
  cxQuickSearchTheme,
  cxRecentItemsTheme,
  cxSearchFieldTheme,
  cxSettingsButtonTheme,
  cxTableTheme,
  cxUserInfoTheme,
  cxMenuBarTheme
);
