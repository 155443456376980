import React from "react";
import PropTypes from "prop-types";
import { DataContext } from "./DataContext";
import * as cookie from "@vauto/simple-web-tools/lib/cookie";

const EXPIRATION_MINUTES = 15;

function isCacheExpired(date) {
  let delta = new Date().getTime() - new Date(date).getTime();
  return delta > 1000 * 60 * EXPIRATION_MINUTES;
}

export class DataLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  async componentDidMount() {
    const cookies = cookie.parseCookie(document.cookie),
          auth = cookies.vAutoAuth || "",
          entity = cookies.CurrentEntity,
          user = auth.substring(0, auth.indexOf("&")),
          theme = cookies._theme,
          locale = cookies._locale,
          allCaches = Object.entries(localStorage).filter(kv => kv[0].startsWith("menu-data:")),
          cacheKey = `menu-data:${user}:${entity}:${theme}:${locale}`,
          cacheString = localStorage.getItem(cacheKey),
          cacheData = cacheString ? JSON.parse(cacheString) : null;

    if (cacheData && !isCacheExpired(cacheData.downloaded)) {
        this.setState({ data: cacheData });
    } else {
      if (allCaches.length) {
        // Use the expired cache data until we get the new refreshed data
        // so the user experience is less jarring
        const allCacheJson = allCaches.map(kv => JSON.parse(kv[1])),
              sortedCache = allCacheJson.sort((a, b) => b.downloaded.localeCompare(a.downloaded)),
              mostRecentCache = sortedCache[0];
        this.setState({ data: mostRecentCache });
      }

      console.log("Fetching new menu data...");
      const data = {...await this.props.api.getMenuData()};
      console.log("Done!");

      // Clear stale cache entries
      for (let kv in allCaches) {
        localStorage.removeItem(kv[0]);
      }

      data.downloaded = new Date();
      localStorage.setItem(cacheKey, JSON.stringify(data));
      this.setState({ data: data });
    }
  }

  render() {
    return (
      <DataContext.Provider value={{ data: this.state.data, api: this.props.api}}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

DataLoader.propTypes = {
  /** Any child components. */
  children: PropTypes.node
};
