import * as data from "./mockMenuData";

export class MenuApi {
  getMenuData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(data);
      }, 1000);
    });
  }

  getAnnouncement(id) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(data.announcements[id]);
      }, 1000);
    });
  }

  deleteAnnouncement(id) {
    return Promise.resolve(true);
  }

  getRecentItems() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(data.recentItems);
      }, 1000);
    });
  }

  getQuickSearch(search) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(data.quickSearch);
      }, 1000);
    });
  }

  getEntityList({ start = 0, limit = 10, quickSearch, root }) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const result = { ...data.entities };
        result.rows = result.rows.slice(start, start + limit);
        resolve(result);
      }, 1000);
    });
  }

  pinRecentItem(id, isPinned) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ success: true });
      }, 1000);
    });
  }

  sendFeedback(feedback) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ success: true });
      }, 1000);
    });
  }
}
