import styled from "styled-components";

export const cxFeedbackTheme = {
  feedback: component => styled(component)`
    .input-group-addon {
      padding: 0;
      padding-right: 2px;
      padding-left: 2px;
      border: none;
    }

    .va-feedback-form > :not(:last-child) {
      margin-bottom: 16px;
    }

    .va-feedback-form-footer {
      display: flex;
      justify-content: center;
    }

    .va-feedback-form-description {
      display: flex;
      border-bottom: 1px solid #cacfd9;
      margin-bottom: 16px;
      padding-bottom: 8px;
    }

    input,
    textarea {
      width: 100%;
    }
  `
};
