import React from "react";
import { themed } from "@coxkit/core/theme-engine";

const RawLoadingIndicator = props => (
  <div {...props}>
    <div className="va-loading-dot" />
    <div className="va-loading-dot" />
    <div className="va-loading-dot" />
  </div>
);

export const LoadingIndicator = themed("loading", RawLoadingIndicator);
