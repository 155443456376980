export const data = {
  en: {
    pm: {
      office: "Office",
      mobile: "Mobile",
      email: "Email",
      "tech-support": "Technical Support",
      "your-pm": "Your vAuto Performance Manager"
    }
  }
};
