import React from "react";
import { themed } from "@coxkit/core/theme-engine";
import { UserInfo } from "./UserInfo";
import { SettingsButton } from "./SettingsButton";
import { AppSwitcher } from "./AppSwitcher";
import { Menu } from "../Menu/Menu";
import { DataContext } from "../DataContext";

class RawHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const dataContext = this.context.data || {},
      themeConfig = dataContext.themeConfig || {},
      currentTheme = themeConfig.currentTheme || {};

    return (
      <div className={`va-nav-section ${this.props.className} ${currentTheme.name}`}>
        <div className="va-nav-bar">
          <div className="va-app-switcher-ct">
            <AppSwitcher />
          </div>
          <Menu />
          <div className="va-settings">
            <UserInfo />
            <SettingsButton />
          </div>
        </div>
      </div>
    );
  }
}

RawHeader.contextType = DataContext;

RawHeader.propTypes = {};

export const Header = themed("header", RawHeader);
