import styled from "styled-components";

export const cxRecentItemsTheme = {
  recentItemsButton: component => styled(component)`
    padding: 6px 0;

    a {
      text-decoration: none;
    }

    .va-quicksearch {
      display: flex;
      align-items: center;

      .icon-search {
        position: relative;
        top: 2px;
      }

      .loading .va-popup-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  `,

  recentItemsList: component => styled(component)`
    display: flex;
    flex: 1;
    flex-direction: column;

    &.loading {
      align-items: center;
      justify-content: center;
    }
  `,

  recentItem: component => styled(component)`
    display: flex;
    flex-direction: row;
    padding: 8px;
    color: #4b5053;
    font-size: 12px;
    text-decoration: none;

    h6 {
      margin: 0;
      font-size: 13px;
    }

    .va-flag-icon {
      color: #4b5053;
      opacity: 0.5;

      &:hover {
        cursor: pointer;
        background-color: #ebf0fa;
      }

      &.pinned {
        color: #2b6bdd;
        opacity: 1;
      }
    }

    &:hover {
      background-color: #fafafa;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ebf0fa;
    }

    > *:not(:last-child) {
      margin-right: 8px;
    }

    h6 {
      margin: 0;
      color: #2b6bdd;
    }
    em {
      font-style: italic;
    }

    .sprite {
      background-image: url(${require("../../images/quickaction-sprite.png")});
      background-repeat: no-repeat;
      display: block;
    }

    .sprite-appraisal {
      width: 32px;
      height: 32px;
      background-position: -5px -5px;
    }

    .sprite-inventory {
      width: 32px;
      height: 32px;
      background-position: -5px -47px;
    }

    .sprite-vinscan {
      width: 32px;
      height: 23px;
      background-position: -5px -89px;
    }

    .va-recent-item-details {
      flex: 1;
    }

    .va-recent-item-icon {
      width: 32px;
      height: 32px;
      background-color: black;
    }

    .va-recent-item-pricing {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: #2b6bdd;
    }
  `
};
